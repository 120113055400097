<template>
  <div class="tabbarApp" style="height: 100%">
        <Dialog
      :visible.sync="dataDialog"
      :style="{ width: '70%' }"
      header="Endre datafelt"
      :modal="true"
    >
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6 p-mt-2">
          <h6>Datafeltnavn</h6>
          <InputText
            id="name"
            v-model.trim="datafield.name"
            required="true"
            :class="{ 'p-invalid': submitted && !datafield.name }"
          />
          <small class="p-invalid" v-if="submitted && !datafield.name"
            >Name is required.</small
          >
        </div>

        <div class="p-field p-col-12 p-md-6 p-mt-2">
          <h6>Beskrivelse</h6>
          <InputText
            id="name"
            v-model.trim="datafield.description"
            required="true"
            :class="{ 'p-invalid': submitted && !datafield.name }"
          />
          <small class="p-invalid" v-if="submitted && !datafield.name"
            >Name is required.</small
          >
        </div>
        <div class="p-field p-col-12 p-md-6 p-mt-2">
          <h6>Ekstra</h6>
          <InputText
            id="name"
            v-model.trim="datafield.extra"
            required="true"
            :class="{ 'p-invalid': submitted && !datafield.name }"
          />
          <small class="p-invalid" v-if="submitted && !datafield.name"
            >Name is required.</small
          >
        </div>
        <div class="p-field p-col-12 p-md-6 p-mt-2">
          <h6>Kategori</h6>
          <Dropdown
            style="text-align: left"
            v-model="datafield.datafield_category"
            :options="datafieldCategories"
            optionValue="id"
            optionLabel="name"
          />
        </div>
      </div>
      <template #footer>
        <div v-if="datafield.status == 0">
          <Button
            style="float: left"
            @click="updateDatafield(datafield)"
            label="Lagre"
            icon="pi pi-check"
            class="p-button-primary marginl"
          />
          <Button
            style="float: left"
            label="Arkiver"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="updateStatus(1)"
          />
        </div>
        <div v-if="datafield.status == 1">
          <Button
            style="float: left"
            @click="updateStatus(0)"
            label="Aktiver"
            icon="pi pi-check"
            class="p-button-primary marginl"
          />
          <Button
            style="float: left"
            label="Slett"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="displayConfirmation = true"
          />
        </div>
      </template>
    </Dialog>
    <Dialog
      header="Advarsel!"
      :visible.sync="displayConfirmation"
      :style="{ width: '350px' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>Er du sikker på at du vil slette datafelt?</span>
      </div>
      <template #footer>
        <Button
          label="Nei"
          icon="pi pi-times"
          @click="displayConfirmation = false"
          class="p-button-text"
        />
        <Button
          label="Ja"
          icon="pi pi-check"
          @click="updateStatus(2)"
          class="p-button-text"
        />
      </template>
    </Dialog>
    <b-tabs :animated="false" scrollable="true">
      <b-tab-item
        :key="category.id"
        v-for="category in datafieldCategories"
        :label="category.name"
      >
        <DataTable
          :autoLayout="true"
          :filters="filters"
          removableSort
          :value="table(category.id)"
          data-key="id"
        >
          <template #header>
            <div class="p-col-12">
              <div style="text-align: left">
                <h4>Datafelter</h4>
              </div>
            </div>
            <div class="p-d-flex p-flex-column p-flex-md-row">
              <div class="p-mb-1 p-mr-2">
                <div style="text-align: left">
                  <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global']" placeholder="Søk" />
                  </span>
                </div>
              </div>
              <div class="p-mb-1 p-mr-2">
                <b-field style="text-align: left; padding-right: 20px">
                  <b-radio-button
                    v-model="status"
                    :native-value="0"
                    type="is-primary"
                  >
                    <span>Aktive</span>
                  </b-radio-button>

                  <b-radio-button
                    v-model="status"
                    :native-value="1"
                    type="is-primary"
                  >
                    <span>Inaktive</span>
                  </b-radio-button>
                </b-field>
              </div>
            </div>
          </template>
          <template #empty>Ingen treff</template>
          <Column field="name" header="Datafelt"></Column>
          <Column field="description" header="Beskrivelse"></Column>
          <Column field="Type.display" header="Type"></Column>
          <Column field="extra" header="Ekstra"></Column>

          <Column bodyStyle="text-align:right;">
            <template #body="slotProps">
              <Button
                label="Endre"
                @click="editData(slotProps.data)"
                class="p-mr-2 p-button-danger p-button-rounded"
              />
            </template>
          </Column>
        </DataTable>
      </b-tab-item>
    </b-tabs>


  </div>
</template>

<script>
import DashboardService from "@/services/Dashboard";
import TemplateService from "@/services/Template";
export default {
  data() {
    return {
      displayConfirmation: false,
      submitted: false,
      dataDialog: false,
      deleteCustomerDialog: false,
      dashboardService: null,
      templateService: null,
      filters: {},
      status: 0,
      datafieldCategories: [],
      datafieldCategory: {},
      datafields: [],
      datafield: {},
    };
  },

  watch: {
    status: function () {
      this.fetchDatafields();
    },
  },

  methods: {
    updateStatus(status) {
      const datafield = { id: this.datafield.id, status: status };
      this.templateService
        .updateDatafield(datafield)
        .then((data) => this.fetchDatafields());
      this.dataDialog = false;
      this.displayConfirmation = false;
    },
    updateDatafield(datafield) {
      console.log("he");
      this.templateService
        .updateDatafield(datafield)
        .then((data) => this.fetchDatafields());
      this.dataDialog = false;
      this.displayConfirmation = false;
    },
    table(category) {
      return this.datafields.filter(
        (element) => element.datafield_category == category
      );
    },
    hideDialog() {
      this.dataDialog = false;
      this.submitted = false;
    },
    fetchDatafields() {
      this.dashboardService
        .getDatafields(this.status)
        .then((data) => (this.datafields = data));
    },
    fetchCategories() {
      this.dashboardService
        .getDatafieldCategories(this.status)
        .then((data) => (this.datafieldCategories = data));
    },

    editData(datafieldCategory) {
      this.datafield = { ...datafieldCategory };
      this.dataDialog = true;
    },
  },

  created() {
    this.dashboardService = new DashboardService();
    this.templateService = new TemplateService();
  },

  mounted() {
    this.fetchCategories();
    this.fetchDatafields();
  },
};
</script>
<style scoped>
.p-dialog-mask {
  position: fixed;
}
</style>