<template>
  <div>
    <div class="p-col-12">
      <div style="text-align: left">
        <h4 style="line-height: 1.2">{{ project.projectname }}</h4>
      </div>
    </div>
    <b-tabs @input="updateTab" v-model="activeTab" class="testklasse">
      <b-tab-item
        class="testklasse"
        v-for="template in templates"
        :key="template.id"
        :label="template.name"
      >
        <ObjectTable :project="project" :template="template.id" />
      </b-tab-item>
      <b-tab-item
        v-if="ready && project.optionalTabs.includes(1)"
        :label="`Dokumenter (${files.length})`"
      >
        <Button
          class="p-button-danger"
          @click="$router.push('/projects')"
          label="Tilbake"
        />
        <br /><br />
        <FileUpload
          v-if="fileUploader"
          name="demo"
          chooseLabel="Velg fil"
          uploadLabel="Last opp"
          cancelLabel="Avbryt"
          customUpload
          @uploader="myUploader"
          :maxFileSize="15000000"
        >
          <template #empty>
            <p>Dra en fil hit.</p>
          </template>
        </FileUpload>
        <!-- {{files}} -->

        <div>
          <br />
          <div class="p-grid">
            <div class="p-col-4" v-for="files in files" :key="files.id">
              <Panel>
                <template #header>
                  <span
                    ><b>{{ files.name || "Uten navn" }}</b></span
                  >
                  <Button
                    class="p-button-danger p-button-sm"
                    @click="deleteDocument(files.id)"
                    v-if="$store.getters.user.userid == files.user"
                    >Slett dokument</Button
                  >
                </template>
                <!-- <img v-if="!files.thumbnail" :src="files.url" height="400"> -->
                <div v-if="fileType(files.name) == 'image'">
                  <div v-if="!files.s3_thumbnail">
                    <a target="new" :href="files.url">
                      <img style="height: 300px" :src="files.url" />
                    </a>
                  </div>

                  <div v-if="files.s3_thumbnail">
                    <a target="new" :href="files.url">
                      <img
                        style="width: 300px; height: 300px"
                        :src="files.urlthumbnail"
                      />
                    </a>
                  </div>
                </div>

                <div v-if="fileType(files.name) == 'pdf'">
                  <div v-if="!files.s3_thumbnail">
                    <a target="new" :href="files.url">
                      <!-- <img style="height: 300px" :src="files.url" /> -->
                      <i style="fontsize: 5rem" class="pi pi-file-pdf"></i>
                    </a>
                  </div>
                </div>
                <div v-if="fileType(files.name) == 'xlsx'">
                  <div v-if="!files.s3_thumbnail">
                    <a target="new" :href="files.url">
                      <!-- <img style="height: 300px" :src="files.url" /> -->
                      <i style="fontsize: 5rem" class="pi pi-file-excel"></i>
                    </a>
                  </div>
                </div>
              </Panel>
              <br />
              <br />
            </div>
          </div>
        </div>
      </b-tab-item>
      <b-tab-item
        v-if="ready && project.optionalTabs.includes(2)"
        :label="`Materiell`"
      >
        <Button
          class="p-button-danger"
          @click="$router.push('/projects')"
          label="Tilbake"
        /><br /><br />

        <br />
        <Button
          @click="addMaterial = true"
          label="Legg til enkelt"
          class="p-button-primary"
        />
        <Button
          @click="excelImport = !excelImport"
          style="margin-left: 10px"
          label="Importer Excel"
          class="p-button-primary"
        />

        <br />

        <ProjectMaterialsTable
          :key="key"
          type="transaction"
          :project="$route.params.id"
        />
      </b-tab-item>

      <b-tab-item
        v-if="ready && project.optionalTabs.includes(3)"
        :label="`Patching`"
      >
        <Textarea rows="40" cols="100"></Textarea>

        <br />
      </b-tab-item>
    </b-tabs>
    <Dialog
      header="Nytt materiell"
      modal
      :closable="true"
      :dismissableMask="false"
      :showHeader="true"
      :visible.sync="addMaterial"
    >
      <ProjectMaterialsTable
        :key="key"
        type="new"
        :project="$route.params.id"
      />
    </Dialog>

    <Dialog
      modal
      :closeable="true"
      header="Import Excel"
      :showHeader="true"
      :visible.sync="excelImport"
    >
      <h5>Bruk kun Excel ark med Kategori, Materiellkode og Materiellnavn</h5>
      <div>
        <FileUpload
          name="demo[]"
          chooseLabel="Velg fil"
          uploadLabel="Last opp"
          cancelLabel="Avbryt"
          customUpload
          @uploader="uploaderMaterialProject"
          :maxFileSize="15000000"
        >
          <template #empty>
            <p>Dra en fil hit.</p>
          </template>
        </FileUpload>
      </div>
    </Dialog>
  </div>
</template>
<script>
import ProjectService from "@/services/Project";
import TemplateService from "@/services/Template";

import ObjectTable from "@/components/Project/ObjectTable";
import TelenorMaterialTable from "@/components/Project/TelenorMaterialTable";
import ProjectMaterialsTable from "@/components/Project/ProjectMaterialsTable";
export default {
  components: {
    ObjectTable,
    TelenorMaterialTable,
    ProjectMaterialsTable,
  },
  data() {
    return {
      excelImport: false,
      fileUploader: true,
      activeTab: null,
      key: 0,
      project: {},
      addMaterial: false,
      templates: [],
      files: [],
      ready: false,
      testFile: null,
      materials: null,
    };
  },

  created() {
    this.projectService = new ProjectService();
    this.templateService = new TemplateService();
  },

  mounted() {
    this.fetchProject();
    this.fetchFiles();
    // this.fetchMaterials();
  },
  methods: {
    uploaderMaterialProject(event) {
      console.log("Uploader material project");
      console.log(event);
      this.file = event.files[0];
      this.uploadMaterialFileProject();
      event.files = null;
    },

    uploadMaterialFileProject() {
      console.log("Upload===");
      this.projectService
        .uploadProjectMaterialExcel(this.file, { project: this.project.id })
        .then((response) => {
          console.log(response);
          //   if(response.type=='is-success'){
          (this.excelImport = false), this.projectkey++;
          //   }
        });
    },

    updateTab(v) {
      let storage = {};
      // console.log(this.activeTab);
      // console.log(v);
      // console.log(this.project.id);
      // if (localStorage.lastTemplate) {
      //   storage = localStorage.lastTemplate;
      // }
      // storage[this.project.id] = v;
      // console.log(storage);
      localStorage.lastTemplate = v;
    },
    fetchMaterials() {
      this.projectService
        .fetchMaterials(this.$route.params.id)
        .then((data) => (this.materials = data));
    },
    myUploader(event) {
      console.log("Upload?");
      console.log(event);
      this.file = event.files[0];
      this.uploadDocument();
    },

    fileType(filename) {
      if (filename.slice(filename.length - 4) == ".pdf") {
        return "pdf";
      }
      if (filename.slice(filename.length - 5) == ".xlsx") {
        return "xlsx";
      }
      return "image";
    },
    deleteDocument(id) {
      if (confirm("Vil du slette denne filen?")) {
        this.projectService.deleteFile({ id: id }).then((response) => {
          this.fetchFiles();
        });
      }
    },
    uploaderMaterial(event) {
      console.log("Uploader material");
      console.log(event);
      this.file = event.files[0];
      this.uploadMaterialFile();
      event.files = null;
    },

    uploadMaterialFile() {
      console.log("Upload");
      this.projectService
        .uploadMaterial(this.project.id, this.file)
        .then((response) => {
          console.log(response);
          this.fetchObjects();
          this.newObjectDialog = false;
          this.key++;
          this.fileUploader = false;
          this.fileUploader = true;
        });
    },
    uploadDocument() {
      let data = {
        name: "",
        comment: "",
        project_id: this.$route.params.id,
        table: `projects`,
      };
      console.log("Upload document");
      console.log(data);
      this.projectService.uploadFile(data, this.file).then((response) => {
        this.fetchFiles();
      });
    },
    fetchFiles() {
      let object = {
        table: "projects",
        project_id: this.$route.params.id,
      };
      this.projectService.getFiles(object).then((data) => (this.files = data));
    },

    fetchTemplates() {
      this.templateService
        .getTemplateName(this.project.template)
        .then((data) => (this.templates = data))
        .then(() => (this.ready = true))
        .then(() => (this.activeTab = 0))
        .then(() => this.checkLastTemplate());
    },
    fetchProject() {
      this.projectService
        .getProject(this.$route.params.id)
        .then((data) => (this.project = data))
        .then((data) => this.fetchTemplates());
    },
    checkLastTemplate() {
      if (localStorage.lastTemplate) {
        this.activeTab = parseFloat(localStorage.lastTemplate);
      }
      // this.activeTab=3
    },
  },
};

//Hent template til prosjekt
//Hente prosjekt (info)
//Hente objekter
//Hente templateinfo per template
</script>

<style scoped lang="scss">
.p-tabview .p-tabview-panels {
  padding: 0 !important;
}
.testklasse {
  padding: 0 !important;
}

.p-dialog-mask {
  position: fixed;
}
</style>