<template>
  <div>
    <InventorySettingNav />
    <div>
      <!-- <Button class="p-mr-2 p-button-primary marginl" @click="locationModal=true">Ny lokasjon</Button>
       -->
      <NewLocation @clicked="key++" />
    </div>
  

 

    <TableLocation :key="key" />
  </div>
</template>

<script>
import InventorySettingNav from "@/components/UI/InventorySettingNav.vue";
import TableLocation from "@/components/Dashboard/InventoryModule/TableLocation.vue";
import NewLocation from "@/components/Dashboard/InventoryModule/NewLocation.vue";
export default {
  data() {
    return {
      key: 0,
      locationModal: false,
    };
  },
  components: {
    InventorySettingNav,
    TableLocation,
    NewLocation,
  },
};
</script>
