<template>
  <div style="padding:20px;">
    <!-- <h4 style="text-align:left;" class="p-pb-2">Ny template</h4> -->

    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-6 p-mt-2">
        <h6>Templatenavn</h6>
          <InputText v-model="template.name" id="templatename" type="text" />
      </div>
      <div class="p-field p-col-12 p-md-6 p-mt-2">
        <h6>Internavn / versjon</h6>
          <InputText v-model="template.version" id="templateversion" type="text" />
      </div>
    </div>
    <div>
      <!-- <Button
        style="float:left;"
        label="Avbryt"
        icon="pi pi-times"
        class="p-button-danger"
        @click="visible=false"
      /> -->

      <Button
        @click="newTemplate()"
        label="Lagre"
        icon="pi pi-check"
        class="p-button-primary"
      />
    </div>
  </div>
</template>

<script>
import TemplateService from "@/services/Template";
export default {
  methods: {
    newTemplate() {
      this.templateService
        .createTemplate(this.template)
        .then((data) => console.log(data));
      this.$emit("clicked", "newtemplate");
    },
  },

  data() {
    return {
      template: {},
      templateService: null,
    };
  },
  created() {
    this.templateService = new TemplateService();
  },
};
</script>

<style>
</style>