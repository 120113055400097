import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    idToken: null,
    user: null
  },
  mutations: {
    authUser(state, userData) {
      state.idToken = userData.token
      state.user = userData.user
          axios.defaults.headers = { 'Authorization': `Bearer ${userData.token}` }
    },
    clearAuthData(state) {
      state.idToken = null
      state.user = null
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      localStorage.removeItem('expirationDate')
      router.replace('/login')
    }
  },
  actions: {
    setLogoutTimer({commit},expirationTime){
      setTimeout(()=>{
        commit('clearAuthData')
      },expirationTime*1000)
    },
    logout({ commit }) {
      commit('clearAuthData')
      router.replace('/login')
    },
    signup({ commit }, authData) {

    },
    login({ commit,dispatch }, user) {
      console.log('Login action')
      return axios
        .post('/api/login', user)
        // .then(res=>console.log(res.data.status))
        .then(res => {
          if(res.data.status=='newuser'){
            console.log('NewUser Status')
            router.push('/newuser')
            return 'newuser'
          } else { 

          commit('authUser', {
            token: res.data.data,
            user: res.data.user
            // user:'hallo'
          })
          const now = new Date()
          // const expirationDate = now.getTime() + res.data.expiresIn
          const expirationDate = new Date(now.getTime() + 604800*1000)
          localStorage.setItem('token', res.data.data)
          localStorage.setItem('user', JSON.stringify(res.data.user))
          localStorage.setItem('expirationDate', expirationDate)
          dispatch('setLogoutTimer',604800)
          // axios.defaults.headers = { 'Authorization': `Bearer ${res.data.data}` }
          router.push('/')
        }
        })
        .catch(function(error){
          console.log('Catchet error!!! 123')
          console.log(error.response.data.message)
          // console.log(res.data)
          error = error.response.data.message
          throw error
        })
        
    },
    createUser({ commit,dispatch }, user) {
      console.log('Create action')
      if(user.password1!==user.password2){
        let error='Passord en og to er ikke like.'
        console.log('Throwe eerror for passord er ikke like')
        throw error
        return 'feil'
      } else {
        user.password=user.password1
      }
      console.log(user)
      return axios
        .post('/api/createpassword', user)
        // .then(res=>console.log(res.data.status))
        .then(res => {
          if(res.data.error){
            console.log('Error! ' + res.data.error)
            let error = res.data.error
            // error='error'
            console.log('Skal returnere ' + error)
            throw error
            return 'error'
          }
          if(res.data.status=='newuser'){
            console.log('NewUser Status')
            router.push('/newuser')
            return 'newuser'
          }})
          .then(res=>{
            // user={email:'tobias@laaneits.no',password:'123'}
            console.log('Ok, nå login? ' + user)
            console.log(user)
            dispatch("login", user).then((data) => (this.error = data));
        
        })
        .catch(function(error){
          console.log('Catchet error! ABCD')
          console.log(error)
          throw error.response.data.message
        })
        
    },
    tryAutoLogin({commit}){
      const token = localStorage.getItem('token')
      if (!token){
        return
      }
      const expirationDate = localStorage.getItem('expirationDate')
      const now = new Date()
      if(now >= expirationDate) {
        return
      }
      const user = JSON.parse(localStorage.getItem('user'))
      commit('authUser',{
        token: token,
        user:user
      })
    }
  },
  getters: {
    user(state) {
      return state.user
    },
    token(state) {
      return state.idToken
    },
    isAuthenticated(state) {
      return state.idToken !== null
    }
  }
})