<template>
  <div style="padding: 4%; position: absoulute;">

   
    <div v-if="!$store.getters.isAuthenticated && !activateUser">
      <h1 style="margin-bottom: 10px">Logg inn</h1>
      <div class="p-d-inline-flex">
        <div class="p-align-center p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-6 p-mt-3">
            <span class="p-float-label">
              <InputText v-model="nfc" id="firstname" type="email" />
              <label for="firstname">Epost</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-6 p-mt-3">
            <span class="p-float-label">
              <InputText
                v-model="user.password"
                id="lastname"
                type="password"
              />
              <label for="lastname">Passord</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-12">
            <Button
              @click="login()"
              type="button"
              label="Logg inn"
            />
          </div>

          <div class="p-field p-col-12 p-md-12">
            <a @click="logout()">Glemt passord</a>
          </div>
          <div class="p-field p-col-12 p-md-12">
            <a @click="activateUser = !activateUser">Aktiver bruker</a>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!$store.getters.isAuthenticated && activateUser">
      <h1 style="margin-bottom: 10px">Aktiver bruker</h1>
      <div class="p-d-inline-flex">
        <div class="p-align-center p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-6 p-mt-3">
            <span class="p-float-label">
              <InputText v-model="user.email" id="firstname" type="email" />
              <label for="firstname">Epost</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-6 p-mt-3">
            <span class="p-float-label">
              <InputText
                v-model="user.passcode"
                id="passcode"
                type="number"
              />
              <label for="passcode">Pinkode</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-6 p-mt-3">
            <span class="p-float-label">
              <InputText
                v-model="user.password1"
                id="password1"
                type="password"
              />
              <label for="lastname">Passord</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-6 p-mt-3">
            <span class="p-float-label">
              <InputText
                v-model="user.password2"
                id="password2"
                type="password"
              />
              <label for="password2">Gjenta passord</label>
            </span>
          </div>
          <div class="p-field p-col-12 p-md-12">
            <Button
              @click="createPassword()"
              type="button"
              label="Aktiver bruker"
            />
          </div>

         
          <div class="p-field p-col-12 p-md-12">
            <a @click="activateUser = !activateUser;error=null">Eksisterende bruker</a>
          </div>
        </div>
      </div>
    </div>
     <Message :life=5000 v-if="error" severity="warn" >{{error}}</Message>
  </div>
</template>


<script>
// import User from '../models/user';
import axios from "axios";

export default {
    watch: {
    nfc: function(val){
      console.log(val)
      // this.nfc='OK'
      if (val.length==10){
        console.log('sjekk nfc')
      }
      this.user.email=val
      console.log(this.user)
    },
    message: function () {
      console.log('message oppdatert?')
      console.log(this.error)
      if(this.message=='newuser'){
        this.activateUser=true
      }
    },
    },
  computed: {
    firstname() {
      return !this.$store.getters.user
        ? ""
        : this.$store.getters.user.firstname;
      // return 'ok'
    },
  },
  data() {
    return {
      // user: new User('', ''),
      nfc:null,
      user: {
        email: null,
        password: null,
        passcode: null,
        password1:null,
        password2:null,
      },
      loading: false,
      message: "",
      error: null,
      activateUser: false,
    };
  },
  created() {
    if (this.$store.getters.isAuthenticated) {
      this.$router.go(-1);
    }
  },
  methods: {
    login() {
      const user = this.user;
      this.$store.dispatch("login", user)
      .then((data) => (this.message = data,console.log('Feil med login: '+data)))
      .catch((error)=>(this.error=error,console.log('Error med innlogging : ' + error)));
    },
    createPassword() {
      const user = this.user;
      this.$store
        .dispatch("createUser", user)
        .then((data) => (this.message = data,console.log('Feil med create '+data)))
        .catch((error)=>(this.error=error,console.log('Error med createPassword'+error)))
    },
  },
};
</script>