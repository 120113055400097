<template>
  <div>
    <ProjectSettingNav />
    <div>
      <Button class="p-mr-2 p-button-primary marginl" @click="customerModal=true">Ny kunde</Button>
    </div>
    <Dialog header="Ny Kunde"
      modal
      :closable="true"
      :showHeader="true"
      :visible.sync="customerModal"
    >
      <NewCustomer @clicked="customerModal=false;key++" />
    </Dialog>

    <CustomerTable :key="key" />
  </div>
</template>

<script>
import ProjectSettingNav from "@/components/UI/ProjectSettingNav.vue";
import CustomerTable from "@/components/Dashboard/Common/CustomerTable.vue";
import NewCustomer from "@/components/Dashboard/Common/NewCustomer.vue";
export default {
  data() {
    return {
      key: 0,
      customerModal: false,
    };
  },
  components: {
    ProjectSettingNav,
    CustomerTable,
    NewCustomer,
  },
};
</script>
