<template>
  <div>
    <ProjectSettingNav />
    <b-tabs :animated="false">
      <b-tab-item label="Database">
        <div>
          <Button
            class="p-mr-2 p-button-primary marginl"
            @click="projectModal = true"
            >Nytt enkelt materiell</Button
          >
          <Button
            class="p-mr-2 p-button-primary marginl"
            @click="uploadModal = true"
            >Importer materiellliste</Button
          >
        </div>

        <ProjectMaterialsTable type="edit" :key="projectkey" />
      </b-tab-item>
   
    </b-tabs>
    <Dialog
      header="Nytt materiell"
      :contentStyle="{ overflow: 'visible' }"
      modal
      :closable="true"
      :dismissableMask="false"
      :showHeader="true"
      :visible.sync="projectModal"
    >
      <NewMaterial
        @clicked="
          projectModal = false;
          projectkey++;
        "
      />
    </Dialog>
    <Dialog
    modal :closeable="true" header="Import Excel" :showHeader="true" :visible.sync="uploadModal">
<h5>Bruk kun Excel ark med Kategori, Materiellkode og Materiellnavn</h5>
    <div >

                       <FileUpload
                       
          name="demo[]"
          chooseLabel="Velg fil"
          uploadLabel="Last opp"
          cancelLabel="Avbryt"
          customUpload
          @uploader="uploaderMaterial"
          :maxFileSize="15000000"
        >
          <template #empty>
            <p>Dra en fil hit.</p>
          </template>
        </FileUpload>
    </div>
    </Dialog>
 
  </div>
</template>

<script>
import ProjectService from "@/services/Project";
import ProjectMaterialsTable from "@/components/Project/ProjectMaterialsTable.vue"
import ProjectSettingNav from "@/components/UI/ProjectSettingNav.vue";
import NewMaterial from "@/components/Dashboard/ProjectModule/NewMaterial.vue";
import ProjectCategoryTable from "@/components/Dashboard/ProjectModule/ProjectCategoryTable.vue";
import NewProjectCategory from "@/components/Dashboard/ProjectModule/NewProjectCategory.vue";
import ProjectTable from "@/components/Dashboard/ProjectModule/ProjectTable.vue";
export default {
      created() {
    this.projectService = new ProjectService();
  },
  data() {
    return {
    projectService:null,
    uploadModal:false,
      projectkey: 0,
      categorykey: 0,
      file:null,
      projectModal: false,
      projectcategoryModal: false,
    };
  },
  components: {
    ProjectMaterialsTable,
    ProjectTable,
    ProjectCategoryTable,
    ProjectSettingNav,
    NewMaterial,
    NewProjectCategory,
  },
  methods:{
          uploaderMaterial(event) {
      console.log("Uploader material");
      console.log(event);
      this.file = event.files[0];
      this.uploadMaterialFile();
      event.files=null
    },

    uploadMaterialFile() {
      console.log("Upload===");
      this.projectService
        .uploadProjectMaterialExcel(this.file)
        .then((response) => {
          console.log(response)
        //   if(response.type=='is-success'){
              this.uploadModal=false,
          this.projectkey++
        //   }
        });
    },
  }
};
</script>


