<template>
  <div style="height:100%;">
    <DataTable :autoLayout="true" :filters="filters" removableSort :value="users" data-key="id">
      <template #header>
        <div class="p-col-12">
          <div style="text-align:left;">
            <h4>Brukere</h4>
          </div>
        </div>
        <div class="p-d-flex p-flex-column p-flex-md-row">
          <div class="p-mb-1 p-mr-2">
            <div style="text-align:left;">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global']" placeholder="Søk" />
              </span>
            </div>
          </div>
          <div class="p-mb-1 p-mr-2">
            <b-field style="text-align: left; padding-right: 20px;">
              <b-radio-button v-model="status" :native-value="0" type="is-primary">
                <span>Aktive</span>
              </b-radio-button>

              <b-radio-button v-model="status" :native-value="1" type="is-primary">
                <span>Deaktivert</span>
              </b-radio-button>
              <b-radio-button v-model="status" :native-value="3" type="is-primary">
                <span>Venter</span>
              </b-radio-button>
            </b-field>
          </div>
        </div>
      </template>
      <template #empty>Ingen treff</template>

      <Column sortable header="Navn">
        <template #body="slotProps">{{slotProps.data.firstname}} {{slotProps.data.lastname}}</template>
      </Column>

      <Column field="email" header="Epost"></Column>
      <Column field="phonenumber" header="Telefon"></Column>

      <Column bodyStyle="text-align:right;">
        <template #body="slotProps">
          <Button
            label="Endre"
            @click="editData(slotProps.data)"
            class="p-mr-2 p-button-danger p-button-rounded"
          />
        </template>
      </Column>
    </DataTable>

    <Dialog
      :visible.sync="dataDialog"
      :style="{width: '450px'}"
      header="Endre bruker"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <h6>Navn</h6>
        <InputText
          id="name"
          disabled
          v-model.trim="user.firstname"
          required="true"
          
          :class="{'p-invalid': submitted && !user.name}"
        />
        <small class="p-invalid" v-if="submitted && !user.name">Name is required.</small>
      </div>

      <template #footer>
        <div v-if="user.status==0">
          <Button
            style="float:left"
            @click="updateUser(user)"
            label="Lagre"
            icon="pi pi-check"
            class="p-button-primary"
          />
          <Button
            style="float:left"
            label="Deaktiver"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="updateStatus(1)"
          />
        </div>
        <div v-if="user.status==1">
          <Button
            style="float:left"
            @click="updateStatus(0)"
            label="Aktiver"
            icon="pi pi-check"
            class="p-button-primary"
          />
          <Button
            style="float:left"
            label="Slett"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="displayConfirmation=true"
          />
        </div>
      </template>
    </Dialog>
    <Dialog
      header="Advarsel!"
      :visible.sync="displayConfirmation"
      :style="{width: '350px'}"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>Er du sikker på at du vil slette brukeren?</span>
      </div>
      <template #footer>
        <Button
          label="Nei"
          icon="pi pi-times"
          @click="displayConfirmation=false"
          class="p-button-text"
        />
        <Button
          label="Ja"
          icon="pi pi-check"
          @click="updateStatus(2)"
          class="p-button-text"
          
        />
      </template>
    </Dialog>
  </div>
</template>


<script>
import CustomerService from "@/services/Customer";
import UserService from "@/services/User";
export default {
  data() {
    return {
      submitted: false,
      dataDialog: false,
      displayConfirmation: false,
      deleteCustomerDialog: false,
      userService: null,
      filters: {},
      status: 0,
      users: [],
      user: {},
    };
  },

  watch: {
    status: function () {
      this.fetchData();
    },
  },

  methods: {
    updateStatus(status) {
      const user = { id: this.user.id, status: status };
      this.userService.updateUser(user).then((data) => this.fetchData());
      this.dataDialog = false;
      this.displayConfirmation = false;
    },
    updateUser(user) {},
    hideDialog() {
      this.dataDialog = false;
      this.submitted = false;
    },
    fetchData() {
      this.customerService
        .getUsers(this.status)
        .then((data) => (this.users = data));
    },

    editData(user) {
      this.user = { ...user };
      this.dataDialog = true;
    },
  },

  created() {
    this.customerService = new CustomerService();
    this.userService = new UserService();
  },

  mounted() {
    this.fetchData();
  },
};
</script>