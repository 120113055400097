import axios from 'axios'

export default class HomeService {    

	//2 stk ta bort
	postComment(data) {
		return axios.post('api/postcomment/',data).then(res => res.data.data);
	}
	getArticles() {
		return axios.get('api/getarticles/').then(res => res.data.data);
	}
	getComments() {
		return axios.get('api/getcomments/').then(res => res.data.data);
	}


}

