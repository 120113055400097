<template>
  <div class="tabbarApp">
    <ProjectSettingNav />
    <h4 style="margin-top: 10px; margin-left: 8px; text-align: left">
      {{ project.projectname }}
    </h4>

    <b-tabs :animated="false">
      <b-tab-item label="Detaljer">
        <div style="margin-top: 8px" class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-6 p-mt-2">
            <h6>Prosjektnavn</h6>
            <InputText
              v-model="project.projectname"
              id="projectname"
              type="text"
            />
          </div>
          <div class="p-field p-col-12 p-md-6 p-mt-2">
            <h6>Deadline</h6>
            <Calendar
              dateFormat="d.m.yy"
              v-model="project.deadline"
              id="deadline"
            />
          </div>

          <div class="p-field p-col-12 p-md-6 p-mt-2">
            <h6>Prosjektkategori</h6>
            <Dropdown
              style="text-align: left"
              v-model="project.projectcategory"
              :options="projectcategories"
              optionValue="id"
              optionLabel="project_category"
            />
          </div>

          <div class="p-field p-col-12 p-md-6 p-mt-2">
            <h6>Kunde</h6>

            <Dropdown
              style="text-align: left"
              v-model="project.employer"
              :options="customers"
              optionValue="id"
              optionLabel="customername"
            />
          </div>
                    <div class="p-field p-col-12 p-md-6 p-mt-2">
            <h6>Prosjektleder</h6>
            <InputText
              v-model="project.project_leader"
              id="projectname"
              type="text"
            />
          </div>

          <div class="p-field p-col-12 p-md-12">
            <!-- <Button @click="registrer()" class="p-button-secondary" label="Legg til" /> -->
          </div>
        </div>
      </b-tab-item>
      <b-tab-item label="Teamledere">
        <DataTable
          :autoLayout="true"
          :filters="filters"
          removable
          sort
          :value="users"
          data-key="id"
        >
          <Column headerStyle="width: 3rem">
            <template #body="slotProps">
              <Checkbox
                v-model="project.teamleader"
                :value="slotProps.data.id"
              />
            </template>
          </Column>
          <Column header="Navn">
            <template #body="slotProps"
              >{{ slotProps.data.firstname }}
              {{ slotProps.data.lastname }}</template
            >
          </Column>
          <Column Sortable field="email" header="Epost"></Column>
        </DataTable>
      </b-tab-item>

      <b-tab-item label="Brukere">
        <DataTable
          :autoLayout="true"
          :filters="filters"
          removable
          sort
          :value="users"
          data-key="id"
        >
          <Column headerStyle="width: 3rem">
            <template #body="slotProps">
              <Checkbox v-model="project.users" :value="slotProps.data.id" />
            </template>
          </Column>
          <Column header="Navn">
            <template #body="slotProps"
              >{{ slotProps.data.firstname }}
              {{ slotProps.data.lastname }}</template
            >
          </Column>
          <Column Sortable field="email" header="Epost"></Column>
        </DataTable>
      </b-tab-item>
      <b-tab-item label="Template">
        <DataTable
          @row-reorder="onRowReorder"
          :autoLayout="true"
          :filters="filters"
          removable
          sort
          :value="templates"
          data-key="id"
        >
          <Column headerStyle="width: 3rem" :rowReorder="true"></Column>

          <Column headerStyle="width: 3rem">
            <template #body="slotProps">
              <Checkbox v-model="project.template" :value="slotProps.data.id" />
            </template>
          </Column>

          <Column Sortable field="name" header="Template"></Column>
          <Column Sortable field="version" header="Versjon"></Column>
        </DataTable>
      </b-tab-item>
       <b-tab-item label="Valgfrie faner">
              <DataTable
          :autoLayout="true"
          :value="tabs"
          data-key="id"
        >
          <Column headerStyle="width: 3rem" :rowReorder="true"></Column>

          <Column headerStyle="width: 3rem">
            <template #body="slotProps">
              <Checkbox v-model="project.optionalTabs" :value="slotProps.data.id" />
            </template>
          </Column>

          <Column Sortable field="name" header="Template"></Column>
          <Column Sortable field="version" header="Versjon"></Column>
              </DataTable>
      </b-tab-item>
    </b-tabs>
    <div v-if="project.status == 0">
      <Button
        @click="updateProject(project)"
        style="float: left"
        label="Lagre"
        icon="pi pi-check"
        class="p-button-primary marginl"
      />
      <Button
        @click="updateStatus(1)"
        style="float: left"
        label="Arkiver"
        icon="pi pi-trash"
        class="p-button-danger p-button marginl"
      />
    </div>

    <div v-if="project.status == 1">
      <Button
        @click="updateStatus(0)"
        style="float: left"
        label="Aktiver"
        icon="pi pi-check"
        class="p-button-primary marginl"
      />
      <Button
        @click="displayConfirmation = true"
        style="float: left"
        label="Slett"
        icon="pi pi-trash"
        class="p-button-danger p-button-danger marginl"
      />
    </div>
    <!-- <Button @click="sortert()">Test</Button>
    <div v-for="item in templates">
      <li>{{item.id}}</li>
    </div>-->
    <Dialog
      header="Advarsel!"
      :visible.sync="displayConfirmation"
      :style="{ width: '350px' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>Er du sikker på at du vil slette prosjektet?</span>
      </div>
      <template #footer>
        <Button
          label="Nei"
          icon="pi pi-times"
          @click="displayConfirmation = false"
          class="p-button-text"
        />
        <Button
          label="Ja"
          icon="pi pi-check"
          @click="updateStatus(2)"
          class="p-button-text"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import ProjectService from "@/services/Project";
import CustomerService from "@/services/Customer";
import TemplateService from "@/services/Template";
import ProjectSettingNav from "@/components/UI/ProjectSettingNav.vue";
export default {
  components: {
    ProjectSettingNav,
  },

  data() {
    return {
      displayConfirmation: false,
      filters: {},
      project: {},
      customers: [],
      projectcategories: [],
      users: [],
      templates: [],
      tabs:[
        {id:1, name:'Dokumenter'},
        {id:2, name:'Materiell'}
      ],

    };
  },
  computed: {
    //   userarr: function(){
    //       return this.project.users.toString().split(',')
    //   }
  },

  watch: {
    project: function (val) {
      this.project.users = val.users.toString().split`,`.map((x) => +x);
      this.project.teamleader = val.teamleader.toString().split`,`.map(
        (x) => +x
      );
      this.project.template = val.template.toString().split`,`.map((x) => +x);
      this.project.optionalTabs = val.optionalTabs.toString().split`,`.map((x) => +x);
    },
  },

  mounted() {
    this.fetchProject();
    this.fetchCustomers();
    this.fetchProjectCategories();
    this.fetchUsers();
    this.fetchTemplates();
  },

  created() {
    this.projectService = new ProjectService();
    this.customerService = new CustomerService();
    this.templateService = new TemplateService();
  },

  methods: {
    updateStatus(status) {
      const project = { id: this.project.id, status: status };
      this.projectService
        .updateProject(project)
        .then((data) => this.fetchProject());
      this.$router.push("/dashboard/projects");
    },
    updateProject(project) {
      if(!project.project_leader){project.project_leader=''}
      project.teamleader = project.teamleader.toString();
      project.users = project.users.toString();
      project.project_leader = project.project_leader.toString();
      project.template = project.template.toString();
      project.optionalTabs = project.optionalTabs.toString();
      this.projectService
        .updateProject(project)
        .then((data) => this.fetchProject());
    },
    sorter() {
      itemsArray.sort(function (a, b) {
        return sortingArr.indexOf(a) - sortingArr.indexOf(b);
      });
    },
    onRowReorder(event) {
      this.templates = event.value;
    },
    fetchTemplates() {
      this.templateService
        .getAllTemplates(0)
        .then((data) => (this.templates = data));
    },
    fetchUsers() {
      this.customerService.getUsers("0,3").then((data) => (this.users = data));
    },
    fetchProjectCategories() {
      this.projectService
        .getProjectCategories(0)
        .then((data) => (this.projectcategories = data));
    },
    fetchCustomers() {
      this.customerService
        .getCustomers(0)
        .then((data) => (this.customers = data));
    },

    fetchProject() {
      this.projectService
        .getProject(this.$route.params.id)
        .then((data) => (this.project = data));
    },
  },
};
</script>

<style>
.pad {
  margin-left: 10px;
}
</style>