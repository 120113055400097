<template>
  <div style="padding:20px;">
    <!-- <h4 style="text-align:left;" class="p-pb-2">Ny kunde</h4> -->

    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-12 p-mt-2">
        <h6>Kundenavn</h6>
          <InputText v-model="customer.customername" id="name" type="text" />
      </div>
    </div>
    <div>
      <!-- <Button
        style="float:left;"
        label="Avbryt"
        icon="pi pi-times"
        class="p-button-danger"
        @click="visible=false"
      /> -->

      <Button @click="newCustomer(customer)" label="Lagre" icon="pi pi-check" class="p-button-primary" />
    </div>
  </div>
</template>

<script>
import CustomerService from "@/services/Customer"
export default {
  methods: {
    newCustomer(customer) {
    this.customerService
    .newCustomer(customer)
    .then((data)=>console.log(data))
    this.$emit('clicked','newcustomer')
    },
  },

  data() {
    return {
      customer: {},
      customerService:null,
    };
  },
  created(){
    this.customerService = new CustomerService()
  }
};
</script>

<style>
</style>