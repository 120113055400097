import axios from 'axios'

export default class DashboardService {    

	//2 stk ta bort
	getDatafieldCategories(status) {
		return axios.get('api/getdatafieldcategories/'+status).then(res => res.data.data);
	}
	getDatafields(status) {
		return axios.get('api/getdatafields/'+status).then(res => res.data.data);
	}


}

