<template>
  <div style="height: 100%">
    <DataTable
      :autoLayout="true"
      :filters="filters"
      removableSort
      :value="customers"
      data-key="id"
    >
      <template #header>
        <div class="p-col-12">
          <div style="text-align: left">
            <h4>Kunder</h4>
          </div>
        </div>
        <div class="p-d-flex p-flex-column p-flex-md-row">
          <div class="p-mb-1 p-mr-2">
            <div style="text-align: left">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global']" placeholder="Søk" />
              </span>
            </div>
          </div>
          <div class="p-mb-1 p-mr-2">
            <b-field style="text-align: left; padding-right: 20px">
              <b-radio-button
                v-model="status"
                :native-value="0"
                type="is-primary"
              >
                <span>Aktive</span>
              </b-radio-button>

              <b-radio-button
                v-model="status"
                :native-value="1"
                type="is-primary"
              >
                <span>Inaktive</span>
              </b-radio-button>
            </b-field>
          </div>
        </div>
      </template>
      <template #empty>Ingen treff</template>

      <Column sortable field="customername" header="Name"></Column>

      <Column bodyStyle="text-align:right;" header>
        <template #body="slotProps">
          <Button
            label="Endre"
            @click="editCustomer(slotProps.data)"
            class="p-mr-2 p-button-danger p-button-rounded"
          />
        </template>
      </Column>
    </DataTable>

    <Dialog
      :visible.sync="customerDialog"
      :style="{ width: '450px' }"
      header="Endre kunde"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <h6>Kunde</h6>
        <InputText
          id="name"
          v-model.trim="customer.customername"
          required="true"
          :class="{ 'p-invalid': submitted && !customer.name }"
        />
        <small class="p-invalid" v-if="submitted && !customer.name"
          >Name is required.</small
        >
      </div>
      <template #footer>
        <div v-if="customer.status == 0">
          <Button
            style="float: left"
            @click="updateCustomer(customer)"
            label="Lagre"
            icon="pi pi-check"
            class="p-button-primary"
          />
          <Button
            style="float: left"
            label="Deaktiver"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="updateStatus(1)"
          />
        </div>
        <div v-if="customer.status == 1">
          <Button
            style="float: left"
            @click="updateStatus(0)"
            label="Aktiver"
            icon="pi pi-check"
            class="p-button-danger"
          />
          <Button
            style="float: left"
            label="Slett"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="displayConfirmation = true"
          />
        </div>
      </template>
    </Dialog>
    <Dialog
      header="Advarsel!"
      :visible.sync="displayConfirmation"
      :style="{ width: '350px' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>Er du sikker på at du vil slette kunden?</span>
      </div>
      <template #footer>
        <Button
          label="Nei"
          icon="pi pi-times"
          @click="displayConfirmation = false"
          class="p-button-text"
        />
        <Button
          label="Ja"
          icon="pi pi-check"
          @click="updateStatus(2)"
          class="p-button-text"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import CustomerService from "@/services/Customer";
export default {
  data() {
    return {
      displayConfirmation: false,
      customerDialog: false,
      deleteCustomerDialog: false,
      customer: {},
      submitted: false,
      productService: null,
      filters: {},
      status: 0,
      customers: [],
    };
  },

  watch: {
    status: function () {
      this.fetchData();
    },
  },

  methods: {
    updateStatus(status) {
      const customer = { id: this.customer.id, status: status };
      this.customerService
        .updateCustomer(customer)
        .then((data) => this.fetchData());
      this.customerDialog = false;
      this.displayConfirmation = false;
    },
    updateCustomer(customer) {
      this.customerService
        .updateCustomer(customer)
        .then((data) => this.fetchData());
      this.customerDialog = false;
      this.displayConfirmation = false;
    },
    hideDialog() {
      this.customerDialog = false;
      this.submitted = false;
    },
    fetchData() {
      this.customerService
        .getCustomers(this.status)
        .then((data) => (this.customers = data));
    },

    editCustomer(customer) {
      this.customer = { ...customer };
      this.customerDialog = true;
    },
  },

  created() {
    this.customerService = new CustomerService();
  },

  mounted() {
    this.fetchData();
  },
};
</script>