<template>
  <b-tabs>
    <b-tab-item label="Uttak">
      <!-- {{tmp}} -->
      {{checkout}}
      <div >
         <p class="error">{{ error }}</p>

        <qrcode-stream :height="100" :track="false" v-if="!error" @decode="onDecode" @init="onInit"></qrcode-stream>
    <!-- <p class="decode-result">Last result: <b>{{ result }}</b></p> -->

    <!-- <p v-if="error !== null" class="drop-error">
      {{ error }}
    </p> -->

    <!-- <qrcode-drop-zone @detect="onDetect" @dragover="onDragOver" @init="logErrors">
      <div class="drop-area" :class="{ 'dragover': dragover }">
        DROP SOME IMAGES HERE
      </div>
    </qrcode-drop-zone> -->
        <!-- <StreamBarcodeReader
    @decode="onDecode"
    @loaded="onLoaded"
        ></StreamBarcodeReader>
        <br><br> -->
        
          <!-- <input v-model="barcodeValue" /><br>
  <barcode v-bind:value="barcodeValue">
    Show this if the rendering fails.
  </barcode> -->
</div>

      
  <div>
    <!-- <v-quagga :onDetected="logIt" :readerSize="readerSize" :readerTypes="['ean_13']"></v-quagga> -->
  </div>

      <Message
        severity="warn"
        v-if="diffStorages()"
      >Advarsel: Du har valgt varer fra forskjellige lagre!</Message>

      <Button @click="$router.push('/inventory')" label="Tilbake" class="p-button-primary" />
      <Button @click="emptyCheckout()" class="marginl p-button-primary" label="Tøm kurv" />

      <br />
      <!-- {{checkout[0]}}
      <br />
      <br />
      {{checkout[0].Inventory_Storage}}-->
      <!-- {{checkout}}
      {{inventories[0]}}-->
      <DataTable :autofocus="false" :value="inventories">
        <Column header="Produkt" field="Inventory_Product.name"></Column>
        <Column header="Lager">
          <template #body="slotProps">
            <span v-if="slotProps.data.Inventory_Storage">{{slotProps.data.Inventory_Storage.name}}</span>

            <span
              v-if="slotProps.data.Inventory_Location"
            >/ {{slotProps.data.Inventory_Location.name}}</span>
          </template>
        </Column>
        <Column header="På lager" field="quantity"></Column>
        <Column header="Antall" field="number">
          <template #body="slotProps">
            <InputNumber v-model="slotProps.data.number" mode="decimal" :min="0" />
            <!-- <InputNumber v-model="slotProps.data.number" mode="decimal" :min="0" /> -->
            <!-- <input
              :autofocus="false"
              class="p-inputnumber-input p-inputtext p-component p-filled"
              v-model="slotProps.data.number"
              min="0"
              type="number"
            />-->
            <!-- <input :autofocus="false" class="p-inputnumber-input p-inputtext p-component p-filled" v-model="slotProps.data.quantity" min=0 type="number"> -->

            <Button
              class="p-button-danger p-button-text marginl"
              @click="checkout.splice(checkout.indexOf(slotProps.data.id),1);"
              icon="pi pi-times"
            />
          </template>
        </Column>
      </DataTable>
      <Button
        style="float:left; margin-top:25px;"
        label="Ut"
        icon="pi pi-check"
        class="p-button-primary"
        @click="save(-1)"
      />
      <Button
        style="float:left; margin-top:25px;"
        label="Inn"
        icon="pi pi-check"
        class="p-button-primary marginl"
        @click="save(1)"
      />
    </b-tab-item>
  </b-tabs>
</template>

<script>
require("howler");
import { StreamBarcodeReader } from "vue-barcode-reader";
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from "vue-qrcode-reader";
import VueBarcode from 'vue-barcode';
import InventoryService from "@/services/Inventory/Inventory";


export default {
  components: {
    'barcode' : VueBarcode,
    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture,
    StreamBarcodeReader,
  },
  watch: {
    checkout: function () {
      localStorage.setItem("checkout", JSON.stringify(this.checkout));
      this.fetchCart();
    },
  },
  data() {
    return {
      barcodeValue:123123,
      readerSize: {
        width: 640,
        height: 480,
      },
      tmp:[],
      detecteds: [],
            result: null,
      error: null,
      dragover: false,
      checkout: [],
      number: null,
      inventories: [],
      inventoryService: null,
    };
  },
  methods: {
      async onInit (promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = "Feil: Du må tillate kamera."
        } else if (error.name === 'NotFoundError') {
          this.error = "Feil: Enheten har ikke kamera"
        } else if (error.name === 'NotSupportedError') {
          this.error = "ERROR: secure context required (HTTPS, localhost)"
        } else if (error.name === 'NotReadableError') {
          this.error = "Feil: Er kamera allerede i bruk?"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "ERROR: installed cameras are not suitable"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "ERROR: Stream API is not supported in this browser"
        }
      }
      }
      ,
    logIt(data) {
      if (!this.tmp.includes(data.codeResult.code)){
        this.tmp.push(data.codeResult.code);
      }
      console.log("detected", data.codeResult.code);
    },
    playSound() {
      console.log("Play sound");
      // const file_path="@/assets/sound.mp3"
      const file_path =
        "https://gateway.pinata.cloud/ipfs/Qmbzg4XXtaZwSNkn5WUVdWwQ6kU2GaCJnL9Ehqi4hakq5V";
      var sound = new Howl({
        src: ["/soft_notification.mp3"],
        volume: 0.8,
      });
      sound.play();
      console.log(sound.state());
    },
    onError(result) {
      console.log(result);
    },
    onLoaded(result) {
      console.log(result);
    },
    onDecode(decodedString) {
      let stripped = decodedString.replace(/\\/g,"")
        let parsed =JSON.parse(decodedString)
        parsed = parsed.locationId
      if (!this.checkout.includes(parsed)) {
        this.checkout.push(parsed);
        this.playSound();
      }
    },
    diffStorages() {
      let antall = [];
      for (let i = 0; i < this.inventories.length; i++) {
        if (!antall.includes(this.inventories[i].Inventory_Storage.id)) {
          antall.push(this.inventories[i].Inventory_Storage.id);
        }
      }
      console.log(antall);
      return antall.length > 1;
    },
    save(num) {
      for (let i = 0; i < this.inventories.length; i++) {
        this.transaction(this.inventories[i], this.inventories[i].number * num);
      }
      this.emptyCheckout();
      this.$router.push("/inventory");
    },
    fetchCart() {
      this.inventoryService
        .getCart(this.checkout)
        .then((data) => (this.inventories = data));
    },
    emptyCheckout() {
      localStorage.removeItem("checkout");
      this.checkout = [];
    },
    transaction(editObject, number) {
      const object = {
        id: editObject.id,
        number: number,
      };
      (this.editObject = null),
        (this.number = null),
        (this.showModal = false),
        this.inventoryService
          .updateInventoryQuantity(object)
          .then((data) => console.log(data))
          .then(this.fetchInventories);
    },
        onDragOver (isDraggingOver) {
      this.dragover = isDraggingOver
    },
    async onDetect (promise) {
      try {
        const { content } = await promise

        this.result = content
        this.error = null
      } catch (error) {
        if (error.name === 'DropImageFetchError') {
          this.error = 'Sorry, you can\'t load cross-origin images :/'
        } else if (error.name === 'DropImageDecodeError') {
          this.error = 'Ok, that\'s not an image. That can\'t be decoded.'
        } else {
          this.error = 'Ups, what kind of error is this?! ' + error.message
        }
      }
    },

    logErrors (promise) {
      promise.catch(console.error)
    },
  },
  created() {
    this.inventoryService = new InventoryService();
  },
  mounted() {
    if (localStorage.getItem("checkout")) {
      this.checkout = JSON.parse(localStorage.getItem("checkout"));
    }
    this.fetchCart();
  },
};
</script>

<style>
.drop-area {
  height: 300px;
  color: #fff;
  text-align: center;
  font-weight: bold;
  padding: 10px;

  background-color: rgba(0,0,0,.5);
}

.dragover {
  background-color: rgba(0,0,0,.8);
}

.drop-error {
  color: red;
  font-weight: bold;
}
</style>