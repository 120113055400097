<template>
  <div>
    <ProjectSettingNav />
    <b-tabs v-model="tab" :animated="false">
      <b-tab-item label="Detaljer">
        <div style="margin-top: 8px" class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-6 p-mt-2">
            <h6>Templatenavn</h6>
            <InputText v-model="template.name" id="templatenavn" type="text" />
          </div>
          <div class="p-field p-col-12 p-md-6 p-mt-2">
            <h6>Internnavn / Versjon</h6>
            <InputText v-model="template.version" id="version" type="text" />
          </div>
        </div>
      </b-tab-item>
      <b-tab-item label="Datafelter">
        <b-tabs :animated="false">
          <b-tab-item
            :key="category.id"
            v-for="category in datafieldCategories"
            :label="category.name"
          >
            <DataTable
              :autoLayout="true"
              :filters="filters"
              removableSort
              :value="table(category.id)"
              data-key="id"
            >
              <template #header>
                <div class="p-d-flex p-flex-column p-flex-md-row">
                  <div class="p-mb-1 p-mr-2">
                    <div style="text-align: left">
                      <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText
                          v-model="filters['global']"
                          placeholder="Søk"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </template>
              <template #empty>Ingen treff</template>
              <Column field="name" header="Datafelt"></Column>
              <Column field="description" header="Beskrivelse"></Column>
              <Column field="Type.display" header="Type"></Column>

              <Column bodyStyle="text-align:right;">
                <template #body="slotProps">
                  <Button
                    label="Legg til"
                    @click="addDatafield(slotProps.data.id)"
                    class="p-mr-2 p-button-primary p-button-rounded"
                    v-if="
                      templateInfo.filter(
                        (templateInfo) =>
                          templateInfo.datafield_id == slotProps.data.id
                      ).length == 0
                    "
                  />
                  <Button
                    v-if="
                      templateInfo.filter(
                        (templateInfo) =>
                          templateInfo.datafield_id == slotProps.data.id
                      ).length == 1
                    "
                    label="Ta bort"
                    @click="addDatafield(slotProps.data.id)"
                    class="p-mr-2 p-button-danger p-button-rounded"
                  />
                </template>
              </Column>
            </DataTable>
          </b-tab-item>
        </b-tabs>
      </b-tab-item>

      <b-tab-item label="Rekkefølge">
        <div v-for="items in grupper" :key="items.id" class="box">
                      <span v-if="items.id == 0" style="text-align: left"
              ><b>Tabellvisning</b></span
            >
          <draggable :list="items.list" group="datafelt">

            <div
              style="margin: 5px"
              v-for="elements in items.list"
              :key="elements.id"
            >
              <b-switch
                style="margin-right: 20px; display: inline-block"
                :true-value="1"
                :false-value="0"
                v-model="elements.tableview"
              ></b-switch>

              <span
                style="display: inline-block; margin-right: 10px"
                class="button bredde"
                >{{ elements.name }}</span
              >
            </div>
          </draggable>

          <div v-if="items.list.length == 0" style>
            <span class="drag">Dra en boks hit!</span>
            <span
              @click="removebox(items.id)"
              class="remove"
              style="float: right"
            >
              <i style="fontsize: 2rem" class="pi pi-times"></i>
            </span>
          </div>
        </div>

        <div class="box">
          <span @click="addbox()" class="add">
            <i style="fontsize: 2rem; margin-right: 10px" class="pi pi-plus"></i
            >Trykk for å legge til gruppe
          </span>
        </div>

        <Button
          @click="sort()"
          style="float: left"
          label="Lagre"
          icon="pi pi-check"
          class="p-button-primary marginl"
        />
      </b-tab-item>
    </b-tabs>

    <div v-if="template.status == 0 && tab == 0">
      <Button
        @click="updateTemplate(template)"
        style="float: left"
        label="Lagre"
        icon="pi pi-check"
        class="p-button-primary marginl"
      />
      <Button
        @click="updateStatus(1)"
        style="float: left"
        label="Arkiver"
        icon="pi pi-trash"
        class="p-button-danger p-button-danger marginl"
      />
    </div>

    <div v-if="template.status == 1 && tab == 0">
      <Button
        @click="updateStatus(0)"
        style="float: left"
        label="Aktiver"
        icon="pi pi-check"
        class="p-button-primary marginl"
      />
      <Button
        @click="displayConfirmation = true"
        style="float: left"
        label="Slett"
        icon="pi pi-trash"
        class="p-button-danger p-button-danger marginl"
      />
    </div>
    <Dialog
      header="Advarsel!"
      :visible.sync="displayConfirmation"
      :style="{ width: '350px' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>Er du sikker på at du vil slette prosjektet?</span>
      </div>
      <template #footer>
        <Button
          label="Nei"
          icon="pi pi-times"
          @click="displayConfirmation = false"
          class="p-button-text"
        />
        <Button
          label="Ja"
          icon="pi pi-check"
          @click="updateStatus(2)"
          class="p-button-text"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import ProjectSettingNav from "@/components/UI/ProjectSettingNav";
import TemplateService from "@/services/Template";
import DashboardService from "@/services/Dashboard";
import draggable from "vuedraggable";

export default {
  components: {
    ProjectSettingNav,
    draggable,
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  data() {
    return {
      tab: 0,
      displayConfirmation: false,
      project: { projectname: "Hei" },
      template: {},
      datafields: [],
      filters: {},
      datafieldCategories: [],
      templateInfo: [],
      groups: [],
      grupper: [],
      datafelt: [],
    };
  },
  methods: {
    sort() {
      let datafelt = JSON.parse(JSON.stringify(this.grupper)); //Deep copy obj
      // datafelt.pop(); //Fjerner siste element, som er placeholder boks uansett
      let updatefelt = [];
      console.log("============");

      for (let i = 0; i < datafelt.length; i++) {
        console.log("BOKS" + i);
        console.log(datafelt[i].list);
        for (let j = 0; j < datafelt[i].list.length; j++) {
          updatefelt.push({
            id: datafelt[i].list[j].id,
            group: i,
            order: j,
            tableview: datafelt[i].list[j].tableview,
            name: datafelt[i].list[j].name, //Dev
          });
        }
      }
      this.datafelt = updatefelt;
      this.templateService
        .updateTemplateFields(updatefelt)
        .then((data) => this.fetchTemplateInfo());
    },
    removebox(boks) {
      let index = this.grupper.findIndex((x) => x.id == boks);
      console.log(index);
      this.grupper.splice(index, 1);
    },
    addbox() {
      let id = 1;
      console.log(this.grupper.findIndex((x) => x.id === id));
      while (this.grupper.findIndex((x) => x.id === id) != -1) {
        id++;
      }
      this.grupper.push({ id: id, list: [] });
    },
    fiksboks() {
      this.grupper = [];
      for (let i = 0; i < this.templateInfo.length; i++) {
        // this.grupper.push(this.templateInfo[i].group)
        if (
          !this.grupper.filter(
            (group) => group.id == this.templateInfo[i].group
          ).length
        ) {
          console.log("//TOM GRUPPE//");
          this.grupper.push({ id: this.templateInfo[i].group, list: [] });
        }
        this.grupper[
          this.grupper.findIndex((x) => x.id == this.templateInfo[i].group)
        ].list.push({
          id: this.templateInfo[i].id,
          name: this.templateInfo[i].Datafields[0].name,
          tableview: this.templateInfo[i].tableview,
          extra: this.templateInfo[i].extra,
        });
      }
    },
    addDatafield(id) {
      const datafield = {
        datafield: id,
        template: this.template.id,
      };
      this.templateService
        .addTemplateField(datafield)
        .then((data) => this.fetchTemplateInfo());
    },
    updateStatus(status) {
      const template = { id: this.template.id, status: status };
      this.templateService
        .updateTemplate(template)
        .then((data) => this.fetchTemplate());
      this.$router.push("/dashboard/templates");
    },
    updateTemplate(template) {
      this.templateService
        .updateProject(template)
        .then((data) => this.fetchTemplate());
    },
    table(category) {
      return this.datafields.filter(
        (element) => element.datafield_category == category
      );
    },
    fetchTemplate() {
      this.templateService
        .getTemplate(this.$route.params.id)
        .then((data) => (this.template = data));
    },
    fetchDatafields() {
      this.dashboardService
        .getDatafields(0)
        .then((data) => (this.datafields = data));
    },
    fetchDatafieldCategories() {
      this.dashboardService
        .getDatafieldCategories(0)
        .then((data) => (this.datafieldCategories = data));
    },
    fetchTemplateInfo() {
      this.templateService
        .getTemplateInfo(this.$route.params.id)
        .then((data) => (this.templateInfo = data))
        .then((data) => this.fiksboks());
    },
  },
  mounted() {
    this.fetchTemplate();
    this.fetchDatafields();
    this.fetchDatafieldCategories();
    this.fetchTemplateInfo();
  },
  created() {
    this.templateService = new TemplateService();
    this.templateService = new TemplateService();
    this.dashboardService = new DashboardService();
  },
};

//Hent Template ID
//Hent Datafelter
</script>


<style>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}

.remove {
  /* color:var(--is-primary); */
  color: red;
  /* font-size:.4em; */
}
.bredde {
  text-align: left;
  display: inline-block;
  height: 2.5em;
  width: 20%;
}
</style>
