<template>
  <div>
<h2>Påfylling av varer</h2>

 <b-tabs :animated="false">
      <b-tab-item v-for="storage in storages" :key="storage.id" :label="storage.name">
</b-tab-item>
 </b-tabs>

  </div>
</template>

<script>
import NewInventory from "@/components/Dashboard/InventoryModule/NewInventory.vue";
import NewLocation from "@/components/Dashboard/InventoryModule/NewLocation.vue";
import InventoryService from "@/services/Inventory/Inventory";
export default {
  data() {
    return {
      storages: [],
    };
  },
  methods: {
    fetchLocations() {
      this.inventoryService
        .getLocations(0)
        .then((data) => (this.locations = data));
    },
    fetchSuppliers() {
      this.inventoryService
        .getSuppliers(0)
        .then((data) => (this.suppliers = data));
    },
    fetchManufacturers() {
      this.inventoryService
        .getManufacturers(0)
        .then((data) => (this.manufacturers = data));
    },
    fetchProductCategories() {
      this.inventoryService
        .getProductCategories(0)
        .then((data) => (this.productCategories = data));
    },
    fetchData() {
      this.inventoryService
        .getProducts(0)
        .then((data) => (this.products = data));
    },
    fetchInventories() {
      this.inventoryService
        .getInventories(0)
        .then((data) => (this.inventories = data));
    },
    fetchStorages() {
      this.inventoryService
        .getStorages(0)
        .then((data) => (this.storages = data));
    },
  },
  created() {
    this.inventoryService = new InventoryService();
  },
  components: {
    NewInventoryE: NewInventory,
    NewLocation,
  },

  mounted() {
    if (localStorage.getItem("checkout")) {
      this.checkout = JSON.parse(localStorage.getItem("checkout"));
    }
    // this.fetchSuppliers(),
    //   this.fetchManufacturers(),
    //   this.fetchProductCategories(),
    //   this.fetchData(),
    //   this.fetchInventories();
    // this.fetchLocations();
    this.fetchStorages();
  },
};
</script>

<style>
</style>