<template>
  <div style="height:100%;">
  
    <DataTable
     @row-reorder="onRowReorder"
      :autoLayout="true"
      :filters="filters"
      removableSort
      :value="datafieldCategories"
      data-key="id"
    >
      <template #header>
        <div class="p-col-12">
          <div style="text-align:left;">
            <h4>Datafeltkategorier</h4>
          </div>
        </div>
        <div class="p-d-flex p-flex-column p-flex-md-row">
          <div class="p-mb-1 p-mr-2">
            <div style="text-align:left;">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global']" placeholder="Søk" />
              </span>
            </div>
          </div>
          <div class="p-mb-1 p-mr-2">
                       <b-field style="text-align: left; padding-right: 20px;">
              <b-radio-button v-model="status" :native-value="0" type="is-primary">
                <span>Aktive</span>
              </b-radio-button>

              <b-radio-button v-model="status" :native-value="1" type="is-primary">
                <span>Inaktive</span>
              </b-radio-button>
            </b-field>
          </div>
        </div>
      </template>
      <template #empty>Ingen treff</template>
      <Column headerStyle="width: 3rem" :rowReorder="true"></Column>
      <Column field="name" header="Datafelt"></Column>
      <Column field="description" header="Beskrivelse"></Column>


      <Column bodyStyle="text-align:right;">
        <template #body="slotProps">
          <Button
            label="Endre"
            @click="editData(slotProps.data)"
            class="p-mr-2 p-button-danger p-button-rounded "
          />
        </template>
      </Column>
    </DataTable>

    <Dialog
      :visible.sync="dataDialog"
      :style="{width: '450px'}"
      header="Endre datafeltkategori"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label style="float:left;" for="name">Name</label>
        <InputText
          id="name"
          v-model.trim="datafieldCategory.name"
          required="true"
          
          :class="{'p-invalid': submitted && !datafieldCategory.name}"
        />
        <small class="p-invalid" v-if="submitted && !datafieldCategory.name">Name is required.</small>
      </div>

            <div class="p-field">
        <label style="float:left;" for="name">Beskrivelse</label>
        <InputText
          id="name"
          v-model.trim="datafieldCategory.description"
          required="true"
          
          :class="{'p-invalid': submitted && !datafieldCategory.name}"
        />
        <small class="p-invalid" v-if="submitted && !datafieldCategory.name">Name is required.</small>
      </div>
      
      <template #footer>
        <div v-if="datafieldCategory.status==0">
          <Button
            style="float:left;"
            label="Arkiver"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="updateStatus(1)"
          />
          <Button @click="updateDatafieldCategory(datafieldCategory)" label="Lagre" icon="pi pi-check" class="p-button-primary marginl" />
        </div>
        <div v-if="datafieldCategory.status==1">
          <Button
            style="float:left;"
            label="Slett"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="displayConfirmation=true"
          />
          <Button @click="updateStatus(0)" label="Aktiver" icon="pi pi-check" class="p-button-primary marginl" />
        </div>
      </template>
    </Dialog>
      <Dialog
      header="Advarsel!"
      :visible.sync="displayConfirmation"
      :style="{width: '350px'}"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>Er du sikker på at du vil slette datafeltkategori?</span>
      </div>
      <template #footer>
        <Button
          label="Nei"
          icon="pi pi-times"
          @click="displayConfirmation=false"
          class="p-button-text"
        />
        <Button
          label="Ja"
          icon="pi pi-check"
          @click="updateStatus(2)"
          class="p-button-text"
          
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import DashboardService from "@/services/Dashboard";
import TemplateService from "@/services/Template"
export default {
  data() {
    return {
      submitted: false,
      dataDialog: false,
      displayConfirmation:false,
      deleteCustomerDialog: false,
      templateService:null,
      dashboardService: null,
      filters: {},
      status: 0,
      datafieldCategories: [],
      datafieldCategory: {},
    };
  },

  watch: {
    status: function () {
      this.fetchData();
    },
  },

  methods: {
              onRowReorder(event) {
            this.datafieldCategories = event.value;
            this.$toast.add({severity:'success', summary: 'Rows Reordered', life: 3000});
        },
    hideDialog() {
      this.dataDialog = false;
      this.submitted = false;
    },
    fetchData() {
      this.dashboardService
        .getDatafieldCategories(this.status)
        .then((data) => (this.datafieldCategories = data));
    },
    updateStatus(status){
      const datafieldCategory = {id:this.datafieldCategory.id, status:status}
      this.templateService
      .updateDatafieldCategory(datafieldCategory)
      .then((data)=>this.fetchData())
      this.dataDialog=false
      this.displayConfirmation=false
    },
    updateDatafieldCategory(datafieldCategory){
        this.templateService
        .updateDatafieldCategory(datafieldCategory)
        .then((data)=>this.fetchData())
              this.dataDialog=false
      this.displayConfirmation=false

    },
    editData(datafieldCategory) {
      this.datafieldCategory = { ...datafieldCategory };
      this.dataDialog = true;
    },
  },

  created() {
    this.dashboardService = new DashboardService();
    this.templateService = new TemplateService();
  },

  mounted() {
   this.fetchData()
   }
};
</script>
<style scoped>
.p-dialog-mask {
  position: fixed;
}
</style>