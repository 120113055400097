<template>
  <div>
    <InventorySettingNav />
    <div>
      <Button class="p-mr-2 p-button-primary marginl" @click="storageModal=true">Nytt lager</Button>
    </div>
    <Dialog header="Nytt lager"
      modal
      :closable="true"
      :showHeader="true"
      :visible.sync="storageModal"
    >
      <NewStorage @clicked="storageModal=false;key++" />
    </Dialog>

    <TableStorage :key="key" />
  </div>
</template>

<script>
import InventorySettingNav from "@/components/UI/InventorySettingNav.vue";
import TableStorage from "@/components/Dashboard/InventoryModule/TableStorage.vue";
import NewStorage from "@/components/Dashboard/InventoryModule/NewStorage.vue";
export default {
  data() {
    return {
      key: 0,
      storageModal: false,
    };
  },
  components: {
    InventorySettingNav,
    TableStorage,
    NewStorage,
  },
};
</script>
