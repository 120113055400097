<template>
  <div>
    <InventorySettingNav />
    <div>
      <Button class="p-mr-2 p-button-primary marginl" @click="manufacturerModal=true">Ny produsent</Button>
    </div>
    <Dialog header="Ny produsent"
      modal
      :closable="true"
      :showHeader="true"
      :visible.sync="manufacturerModal"
    >
      <NewManufacturer @clicked="manufacturerModal=false;key++" />
    </Dialog>

    <TableManufacturer :key="key" />
  </div>
</template>

<script>
import InventorySettingNav from "@/components/UI/InventorySettingNav.vue";
import TableManufacturer from "@/components/Dashboard/InventoryModule/TableManufacturer.vue";
import NewManufacturer from "@/components/Dashboard/InventoryModule/NewManufacturer.vue";
export default {
  data() {
    return {
      key: 0,
      manufacturerModal: false,
    };
  },
  components: {
    InventorySettingNav,
    TableManufacturer,
    NewManufacturer,
  },
};
</script>
