import axios from 'axios'

export default class CustomerService {
	
	//Finn brukere
	getUsers(status){
		return axios.get('api/getusers/'+status).then(res=>res.data.data)
	}

	//Finn bedrifter
    getCompanies(status){
		return axios.get('api/getcompanies/'+status).then(res=>res.data.data)
	}
	//Opprett bedrift
	createCompany(company){
		return axios.post('api/createcompany',company).then(res=>res.data.data)
	}
	//Oppdater beedrift
	updateCompany(company){
		return axios.post('api/updatecompany',company).then(res=>res.data.data)
	}

	//Finn kunde med status 0 / 1
	getCustomers(status) {
		return axios.get('api/findcustomers/'+status).then(res => res.data.data);
	
	//Oppdater kunde
	}
	updateCustomer(customer){
		return axios.post('api/updatecustomer', customer).then(res=>res.data.data)
	}
	
	//Opprett ny kunde
	newCustomer(customer){
		console.log(customer)
		return axios.post('api/newcustomer', customer).then(res=>res.data.data)
	}


}

