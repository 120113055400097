<template>
  <div>
    
    <br>
    <div style="padding-left:10px;"> 

    <span  class="p-input-icon-left">
        <i class="pi pi-search" />
        <InputText type="text" v-model="search" placeholder="Søk" />
    </span>
    </div>

    <b-tabs v-if="search==''" @input="onChange(1)" v-model="activeTab" :animated="false">
      <b-tab-item
        v-for="(category, index) in testCategories"
        :key="index"
        :label="category.project_category"
      >
        <div class="p-grid">
          <div
            @click="$router.push('/project/' + project.id)"
            style="text-align: left"
            class="p-col-12 p-xl-3 p-lg-4 p-md-4 p-sm-12"
            v-for="project in categoryfunc(category)"
            :key="project.id"
          >
            <Card>
              <template slot="title">
                {{ project.projectname }} 
                <Button
                  @click.stop="togglefavorite(project.id)"
                  style="float: right"
                  :icon="pinned(project.id)"
                  class="p-button p-button-text p-button-rounded"
                />
              </template>
              <template slot="subtitle">
                {{ project.Customer.customername }}
                <br />
                {{ project.Project_Category.project_category }}
              </template>
              <template slot="content">
                Tidsfrist: {{ project.deadline }}
                <br>
                <span v-if="project.project_leader">Prosjektleder: {{project.project_leader}}</span>
                <span v-else><br></span>
              </template>
              <template slot="footer"></template>
            </Card>
          </div>
        </div>
      </b-tab-item>
    </b-tabs>

<div v-if="search!=''">  


  <div class="p-grid">
          <div
            @click="$router.push('/project/' + project.id)"
            style="text-align: left"
            class="p-col-12 p-xl-3 p-lg-4 p-md-4 p-sm-12"
            v-for="project in categoryfunc({id:99,project_category:'Søk'})"
            :key="project.id"
          >
            <Card>
              <template slot="title">
                {{ project.projectname }} 
                <Button
                  @click.stop="togglefavorite(project.id)"
                  style="float: right"
                  :icon="pinned(project.id)"
                  class="p-button p-button-text p-button-rounded"
                />
              </template>
              <template slot="subtitle">
                {{ project.Customer.customername }}
                <br />
                {{ project.Project_Category.project_category }}
              </template>
              <template slot="content">
                <p>Tidsfrist: {{ project.deadline }}</p>
              </template>
              <template slot="footer"></template>
            </Card>
          </div>
  </div>
        </div></div>

  </div>
</template>

<script>
import ProjectService from "@/services/Project";
import TemplateService from "@/services/Template";
export default {
  data() {
    return {
      activeTab: null,
      categoryIndex: undefined,
      favorite: [],
      search:'',
      inactive: [],
      ready: false,
      active: [],
      projectCategories: [],
    };
  },

  created() {
    this.projectService = new ProjectService();
    this.templateService = new TemplateService();
    // this.activeTab=0
  },

  mounted() {
    localStorage.removeItem("lastTemplate");
    if (localStorage.favoriteProjects && localStorage.favoriteProjects !== "") {
      this.favorite = localStorage.favoriteProjects.split`,`.map((x) => +x);
    }
    this.fetchData();
  },
  computed: {
    testCategories: function () {
      console.log(this.projectCategories);
      return this.projectCategories;
    },
    fav: function () {
      const favorites = this.favorite;
      const active = this.active;

      return active.filter((element) => favorites.includes(element.id));
    },
  },

  methods: {

    onChange(val) {
      localStorage.lastProjectCategory = this.activeTab;
      // console.log(this.activeTab);
    },
    setIndex() {
      this.activeTab = null;
      this.activeTab = 0;
      let index = parseFloat(localStorage.lastProjectCategory);
      this.activeTab = index;
      this.checkIndex();
    },
    checkIndex() {
      let index = this.activeTab;

      if (localStorage.lastProjectCategory && this.projectCategories) {
        if (localStorage.favoriteProjects == "") {
          this.activeTab = index;
        } else {
          if (this.projectCategories[0].id !== 0) {
            this.projectCategories.unshift({
              id: 0,
              project_category: "Favoritter",
              status: 0,
            });

          }
        }
      }
    },

    categoryfunc(category) {
      // console.log('func' + id)
      console.log(this.active[0])
      if (category.id === 0) {
        return this.fav;
      }
      if(category.project_category==='Søk'){
        // return this.active.filter(function(str){return str.indexOf(this.search)===1})
        return this.active.filter((element) => element.projectname.toLowerCase().includes(this.search.toLowerCase()))
      }
      //Implementer denne igjen på en måte.

      // v-if="categoryfunc(category.id).length > 0"
      // for (category, index) in categories

      //Brukes bare for å filtrere ut tomme prosjektkategorier.

      // console.log(this.active);
      return this.active.filter((element) => category.id == element.projectcategory);
    },
    pinned(project) {
      if (this.favorite.includes(project)) {
        return "pi pi-star";
      }
      return "pi pi-star-o";
    },
    togglefavorite(id) {
      if (!this.favorite.includes(id)) {
        this.favorite.push(id);
      } else {
        const index = this.favorite.indexOf(id);
        if (index > -1) {
          this.favorite.splice(index, 1);
        }
      }

      localStorage.favoriteProjects = this.favorite;
      if (this.favorite.length == 0 && this.projectCategories[0].id === 0) {
        this.projectCategories.shift();
        // console.log('//////////Skal returne')
        this.activeTab--;
        return;
      }
      this.checkIndex();
    },

    fetchData() {
      this.templateService.get;
      this.projectService.getProjects(0).then((data) => (this.active = data));
      this.projectService.getProjects(1).then((data) => (this.inactive = data));
      this.projectService
        .getProjectCategories()
        .then((data) => (this.projectCategories = data))
        .then(() => (this.ready = true))
        .then(() => this.setIndex());
      // .then(() => this.checkIndex());
    },
  },
};
</script>

<style>
.p-card-body {
  cursor: pointer;
}
</style>