<template>
  <div style="padding:20px;">
    <!-- <h4 style="text-align:left;" class="p-pb-2">Ny datafeltkategori</h4> -->

    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-6 p-mt-2">
     
          <h6 for="categoryname">Kategorinavn</h6>
          <InputText v-model="datafieldCategory.name" id="categoryname" type="text" />
      </div>
      <div class="p-field p-col-12 p-md-6 p-mt-2">
          <h6 for="categorydescription">Beskrivelse</h6>
          <InputText v-model="datafieldCategory.description" id="categorydescription" type="text" />
      </div>
    </div>
    <div>
      <!-- <Button
        style="float:left;"
        label="Avbryt"
        icon="pi pi-times"
        class="p-button-danger "
        @click="visible=false"
      /> -->

      <Button @click="newDatafieldCategory(datafieldCategory)" label="Lagre" icon="pi pi-check" class="p-button-primary" />
    </div>
  </div>
</template>

<script>
// import Users from "@/services/Users";
import TemplateService from "@/services/Template";
export default {
  methods: {
      newDatafieldCategory(datafieldCategory) {
      this.templateService
        .createDatafieldCategory(datafieldCategory)
        .then((data) => this.$emit("clicked", "newdatafieldCategory"));
        ;
        ;
    },
  },
  created() {
    this.templateService = new TemplateService();
  },

  props: ["status"],
  data() {
    return {
      datafieldCategory: {},
    };
  },
};
</script>

<style>
</style>