<template>
  <div>
    <div>
      <h1>NeXT</h1>
    </div>

    <div class="p-grid">
      <div class="p-col-4 box">
        <Button
          @click="
            newComment = true;
            newPost = true;
          "
          class="p-button-primary"
          label="Nytt innlegg"
        />
        <br /><br />
        <div class="card" v-for="article in articles" :key="article.id">
          <h4>
            {{ article.title }}
            <span class="author"
              >{{ article.User.firstname }} {{ article.User.lastname }}</span
            >
          </h4>
          {{ article.content }}
          <br />
          <br />
        </div>
      </div>
      <div class="p-col-4 box">
        <Button
          @click="newComment = true"
          class="p-button-primary"
          label="Ny kommentar"
        />
        <br />
        <br />
        <div class="card" v-for="comment in comments" :key="comment.id">
          {{ comment.content }}
          <span class="author"
            >{{ comment.User.firstname }} {{ comment.User.lastname }}</span
          >
        </div>
      </div>
    </div>

    <Dialog
      header="Ny kommentar"
      modal
      :closable="true"
      :showHeader="true"
      :visible.sync="newComment"
    >
      <div class="container">
        <form method="post" @submit.prevent="postComment(post)">
          <div v-if="newPost" class="field">
            <div class="control">
              <InputText type="text" v-model="post.title" />
            </div>
          </div>
          <div class="field">
            <div class="control">
              <Textarea
                v-model="post.content"
                :autoResize="true"
                rows="5"
                cols="35"
                required
              />
            </div>
          </div>
          <div class="control">
            <button type="submit" class="button is-primary is-fullwidth">
              Send
            </button>
          </div>
        </form>
      </div>
    </Dialog>
  </div>
</template>

<script>
import ProjectService from "@/services/Project";
import HomeService from "@/services/Home";
import Projects from "@/components/Overview/Projects";
export default {
  components: {
    Projects,
  },
  data() {
    return {
      newComment: false,
      newPost: false,
      post: {},
      articles: [],
      comments: [],
    };
  },

  watch: {
    newComment: function () {
      if (this.newComment == false) {
        this.newPost = false;
      }
      this.post = {};
    },
  },

  created() {
    this.projectService = new ProjectService();
    this.homeService = new HomeService();
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    postComment(post) {
      if (this.newPost) {
        this.post.type = "article";
      } else {
        this.post.type = "comment";
      }
      this.homeService.postComment(post).then((data) => console.log(data));
      this.newComment = false;
      this.fetchData();
    },
    onSortChange(event) {
      const value = event.value.value;
      const sortValue = event.value;

      if (value.indexOf("!") === 0) {
        this.sortOrder = -1;
        this.sortField = value.substring(1, value.length);
        this.sortKey = sortValue;
      } else {
        this.sortOrder = 1;
        this.sortField = value;
        this.sortKey = sortValue;
      }
    },
    fetchData() {
      // this.projectService.getProjects().then((data) => (this.projects = data));
      this.homeService.getComments().then((data) => (this.comments = data));
      this.homeService.getArticles().then((data) => (this.articles = data));
    },
  },
};
</script>

<style>
.box {
  background: lightblue;
  margin: 20px;
}
.card {
  height: 60px;
  margin: 10px;
  background: lightgray;
}
.author {
  font-size: 0.8rem;
}
</style>