<template>
  <div v-if="!$store.getters.isAuthenticated" style="padding:20px; position:absoulute; margin-top:8%;" class>
    <h1 style="margin-bottom:10px;  ">New User</h1>
    <div class="p-d-inline-flex">
      <div class="p-align-center p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6 p-mt-3">
          <span class="p-float-label">
            <InputText v-model="user.email" id="firstname" type="email" />
            <label for="firstname">Epost</label>
          </span>
        </div>
        <div class="p-field p-col-12 p-md-6 p-mt-3">
          <span class="p-float-label">
            <InputText v-model="user.password" id="lastname" type="password" />
            <label for="lastname">Passord</label>
          </span>
        </div>
        <div class="p-field p-col-12 p-md-12">
          <Button @click="login()" type="button" label="Logg inn" />
        </div>
        <div v-if="error">
          <h1>{{error}}</h1>
          </div>

        <div class="p-field p-col-12 p-md-12">
          <a @click="logout()">Glemt passord</a>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// import User from '../models/user';
import axios from 'axios'

export default {
  computed:{
    firstname(){
      return !this.$store.getters.user ? '' : this.$store.getters.user.firstname
      // return 'ok'
    }
  },
  data() {
    return {
      // user: new User('', ''),
      user: {
        email: "tobias@laaneits.no",
        password: "123",
      },
      loading: false,
      message: '',
      error:null
    };
  },
  created() {
    if(this.$store.getters.isAuthenticated){
      this.$router.go(-1)
    }
  },
  methods: {
    login(){
      const user = this.user
      this.$store.dispatch('login', user).then((data)=>this.error=data)

    }
  }
}

</script>