<template>
  <div style="height:100%;">
    <DataTable :autoLayout="true" :filters="filters" removableSort :value="projects" data-key="id">
      <template #header>
        <div class="p-col-12">
          <div style="text-align:left;">
            <h4>Prosjekter</h4>
          </div>
        </div>
        <div class="p-d-flex p-flex-column p-flex-md-row">
          <div class="p-mb-1 p-mr-2">
            <div style="text-align:left;">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global']" placeholder="Søk" />
              </span>
            </div>
          </div>
          <div class="p-mb-1 p-mr-2">
            <b-field style="text-align: left; padding-right: 20px;">
              <b-radio-button v-model="status" :native-value="0" type="is-primary">
                <span>Aktive</span>
              </b-radio-button>

              <b-radio-button v-model="status" :native-value="1" type="is-primary">
                <span>Inaktive</span>
              </b-radio-button>
            </b-field>
          </div>
        </div>
      </template>
      <template #empty>Ingen treff</template>

      <Column sortable field="projectname" header="Prosjektnavn"></Column>
      <Column sortable field="Project_Category.project_category" header="Kategori"></Column>
      <Column sortable field="Customer.customername" header="Kunde"></Column>
      <Column sortable field="deadline" header="Tidsfrist"></Column>

      <Column bodyStyle="text-align:right;">
        <template #body="slotProps">
          <Button
            label="Endre"
            @click="$router.push('/editproject/'+slotProps.data.id)"
            class="p-mr-2 p-button-danger p-button-rounded"
          />
        </template>
      </Column>
    </DataTable>

    <Dialog
      :visible.sync="dataDialog"
      :style="{width: '450px'}"
      header="Prosjektkategori"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label style="float:left;" for="name">Navn</label>
        <InputText
          id="name"
          v-model.trim="project.project_category"
          required="true"
          
          :class="{'p-invalid': submitted && !project.name}"
        />
        <small class="p-invalid" v-if="submitted && !project.name">Name is required.</small>
      </div>

      <template #footer>
        <div v-if="project.status==0">
          <Button
            style="float:left;"
            label="Arkiver"
            icon="pi pi-trash"
            class="p-button-danger p-button-text"
            @click="updateStatus(1)"
          />
          <Button
            @click="updateProjectCategory()"
            label="Lagre"
            icon="pi pi-check"
            class="p-button-text"
          />
        </div>
        <div v-if="project.status==1">
          <Button
            style="float:left;"
            label="Slett"
            icon="pi pi-trash"
            class="p-button-danger p-button-text"
            @click="displayConfirmation=true"
          />
          <Button
            @click="updateStatus(0)"
            label="Aktiver"
            icon="pi pi-check"
            class="p-button-text"
          />
        </div>
      </template>
    </Dialog>
  </div>
</template>



<script>
import ProjectService from "@/services/Project";
export default {
  data() {
    return {
      displayConfirmation: false,
      submitted: false,
      dataDialog: false,
      projectService: null,
      filters: {},
      status: 0,
      projects: [],
      project: {},
    };
  },

  watch: {
    status: function () {
      this.fetchData();
    },
  },

  methods: {
    hideDialog() {
      this.dataDialog = false;
      this.submitted = false;
    },
    fetchData() {
      this.projectService
        .getProjects(this.status)
        .then((data) => (this.projects = data));
    },

    editData(project) {
      this.project = { ...project };
      this.dataDialog = true;
    },
  },

  created() {
    this.projectService = new ProjectService();
  },

  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
.p-dialog-mask {
  position: fixed;
}
</style>