<template>
<div> 

    <div style=" z-index:18;  width:100%;" class="tabs ">
      <ul>
        <router-link active-class="is-active" tag="li" :to="'/dashboard/products'"><a>Produkter</a></router-link>
        <router-link active-class="is-active" tag="li" :to="'/dashboard/storages'"><a>Lager</a></router-link>
        <router-link active-class="is-active" tag="li" :to="'/dashboard/productcategories'"><a>Produktkategori</a></router-link>
        <router-link active-class="is-active" tag="li" :to="'/dashboard/locations'"><a>Lokasjon</a></router-link>
        <router-link active-class="is-active" tag="li" :to="'/dashboard/inventories'"><a>Lagerposisjon</a></router-link>
        <router-link active-class="is-active" tag="li" :to="'/dashboard/suppliers'"><a>Leverandør</a></router-link>
        <router-link active-class="is-active" tag="li" :to="'/dashboard/manufacturers'"><a>Produsent</a></router-link>
        
        
      </ul>
    </div>

</div>
</template>


<script>

export default {
    components:{

    },
    data() {
        return {


        }
    },
    methods: {
        activate(index) {
            let activeArray = [...this.active];
            for (let i = 0 ; i < activeArray.length; i++) {
                activeArray[i] = (i === index);
            }
            this.active = activeArray;
        }
    }
}
</script>

<style lang="scss" scoped>

a:hover{
    text-decoration:none;
}

.tabs{
    padding-bottom:0px;
    margin:0;
}

</style>