<template>
  <div style="padding:20px;">
    <h4 style="text-align:left;" class="p-pb-2">Nytt produkt</h4>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-12 p-mt-2">
        <span class="p-float-label">
          <InputText v-model="product.name" id="name" type="text" />
          <label for="name">Produktnavn</label>
        </span>
      </div>
      <div class="p-field p-col-6 p-md-6 p-mt-2">
        <span class="p-float-label">
          <InputText v-model="product.article_number" id="article_number" type="text" />
          <label for="article_number">Artikkel nummer</label>
        </span>
      </div>
      <div class="p-field p-col-6 p-md-6 p-mt-2">
        <span class="p-float-label">
          <InputText v-model="product.elnumber" id="elnumber" type="text" />
          <label for="elnumber">Elnummer</label>
        </span>
      </div>
      <div class="p-field p-col-12 p-md-6 p-mt-2">
        <span class="p-float-label">
          <Dropdown
            style="text-align:left;"
            v-model="product.category"
            :options="productCategories"
            optionValue="id"
            optionLabel="name"
          />
          <label for="category">Kategori</label>
        </span>
      </div>

      <div class="p-field p-col-12 p-md-6 p-mt-2">
        <span class="p-float-label">
          <InputText v-model="product.min_quantity" id="min_quantity" type="text" />
          <label for="min_quantity">Minste lagerbeholdning</label>
        </span>
      </div>

      <div class="p-field p-col-6 p-md-6 p-mt-2">
        <span class="p-float-label">
          <InputText v-model="product.quantity_ip" id="quantity_ip" type="text" />
          <label for="quantity_ip">Per pk.</label>
        </span>
      </div>
      <div class="p-field p-col-6 p-md-6 p-mt-2">
        <span class="p-float-label">
          <InputText v-model="product.price_per" id="price_per" type="text" />
          <label for="price_per">Pris per</label>
        </span>
      </div>

      <div class="p-field p-col-12 p-md-6 p-mt-2">
        <span class="p-float-label">
          <Dropdown
            style="text-align:left;"
            v-model="product.manufacturer"
            :options="manufacturers"
            optionValue="id"
            optionLabel="name"
          />
          <label for="manufacturers">Produsent?</label>
        </span>
      </div>

      <div class="p-field p-col-12 p-md-6 p-mt-2">
        <span class="p-float-label">
          <Dropdown
            style="text-align:left;"
            v-model="product.supplier"
            :options="suppliers"
            optionValue="id"
            optionLabel="name"
          />
          <label for="supplier">Leverandør</label>
        </span>
      </div>
      <div class="p-field p-col-12 p-md-12 p-mt-2">
        <span class="p-float-label">
          <InputText v-model="product.description" id="description" type="text" />
          <label for="description">Beskrivelse</label>
        </span>
      </div>
    </div>
    <div>
      <Button
        @click="newProduct(product)"
        label="Lagre"
        icon="pi pi-check"
        class="p-button-primary"
      />
    </div>
  </div>
</template>

<script>
import CustomerService from "@/services/Customer";
import InventoryService from "@/services/Inventory/Inventory";
export default {
  methods: {
    fetchSuppliers() {
      this.inventoryService
        .getSuppliers(0)
        .then((data) => (this.suppliers = data));
    },
    fetchManufacturers() {
      this.inventoryService
        .getManufacturers(0)
        .then((data) => (this.manufacturers = data));
    },
    fetchProductCategories() {
      this.inventoryService
        .getProductCategories(0)
        .then((data) => (this.productCategories = data));
    },
    newProduct(product) {
      this.inventoryService
        .createProduct(product)
        .then((data) => console.log(data));
      this.$emit("clicked", "newproduct");
    },
  },

  data() {
    return {
      product: {},
      inventoryService: null,
      productCategories: [],
      manufacturers: [],
      suppliers: [],
    };
  },
  created() {
    this.inventoryService = new InventoryService();
  },
  mounted() {
    this.fetchSuppliers(),
      this.fetchManufacturers(),
      this.fetchProductCategories();
  },
};
</script>

<style>
</style>