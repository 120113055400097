S<template>
  <div style="height:100%;">
    <DataTable :autoLayout="true" :filters="filters" removableSort :value="suppliers" data-key="id">
      <template #header>
        <div class="p-col-12">
          <div style="text-align:left;">
            <h4>Leverandører</h4>
          </div>
        </div>
        <div class="p-d-flex p-flex-column p-flex-md-row">
          <div class="p-mb-1 p-mr-2">
            <div style="text-align:left;">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global']" placeholder="Søk" />
              </span>
            </div>
          </div>
          <div class="p-mb-1 p-mr-2">
                      <b-field style="text-align: left; padding-right: 20px;">
              <b-radio-button v-model="status" :native-value="0" type="is-primary">
                <span>Aktive</span>
              </b-radio-button>

              <b-radio-button v-model="status" :native-value="1" type="is-primary">
                <span>Inaktive</span>
              </b-radio-button>
            </b-field>
          </div>
        </div>
      </template>
      <template #empty>Ingen treff</template>

      <Column sortable field="name" header="Navn"></Column>
      <Column sortable field="address" header="Adresse"></Column> 
      <Column sortable field="email" header="Email"></Column>
      <Column sortable field="phone" header="Telefon"></Column>
      <Column sortable field="contact" header="Kontaktperson"></Column>
      <Column bodyStyle="text-align:right;" header>
        <template #body="slotProps">
          <Button
            label="Endre"
            @click="editSupplier(slotProps.data)"
            class="p-mr-2 p-button-danger p-button-rounded"
          />
        </template>
      </Column>
    </DataTable>

    <Dialog
      :visible.sync="supplierDialog"
      :style="{width: '450px'}"
      header="Endre lokasjon"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field p-col-12 p-md-12 p-mt-2">
        <span class="p-float-label">
          <InputText v-model="supplier.name" id="name" type="text" />
          <label for="name">Navn</label>
        </span>
      </div>

            <div class="p-field p-col-12 p-md-12 p-mt-2">
        <span class="p-float-label">
          <InputText v-model="supplier.address" id="address" type="text" />
          <label for="address">Adresse</label>
        </span>
      </div>

            <div class="p-field p-col-12 p-md-12 p-mt-2">
        <span class="p-float-label">
          <InputText v-model="supplier.email" id="email" type="text" />
          <label for="email">Email</label>
        </span>
      </div>

            <div class="p-field p-col-12 p-md-12 p-mt-2">
        <span class="p-float-label">
          <InputText v-model="supplier.phone" id="phone" type="text" />
          <label for="phone">Telefon</label>
        </span>
      </div>

            <div class="p-field p-col-12 p-md-12 p-mt-2">
        <span class="p-float-label">
          <InputText v-model="supplier.contact" id="contact" type="text" />
          <label for="contact">Kontaktperson</label>
        </span>
      </div>

      <template #footer>
        <div v-if="supplier.status==0">
          <Button
            style="float:left;"
            label="Deaktiver"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="updateStatus(1)"
          />
          <Button @click="updateSupplier(supplier)" label="Lagre" icon="pi pi-check" class="p-button-primary" />
        </div>
        <div v-if="supplier.status==1">
          <Button
            style="float:left;"
            label="Slett"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="displayConfirmation=true"
          />
          <Button @click="updateStatus(0)" label="Aktiver" icon="pi pi-check" class="p-button-danger" />
        </div>
      </template>
    </Dialog>
            <Dialog
      header="Advarsel!"
      :visible.sync="displayConfirmation"
      :style="{width: '350px'}"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>Er du sikker på at du vil slette lokasjonen?</span>
      </div>
      <template #footer>
        <Button
          label="Nei"
          icon="pi pi-times"
          @click="displayConfirmation=false"
          class="p-button-text"
        />
        <Button
          label="Ja"
          icon="pi pi-check"
          @click="updateStatus(2)"
          class="p-button-text"
          
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import InventoryService from "@/services/Inventory/Inventory";
export default {
  data() {
    return {
      displayConfirmation:false,
      supplierDialog: false,
      deletesupplierDialog: false,
      supplier: {},
      submitted: false,
      productService: null,
      filters: {},
      status: 0,
      suppliers: [],
      storages:[],
    };
  },

  watch: {
    status: function () {
      this.fetchData();
    },
  },

  methods: {
    updateStatus(status){
      const supplier = {id:this.supplier.id, status:status}
      this.inventoryService
       .updateSupplier(supplier)
       .then((data)=>this.fetchData())
       this.supplierDialog=false
       this.displayConfirmation = false
    },
    updateSupplier(supplier){
      this.inventoryService
      .updateSupplier(supplier)
      .then((data)=>(this.fetchData()))
       this.supplierDialog=false
       this.displayConfirmation = false
      
    },    
    hideDialog() {
      this.supplierDialog = false;
      this.submitted = false;
    },
    fetchData() {
      this.inventoryService
        .getSuppliers(this.status)
        .then((data) => (this.suppliers = data));
    },

    editSupplier(supplier) {
      this.supplier = { ...supplier };
      this.supplierDialog = true;
    },
  },

  created() {
    this.inventoryService = new InventoryService();
  },

  mounted() {
    this.fetchData();
  },
};
</script>