<template>
  <div style="padding:20px;">
    <h4 style="text-align:left;" class="p-pb-2">Nytt lager</h4>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field -12 p-md-12 p-mt-2">
        <span class="p-float-label">
          <InputText v-model="storage.name" id="name" type="text" />
          <label for="name">Lagernavn</label>
        </span>
      </div>
      <div class="p-field p-col-12 p-md-12 p-mt-2">
        <span class="p-float-label">
          <InputText v-model="storage.address" id="address" type="text" />
          <label for="address">Adresse</label>
        </span>
      </div>
      <div class="p-field p-col-12 p-md-12 p-mt-2">
        <span class="p-float-label">
          <InputText v-model="storage.comment" id="comment" type="text" />
          <label for="comment">Kommentar</label>
        </span>
      </div>
    </div>
    <div>
      <Button
        @click="newStorage(storage)"
        label="Lagre"
        icon="pi pi-check"
        class="p-button-primary"
      />
    </div>
  </div>
</template>

<script>
import CustomerService from "@/services/Customer";
import InventoryService from "@/services/Inventory/Inventory";
export default {
  methods: {
    newStorage(storage) {
      this.inventoryService
        .createStorage(storage)
        .then((data) => console.log(data));
      this.$emit("clicked", "newstorage");
    },
  },

  data() {
    return {
      storage: {},
      inventoryService: null,
    };
  },
  created() {
    this.inventoryService = new InventoryService();
  },
};
</script>

<style>
</style>