<template>
  <div>
    <InventorySettingNav />
    <div>
      <Button class="p-mr-2 p-button-primary marginl" @click="supplierModal=true">Ny leverandør</Button>
    </div>
    <Dialog header="Ny leverandør"
      modal
      :closable="true"
      :showHeader="true"
      :visible.sync="supplierModal"
    >
      <NewSupplier @clicked="supplierModal=false;key++" />
    </Dialog>

    <TableSupplier :key="key" />
  </div>
</template>

<script>
import InventorySettingNav from "@/components/UI/InventorySettingNav.vue";
import TableSupplier from "@/components/Dashboard/InventoryModule/TableSupplier.vue";
import NewSupplier from "@/components/Dashboard/InventoryModule/NewSupplier.vue";
export default {
  data() {
    return {
      key: 0,
      supplierModal: false,
    };
  },
  components: {
    InventorySettingNav,
    TableSupplier,
    NewSupplier,
  },
};
</script>
