<template>
<div> 

    <div style=" z-index:18;  width:100%;" class="tabs ">
      <ul>
        <router-link active-class="is-active" tag="li" :to="'/dashboard/users'"><a>Brukere</a></router-link>
        <!-- <router-link active-class="is-active" tag="li" :to="'/dashboard/customers'"><a>Kunder</a></router-link> -->
        <router-link active-class="is-active" tag="li" :to="'/dashboard/companies'"><a>Bedrifter</a></router-link>
      </ul>
    </div>

</div>
</template>


<script>

export default {
    components:{

    },
    data() {
        return {
           dashboard:[
               {label:'Brukere', to:'/dashboard/users'},
               {label:'Kunder', to:'/dashboard/customers'},
               {label:'Bedrifter', to:'/dashboard/companies'},
           ],
            active: [true, false, false]
        }
    },
    methods: {
        activate(index) {
            let activeArray = [...this.active];
            for (let i = 0 ; i < activeArray.length; i++) {
                activeArray[i] = (i === index);
            }
            this.active = activeArray;
        }
    }
}
</script>

<style lang="scss" scoped>

a:hover{
    text-decoration:none;
}

.tabs{
    padding-bottom:0px;
    margin:0;
}

</style>