<template>
  <section class="section">
    <div class="container">
      <h2 class="title">Min profil</h2>

      <div class="columns">
        <div class="column">
          <b-field label="Navn">
            <b-input disabled :value="user.firstname+ ' ' + user.lastname"></b-input>
          </b-field>
        </div>

        <div class="column">
          <b-field label="Epost">
            <b-input disabled type="email" v-model="user.email"></b-input>
          </b-field>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <b-field label="Telefonnummer">
            <b-input type="text"  v-model="updated.phonenumber"></b-input>
          </b-field>
        </div>
        <div class="column">
          <div v-if="(firmalist.length>1) && (firmabytte=true)">
            <b-field label="Firma">
              <b-select expanded disabled required v-model="user.company" placeholder="Velg firma">
                <option
                  v-for="option in firmalist"
                  :value="option.id"
                  :key="option.id"
                >{{ option.companyname }}</option>
              </b-select>
            </b-field>
            <!-- <b-button @click="updateUser()" style="display:inline;" class="is-warning">Bytt firma</b-button> -->
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <b-field label="Nytt passord">
            <b-input  type v-model="password.one"></b-input>
          </b-field>
        </div>

        <div class="column">
          <b-field label="Bekreft passord">
            <b-input  type v-model="password.two"></b-input>
          </b-field>
        </div>
        
      </div>
      <div class="columns">
        <div class="column">
                    <b-field label="NFC Kode">
            <b-input  type v-model="nfc"></b-input>
          </b-field>
        </div>
      </div>
    <Button @click="updateUser()" class="p-button-primary"> Oppdater  </Button>
    </div>
  </section>
</template>

<script>


import axios from "axios";
import UserService from "@/services/User"
export default {
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  data() {
    return {
      modalActive: false,
      endre: 0,
      password: {
        one: "",
        two: "",
      },
      nfc:null,
      isOpen: false,
      nyttnavn: "",
      updated:this.$store.getters.user,
      customername: "",
      firma: "",
      firmabytte: false,
      firmalist: "",
      kundeliste: [],
      notification: {
        aktiv: false,
        text: "",
        type: "",
      },
    };
  },

  created() {
    // this.getUser();
    // this.getCompany();
    this.userService = new UserService();
    
  },
mounted(){
this.updated=this.user
},
  methods: {

    async updateUser() {
        console.log(this.password.one)
        console.log(this.password.two)
      let data = {
        phonenumber: this.updated.phonenumber,
        nfc:this.nfc

    
      };
      if (this.password.one !== this.password.two) {
          console.log('Passord matcher ikke')
      } else if (this.password.one.length<2){
        console.log('Passord er ikke langt nok')
      } else {
          data.password = this.password.one;
        this.userService
      .updateUser(data)
      .then((data)=>console.log(data))
      }




    //   this.$axios
    //     .post("api/updateuser", data)
    //     .then(
    //       (response) => (
    //         this.$buefy.toast.open({
    //           message: response.data.message,
    //           type: response.data.type,
    //         }),
    //         this.getUser()
    //       )
    //     )
    //     .catch((e) => {
    //       (this.notification.aktiv = true),
    //         (this.notification.text = "Feil med oppdatering av bruker"),
    //         (this.notification.type = "is-danger");
    //     });
    },
    async getUser() {
      this.$axios
        .get("api/myprofile")
        .then((response) => (this.user = response.data.data))
        .catch((e) => console.log("Feil med bruker " + e));
    },




  },
};
</script>