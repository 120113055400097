<template>
  <div style="padding:20px;">
    <!-- <h4 style="text-align:left;" class="p-pb-2">Ny bruker</h4> -->
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-6 p-mt-2">
        <h6>Fornavn</h6>
          <InputText v-model="user.firstname" id="firstname" type="text" />
      </div>
      <div class="p-field p-col-12 p-md-6 p-mt-2">
        <h6>Etternavn</h6>
          <InputText v-model="user.lastname" id="lastname" type="text" />
      </div>

      <div class="p-field p-col-12 p-md-6 p-mt-2">
        <h6>E-post</h6>
          <InputText v-model="user.email" id="email" type="text" />
      </div>

      <div class="p-field p-col-12 p-md-6 p-mt-2">
        <h6>Telefon</h6>
          <InputText v-model="user.phonenumber" id="phone" type="tel" />
          
      </div>
    </div>
    <div>
      <Button @click="newUser()" label="Lagre" icon="pi pi-check" class="p-button-primary" />
    </div>
  </div>
</template>

<script>
// import axios from '@/services/Auth/axios-auth'
import axios from "axios";
// import Users from "@/services/Users";
export default {
  methods: {
    newUser() {
      const data = this.user;
      axios
        .post("/api/newuser", data)
        .then((res) => console.log(res))
        .catch((error) => console.log(error));
      this.$emit("clicked", "newuser");
    },
    // async registrer() {
    //   try {
    //     const response = await Users.opprettbruker(this.user);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
  },

  data() {
    return {
      user: {},
    };
  },
};
</script>

<style>
</style>