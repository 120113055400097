<template>
  <b-navbar>
    <b-navbar-nav>
      <b-nav-item>
        <router-link style="margin-right:20px" class="navbara" to="/">Hjem</router-link>
      </b-nav-item>
      <b-nav-item>
        <router-link style="margin-right:20px" class="navbara" to="/projects">Prosjekt</router-link>
      </b-nav-item>
      <!-- <b-nav-item>
        <router-link style="margin-right:20px" class="navbara" to="/staticprojects">Static Projects Setup</router-link>
      </b-nav-item> -->
      <b-nav-item-dropdown class="navbara" text="Lager">
        <b-dropdown-item to="/inventory">Uttak / Lagerbeholdning</b-dropdown-item>
        <b-dropdown-item to="/restock">Legg inn på lager</b-dropdown-item>
        <b-dropdown-item to="/inventory/searchproduct">Enkelt</b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>

    <b-navbar-nav class="navbara ml-auto">
      <b-nav-item>
        <Checkbox v-model="darkMode" :binary="true" />
      </b-nav-item>
      <b-nav-item-dropdown class="navbara" text="Dashboard" right>
        <b-dropdown-item to="/dashboard/projects">Prosjektinstillinger</b-dropdown-item>
        <b-dropdown-item to="/dashboard/products">Lagerinstillinger</b-dropdown-item>
        <b-dropdown-item to="/dashboard/users">Instillinger</b-dropdown-item>
        <hr />
        <b-dropdown-item to="/userprofile">Min Profil</b-dropdown-item>
        <b-dropdown-item @click="logout()">Logg ut</b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
export default {
  data() {
    return {
      darkMode: false,
    };
  },
  computed: {
    firstname() {
      return this.$store.getters.user.firstname;
    },
  },

  mounted() {
    let bodyElement = document.body;
    bodyElement.classList.add("app-background");
    let htmlElement = document.documentElement;
    let theme = localStorage.getItem("theme");

    if (theme === "dark") {
      htmlElement.setAttribute("theme", "dark");
      this.darkMode = true;
    } else {
      htmlElement.setAttribute("theme", "light");
      this.darkMode = false;
    }
  },
  watch: {
    darkMode: function () {
      console.log("byttet til " + this.darkMode);
      // add/remove class to/from html tag
      let htmlElement = document.documentElement;

      if (this.darkMode) {
        localStorage.setItem("theme", "dark");
        htmlElement.setAttribute("theme", "dark");
      } else {
        localStorage.setItem("theme", "light");
        htmlElement.setAttribute("theme", "light");
      }
    },
  },

  methods: {
    logout() {
      this.$store.dispatch("logout");
      // this.$router.push('/login')
    },
  },
};
</script>

<style>
</style>