<template>
<div>
    <!-- asd<br>
    {{value}}<br><br>
    {{product}}<br><br><br>
    {{product.id}}<br>
    {{product.product_id}}<br>
    {{product.storage}}<br>
    {{product.Inventory_Product.name}}<br> -->
    <!-- <QrcodeVue :value="value" :size=300 level="H"/> -->
    <div class="box qrcode">
        <div class="p-grid">
            <div @click="downloadSVG()" class="p-col-4">
      <qrcode-vue class="code" id="qr" renderAs="svg" :value="value" :size="size" level="Q" />
              
 
            </div>
            <div class="p-col">
<span @click="draw()" class="txt">{{product.Inventory_Product.name}}</span>

            </div>
        </div>
    </div>
    </div>  
</template>

<script>



  import QrcodeVue from 'qrcode.vue'

export default {
    methods:{
         downloadSVG(evt) {

      // document.querySelector(".link-download").addEventListener("click", (evt) => {
      const svgContent = document.querySelector("#qr svg").outerHTML,
        blob = new Blob([svgContent], {
          type: "image/svg+xml"
        }),
        url = window.URL.createObjectURL(blob),
        link = evt.target;

      link.target = "_blank";
      link.download = "Illustration1.svg";
      link.href = url;
      // });

    },
        draw(){
var c = document.querySelector('#qr canvas')
// var c = document.getElementById("myCanvas");
var ctx = c.getContext("2d");
c.width+=100
ctx.font = "30px Arial";
ctx.strokeStyle="#FF4422"
ctx.fillStyle="#FF4422"
ctx.fillText("Hello World", 100, 100);
        }
    },
    mounted(){
        this.value=JSON.stringify(
        {locationId:this.product.id,productId:this.product.product_id})
    this.draw()
    },
    data(){
 return {
     value:'{id:1}',
        size: 200,

 }
    },
 props: {
    product: Object,
  },
  components:{
         QrcodeVue,
  }
}
</script>

<style>
.qrcode{
    width:700px;
}
.code{
    display:inline;
}
.txt{
    font-size:3rem;
}

</style>