<template>
  <div style="height:100%;">
    <DataTable
      :autoLayout="true"
      :filters="filters"
      removableSort
       @row-reorder="onRowReorder"
      :value="projectCategories"
      data-key="id"
    >
      <template #header>
        <div class="p-col-12">
          <div style="text-align:left;">
            <h4>Prosjektkategori </h4>              
          </div>
        </div>
        <div class="p-d-flex p-flex-column p-flex-md-row">
          <div class="p-mb-1 p-mr-2">
            <div style="text-align:left;">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global']" placeholder="Søk" />
              </span>
            </div>
          </div>
          <div class="p-mb-1 p-mr-2">
                       <b-field style="text-align: left; padding-right: 20px;">
              <b-radio-button v-model="status" :native-value="0" type="is-primary">
                <span>Aktive</span>
              </b-radio-button>

              <b-radio-button v-model="status" :native-value="1" type="is-primary">
                <span>Inaktive</span>
              </b-radio-button>


            </b-field>
          </div>
        </div>
      </template>
      <template #empty>Ingen treff</template>
    <Column :rowReorder="true" headerStyle="width: 3rem"/>
      <Column  sortable field="project_category" header="Navn"></Column>

      <Column bodyStyle="text-align:right;" header>
        <template #body="slotProps">
          <Button
            label="Endre"
            @click="editData(slotProps.data)"
            class="p-mr-2 p-button-danger p-button-rounded"
          />
        </template>
      </Column>
    </DataTable>
    <Button @click="saveOrder()" style="margin:10px;" v-if="editedOrder" label="Lagre rekkefølge" class="p-button-primary button-sm"/>

    <Dialog
      :visible.sync="dataDialog"
      :style="{width: '450px'}"
      header="Prosjektkategori"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label style="float:left;" for="name">Navn</label>
        <InputText
          id="name"
          v-model.trim="projectCategory.project_category"
          required="true"
          
          :class="{'p-invalid': submitted && !projectCategory.name}"
        />
        <small class="p-invalid" v-if="submitted && !projectCategory.name">Name is required.</small>
      </div>

      <template #footer>
        <div v-if="projectCategory.status==0">
          <Button
            style="float:left;"
            label="Arkiver"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="updateStatus(1)"
          />
          <Button
            @click="updateProjectCategory(projectCategory)"
            label="Lagre"
            icon="pi pi-check"
            class="p-button-primary marginl"
          />
        </div>
        <div v-if="projectCategory.status==1">
          <Button
            style="float:left;"
            label="Slett"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="displayConfirmation=true"
          />
          <Button
            @click="updateStatus(0)"
            label="Aktiver"
            icon="pi pi-check"
            class="p-button-primary marginl"
          />
        </div>
      </template>
    </Dialog>
    <Dialog
      header="Advarsel!"
      :visible.sync="displayConfirmation"
      :style="{width: '350px'}"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>Er du sikker på at du vil slette prosjektkategori?</span>
      </div>
      <template #footer>
        <Button
          label="Nei"
          icon="pi pi-times"
          @click="displayConfirmation=false"
          class="p-button-text"
        />
        <Button
          label="Ja"
          icon="pi pi-check"
          @click="updateStatus(2)"
          class="p-button-text"
          
        />
      </template>
    </Dialog>
  </div>
</template>


<script>
import ProjectService from "@/services/Project";
export default {
  data() {
    return {
      displayConfirmation: false,
      submitted: false,
      dataDialog: false,
      editedOrder:false,
      deleteCustomerDialog: false,
      projectService: null,
      filters: {},
      status: 0,
      projectCategories: [],
      projectCategory: {},
    };
  },

  watch: {
    status: function () {
      this.fetchData();
    },
  },

  methods: {
    saveOrder(){
      let categories = this.projectCategories
      let order =[]
      for(let i=0;i<categories.length;i++){
        order.push({
          id:categories[i].id,
          order:i
        })
      }
        console.log(order)
        let data = {order:order,table:'Project_Categories'}
        this.projectService
        .updateTableOrder(data)
        .then((data)=>this.fetchData());
    },
            onRowReorder(event) {
            console.log( event.value)
            this.projectCategories=event.value
            this.editedOrder=true
            // this.$toast.add({severity:'success', summary: 'Rows Reordered', life: 3000});
        },
    updateStatus(status) {
      const projectCategory = { id: this.projectCategory.id, status: status };
      this.projectService
        .updateProjectCategory(projectCategory)
        .then((data) => this.fetchData());
      this.dataDialog = false;
      this.displayConfirmation = false;
    },
    updateProjectCategory(projectCategory) {
      this.projectService
        .updateProjectCategory(projectCategory)
        .then((data) => this.fetchData());
    },
    hideDialog() {
      this.dataDialog = false;
      this.submitted = false;
    },
    fetchData() {
      this.projectService
        .getProjectCategories(this.status)
        .then((data) => (this.projectCategories = data));
    },

    editData(projectCategory) {
      this.projectCategory = { ...projectCategory };
      this.dataDialog = true;
    },
  },

  created() {
    this.projectService = new ProjectService();
  },

  mounted() {
    this.fetchData();
  },
};
</script>