<template>
  <div>
    <section>
      <!-- <b>Valgt Lager :</b>
      <span v-if="selectedStorage">{{ selectedStorage.name }}</span>
      <br /> -->
      {{ error }}{{ selectedInventory }}
      <Button label="Scan" @click="scan = !scan" />
      <Button label="Test" @click="findProductById(6)" />

      <div @if="scan">
        <qrcode-stream
          :height="100"
          :track="false"
          v-if="!error && scan"
          @decode="onDecode"
          @init="onInit"
        ></qrcode-stream>
      </div>

      <Button
        style="display: inline-block"
        v-if="activeStep > 0"
        label="Tilbake"
        @click="activeStep--"
      />
      <b-steps
        :has-navigation="false"
        mobile-mode="compact"
        v-model="activeStep"
      >
        <b-step-item :label="storageName">
          <div>
            <div class="p-grid">
              <div
                @click="
                  activeStep++;
                  selectedStorage = storages;
                "
                style="text-align: left"
                class="p-col-12 p-xl-3 p-lg-4 p-md-4 p-sm-12"
                v-for="storages in storages"
                :key="storages.id"
              >
                <Card>
                  <template slot="title">
                    {{ storages.name }}
                    <Button
                      @click.stop="togglefavorite(storage.id)"
                      style="float: right"
                      :icon="pinned(storages.id)"
                      class="p-button p-button-text p-button-rounded"
                    />
                  </template>
                  <template slot="subtitle">
                    {{ storages.address }}
                    <p>Kommentar: {{ storages.comment }}</p>
                  </template>
                  <template slot="content"> </template>
                  <template slot="footer"></template>
                </Card>
              </div>
            </div>

            <h1>{{ storages.name }}</h1>
          </div>
        </b-step-item>

        <b-step-item :label="operationName">
          <div class="p-grid">
            <div
              @click="
                selectedMode = 'out';
                activeStep++;
              "
              style="text-align: left"
              class="p-col-12 p-xl-6 p-lg-6 p-md-6 p-sm-12"
            >
              <Card>
                <template slot="title"> Ta ut varer </template>
              </Card>
            </div>
            <div
              @click="
                selectedMode = 'in';
                activeStep++;
              "
              style="text-align: left"
              class="p-col-12 p-xl-6 p-lg-6 p-md-6 p-sm-12"
            >
              <Card>
                <template slot="title"> Legg inn varer </template>
              </Card>
            </div>
          </div>
        </b-step-item>

        <b-step-item label="Finn vare">
          <div>
            <div v-if="!selectedProduct">
              <b>Valgt Produkt :</b> <br /><b>Artikkel nummer :</b> <br /><b
                >Elnummer :</b
              >
              <br /><b>Antall posisjoner : </b>
              <br />
              <Button disabled label="Neste" />
            </div>
            <div v-if="selectedProduct">
              <b>Valgt Produkt :</b> {{ selectedProduct.name }} <br /><b
                >Artikkel nummer :</b
              >
              {{ selectedProduct.article_number }} <br /><b>Elnummer :</b>
              {{ selectedProduct.elnumber }} <br /><b>Antall posisjoner : </b
              >{{ availableInventoriesStorages.length }}
              <br />
              <Button label="Neste" @click="checkInventoriesStep()" />
            </div>
            <h4>Artikkel / Direktesøk</h4>
            <InputText
              v-model="search"
              v-on:keyup="searchProduct(search)"
            /><br />
            <br />

            <Listbox
              v-model="selectedProduct"
              :filter="true"
              :options="products"
              optionLabel="name"
              listStyle="max-height:250px"
            />
          </div>
        </b-step-item>

        <b-step-item label="Velg posisjon">
          <Message
            :closable="false"
            severity="warn"
            v-if="
              availableInventoriesStorages.length == 0 && selectedMode == 'out'
            "
          >
            Ingen plassering tilgjengelig, legg til vare først / kontakt
            lagersjef.</Message
          >

          <div
            v-if="
              availableInventoriesStorages.length == 0 &&
              selectedMode == 'in' &&
              selectedProduct
            "
          >
            <Message :closable="false" severity="warn">
              Ingen plassering tilgjengelig, opprett plassering / kontakt
              lagersjef.</Message
            >

            <NewInventoryE
              @clicked="findInventoriesByProduct(selectedProduct.id)"
              name="e"
              :predefinedStorage="selectedStorage.id"
              :predefinedProduct="selectedProduct.id"
            />
          </div>

          <div v-if="availableInventoriesStorages.length > 0">
            <div class="p-grid">
              <div
                @click="
                  activeStep++;
                  selectedInventory = inventories;
                "
                style="text-align: left"
                class="p-col-12 p-xl-3 p-lg-4 p-md-4 p-sm-12"
                v-for="inventories in availableInventoriesStorages"
                :key="inventories.id"
              >
                <Card>
                  <template slot="title">
                    {{ selectedProduct.name }}
                    {{ inventories.name }}
                  </template>
                  <template slot="subtitle">
                    Antall : {{ inventories.quantity }}
                    <br />
                    <span v-if="inventories.Inventory_Location">
                      Lokasjon : {{ inventories.Inventory_Location.name }}
                    </span>
                    
                  </template>
                  <template slot="content">
                    <p>Kommentar : {{inventories.comment}}</p>
                  </template>
                  <template slot="footer"></template>
                </Card>
              </div>

              <div v-if="selectedMode == 'in'">
                <NewInventoryE
                  @clicked="findInventoriesByProduct(selectedProduct.id)"
                  name="e"
                  :predefinedStorage="selectedStorage.id"
                  :predefinedProduct="selectedProduct.id"
                />
              </div>
            </div>
          </div>
        </b-step-item>
        <b-step-item label="Utfør">
          <h1 v-if="selectedMode == 'out'">Ta ut vare!</h1>
          <h1 v-if="selectedMode == 'in'">Legg inn vare!</h1>
          <div v-if="selectedInventory" style="">
            <Card>
              <template slot="title">
                {{ selectedProduct.name }}
                {{ selectedInventory.name }}
              </template>
              <template slot="subtitle"> </template>
              <template slot="content">
                Antall : {{ selectedInventory.quantity }}
                <br />
                Artikkelnummer: {{ selectedProduct.article_number }}
                <br />
                Lager: {{ selectedStorage.name }}
                <br />
                <span v-if="selectedInventory.Inventory_Location">
                  Lokasjon : {{ selectedInventory.Inventory_Location.name }}
                </span>
                <br />
                <span v-if="selectedInventory.comment">
                  Kommentar : {{selectedInventory.comment}}
                  </span>
              </template>
              <template slot="footer"></template>
            </Card>

            <form @submit="createProject()">
              <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6 p-mt-2">
                  <span class="p-float-label">
                    <InputNumber v-model="number" mode="decimal" :min="0" />
                    <label for="projectname">Antall</label>
                  </span>
                </div>

                <div class="p-field p-col-12 p-md-12">
                  <!-- <Button @click="registrer()" class="p-button-secondary" label="Legg til" /> -->
                </div>
              </div>

              <div>
                <Button
                  v-if="selectedMode == 'out'"
                  style="float: left"
                  label="Ta ut"
                  icon="pi pi-check"
                  class="p-button-primary"
                  @click="transaction(selectedInventory, number * -1)"
                />

                <Button
                  v-if="selectedMode == 'in'"
                  @click="transaction(selectedInventory, number)"
                  label="Legg inn"
                  icon="pi pi-check"
                  class="p-button-primary marginl"
                />
              </div>
            </form>
          </div>
        </b-step-item>
      </b-steps>
    </section>

    <b-tabs v-if="2 + 2 == 5">
      <b-tab-item label="Uttak">
        <h4>Valgt Produkt</h4>
        <div v-if="selectedProduct">
          <b>Valgt Produkt :</b> {{ selectedProduct.name }}
          <br />
          <b>Valgt Lager :</b>
          <span v-if="selectedStorage">{{ selectedStorage.name }}</span>
          <br />
          <b>Valgt Posisjon :</b>
          <span v-if="selectedInventory">
            {{ selectedInventory.id }}- {{ selectedInventory.quantity }}-
            {{ selectedInventory.Inventory_Location.name }} - ID :
            {{ selectedInventory.id }}
            <Button
              v-if="!checkout.includes(selectedInventory.id)"
              class="p-button-primary marginl"
              @click="checkout.push(selectedInventory.id)"
              icon="pi pi-shopping-cart"
            />
            <Button
              v-if="checkout.includes(selectedInventory.id)"
              class="p-button-danger marginl"
              @click="
                checkout.splice(checkout.indexOf(selectedInventory.id), 1)
              "
              icon="pi pi-shopping-cart"
            />
          </span>
          <br />
        </div>

        <h4>Tilgjengelige Lagrer</h4>
        <div
          @click="selectedStorage = inventories"
          v-for="inventories in inventoriesStorages"
          :key="inventories.id"
        >
          <Button
            class="p-button-primary"
            :label="inventories.name"
          /><br /><br />
        </div>

        <h4>Tilgjengelige Posisjoner</h4>
        <div
          @click="selectedInventory = inventories"
          v-for="inventories in availableInventoriesStorages"
          :key="inventories.id"
        >
          <!-- {{inventories.Inventory_Location.name}} -->
          {{ inventories }}
        </div>

        {{ product.name }}<br /><br />
        {{ product }}<br /><br />
        {{ selectedProduct }}
        <div style="float;">
          <!-- <v-quagga :onDetected="logIt" :readerSize="readerSize" :readerTypes="['ean_reader']"></v-quagga> -->
        </div>
        <!-- <qrcode-stream @decode="onDecode"></qrcode-stream> -->
        <!-- <StreamBarcodeReader
    @decode="onDecode"
    @loaded="onLoaded"
        ></StreamBarcodeReader> -->
        <div>
          <h4>Artikkel / Direktesøk</h4>
          <InputText
            v-model="search"
            v-on:keyup="searchProduct(search)"
          /><br />
          <br />
        </div>
        <Listbox
          v-model="selectedProduct"
          :filter="true"
          :options="products"
          optionLabel="name"
          listStyle="max-height:250px"
        />

        <Message severity="warn" v-if="diffStorages()"
          >Advarsel: Du har valgt varer fra forskjellige lagre!</Message
        >

        <Button
          @click="$router.push('/inventory')"
          label="Tilbake"
          class="p-button-primary"
        />
        <Button
          @click="emptyCheckout()"
          class="marginl p-button-primary"
          label="Tøm kurv"
        />

        <br />
        <!-- {{checkout[0]}}
      <br />
      <br />
      {{checkout[0].Inventory_Storage}}-->
        <!-- {{checkout}}
      {{inventories[0]}}-->
        <DataTable :autofocus="false" :value="inventories">
          <Column header="Produkt" field="Inventory_Product.name"></Column>
          <Column header="Lager">
            <template #body="slotProps">
              <span v-if="slotProps.data.Inventory_Storage">{{
                slotProps.data.Inventory_Storage.name
              }}</span>

              <span v-if="slotProps.data.Inventory_Location"
                >/ {{ slotProps.data.Inventory_Location.name }}</span
              >
            </template>
          </Column>
          <Column header="På lager" field="quantity"></Column>
          <Column header="Antall" field="number">
            <template #body="slotProps">
              <InputNumber
                v-model="slotProps.data.number"
                mode="decimal"
                :min="0"
              />
              <!-- <InputNumber v-model="slotProps.data.number" mode="decimal" :min="0" /> -->
              <!-- <input
              :autofocus="false"
              class="p-inputnumber-input p-inputtext p-component p-filled"
              v-model="slotProps.data.number"
              min="0"
              type="number"
            />-->
              <!-- <input :autofocus="false" class="p-inputnumber-input p-inputtext p-component p-filled" v-model="slotProps.data.quantity" min=0 type="number"> -->

              <Button
                class="p-button-danger p-button-text marginl"
                @click="checkout.splice(checkout.indexOf(slotProps.data.id), 1)"
                icon="pi pi-times"
              />
            </template>
          </Column>
        </DataTable>
        <Button
          style="float: left; margin-top: 25px"
          label="Ut"
          icon="pi pi-check"
          class="p-button-primary"
          @click="save(-1)"
        />
        <Button
          style="float: left; margin-top: 25px"
          label="Inn"
          icon="pi pi-check"
          class="p-button-primary marginl"
          @click="save(1)"
        />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
require("howler");
// import { StreamBarcodeReader } from "vue-barcode-reader";
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from "vue-qrcode-reader";
import InventoryService from "@/services/Inventory/Inventory";
import NewInventory from "@/components/Dashboard/InventoryModule/NewInventory.vue";

export default {
  components: {
    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture,
    NewInventoryE: NewInventory,
    // StreamBarcodeReader,
  },
  watch: {
    checkout: function () {
      localStorage.setItem("checkout", JSON.stringify(this.checkout));
      this.fetchCart();
    },
    product: function () {
      console.log("Produkt ID " + this.product.id);
      this.findInventoriesByProduct(this.product.id);
    },
    selectedProduct: function () {
      console.log("Nyeste!! : " + this.selectedProduct);
      console.log(!this.selectedProduct == null);
      // if(this.selectedProduct=!null){
      console.log("Produkt ID " + this.selectedProduct.id);
      this.findInventoriesByProduct(this.selectedProduct.id);
      // };
    },
    availableInventories: function () {
      this.inventoriesStorages = [];
      // this.inventories={id:1}
      for (let i = 0; i < this.availableInventories.length; i++) {
        console.log(
          "Lager er : " + this.availableInventories[i].Inventory_Storage.name
        );
        let obj = {
          id: this.availableInventories[i].Inventory_Storage.id,
          name: this.availableInventories[i].Inventory_Storage.name,
        };

        if (!this.inventoriesStorages.some((e) => e.id == obj.id)) {
          this.inventoriesStorages.push(obj);
        }
      }
    },
  },
  computed: {
    storageName: function () {
      if (this.selectedStorage) {
        return this.selectedStorage.name;
      } else {
        return "Velg lager";
      }
    },
    operationName: function () {
      if (this.selectedMode == "out") {
        return "Ta ut";
      }
      if (this.selectedMode == "in") {
        return "Sett inn";
      }
      return "Velg operasjon";
    },
    availableInventoriesStorages: function () {
      // console.log(this.selectedStorage.id)
      if (!this.selectedStorage) {
        return this.availableInventories;
      } else {
        console.log("valgt lager " + this.selectedStorage.id);
        let available = this.availableInventories;
        let filtered = [];
        for (let i = 0; i < this.availableInventories.length; i++) {
          if (this.availableInventories[i].storage == this.selectedStorage.id) {
            filtered.push(this.availableInventories[i]);
          }
        }
        // if (filtered.length==1){console.log('Bare ett inventory')}
        return filtered;
      }
      // return this.availableInventories
    },
  },
  data() {
    return {
      readerSize: {
        width: 640,
        height: 300,
      },
      tmp: [],
      scan: false,
      checkout: [],
      detecteds: [],
      error: null,
      number: null,
      availableInventories: [],
      inventoriesStorages: [],
      inventories: [],
      inventoryService: null,
      search: null,
      debounceSearch: null,
      products: [],
      product: {},
      selectedProduct: null,
      selectedStorage: null,
      selectedInventory: null,
      activeStep: 0,
      storages: [],
      favorite: [],
      selectedMode: null,
    };
  },
  methods: {
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "Feil: Du må tillate kamera.";
        } else if (error.name === "NotFoundError") {
          this.error = "Feil: Enheten har ikke kamera";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "Feil: Er kamera allerede i bruk?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        }
      }
    },
    getLog(product_id, from_inventory_id) {
      this.inventoryService
        .getInventoryLog(product_id, from_inventory_id)
        .then((data) => console.log(data));
    },
    // >{{ availableInventoriesStorages.length }}
    //         <br />
    //         <Button label="Neste" @click="checkInventoriesStep()" />
    checkInventoriesStep() {
      if (
        this.availableInventoriesStorages.length == 1 &&
        this.selectedMode == "out"
      ) {
        this.selectedInventory = this.availableInventoriesStorages[0];
        this.activeStep += 2;
      } else {
        this.activeStep++;
      }
    },

    pinned(project) {
      if (this.favorite.includes(project)) {
        return "pi pi-star";
      }
      return "pi pi-star-o";
    },
    findInventoriesByProduct(id) {
      console.log("ID !!  " + id);
      if (!id) {
        return;
      } else {
        this.inventoryService
          .getInventoriesByProduct(id)
          .then((data) => (this.availableInventories = data))
          .then(this.fetchInventories);
      }
    },
    logIt(data) {
      //   if (this.debounceSearch==this.data.codeResult.code){
      //       return
      //   }
      if (!this.tmp.includes(data.codeResult.code)) {
        this.tmp.push(data.codeResult.code);
      }
      console.log("detected", data.codeResult.code);
      this.playSound();
      this.searchProduct(data.codeResult.code);
    },
    playSound() {
      console.log("Play sound");
      // const file_path="@/assets/sound.mp3"
      const file_path =
        "https://gateway.pinata.cloud/ipfs/Qmbzg4XXtaZwSNkn5WUVdWwQ6kU2GaCJnL9Ehqi4hakq5V";
      var sound = new Howl({
        src: ["/soft_notification.mp3"],
        volume: 0.8,
      });
      sound.play();
      console.log(sound.state());
    },
    onError(result) {
      //   console.log(result);
    },
    onLoaded(result) {
      //   console.log(result);
    },
    onDecode(decodedString) {
      let parsed = JSON.parse(decodedString);
      parsed = parsed.locationId;
      // this.selectedInventory=parsed
findProductById(parsed)
      // if (!this.checkout.includes(decodedString)) {
      //   this.checkout.push(decodedString);
      //   this.playSound();
      // }
    },
    findProductById(id){
      this.inventoryService
        .getInventory(id)
        .then((data) => (this.selectedInventory = data));
        // .then((data) => (console.log(data)));
    },
    diffStorages() {
      let antall = [];
      for (let i = 0; i < this.inventories.length; i++) {
        if (!antall.includes(this.inventories[i].Inventory_Storage.id)) {
          antall.push(this.inventories[i].Inventory_Storage.id);
        }
      }
      //   console.log(antall);
      return antall.length > 1;
    },
    save(num) {
      for (let i = 0; i < this.inventories.length; i++) {
        this.transaction(this.inventories[i], this.inventories[i].number * num);
      }
      this.emptyCheckout();
      this.$router.push("/inventory");
    },
    fetchCart() {
      this.inventoryService
        .getCart(this.checkout)
        .then((data) => (this.inventories = data));
    },
    emptyCheckout() {
      localStorage.removeItem("checkout");
      this.checkout = [];
    },
    transaction(selectedInventory, number) {
      const object = {
        id: selectedInventory.id,
        number: number,
      };
      console.log(selectedInventory);
      this.inventoryService
        .updateInventoryQuantity(object)
        //   .then((data) => console.log(data[0]))
        //   .then(this.fetchInventories);
        // .then((data)=>console.log(data))
        .then((data) => (this.selectedInventory.quantity = data[0].quantity));
      this.number = 0;
      this.activeStep = 2;
      // this.inventoryService
      //   .getInventory(selectedProduct.id)
      //   .then((data) => (console.log(data)))
      //   .then(this.fetchInventories);
    },
    searchProduct(search) {
      if (search.length < 3) {
        return;
      }
      if (this.debounceSearch == search) {
        return;
      }

      console.log(search);
      this.debounceSearch = search;
      search = { search: search };
      this.inventoryService
        .searchProducts(search)
        .then((data) => (this.selectedProduct = data[0]));
      console.log("Søk!");
    },
    fetchProducts() {
      this.inventoryService
        .getProducts(0)
        .then((data) => (this.products = data));
    },
    getStorages() {
      this.inventoryService
        .getStorages(0)
        .then((data) => (this.storages = data));
    },
  },
  created() {
    this.inventoryService = new InventoryService();
  },
  mounted() {
    window.onpopstate = function (event) {
      this.activeStep--;
      //  alert("location: " + document.location + ", state: " + JSON.stringify(event.state));
    };
    // this.$toast.add({severity:'success', summary: 'Success Message', detail:'Order submitted', life: 3000});
    this.fetchProducts();
    if (localStorage.getItem("checkout")) {
      this.checkout = JSON.parse(localStorage.getItem("checkout"));
    }
    this.fetchCart();
    this.getStorages();
  },
};
</script>

<style>
</style>