<template>
<div>
    <Projects/>
</div>
</template>

<script>
import ProjectService from "@/services/Project";
import Projects from '@/components/Overview/Projects'
export default {
    components:{
        Projects
    },
  data() {
    return {
      projects: [],
    
    };
  },

  created() {
    this.projectService = new ProjectService();
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    onSortChange(event) {
      const value = event.value.value;
      const sortValue = event.value;

      if (value.indexOf("!") === 0) {
        this.sortOrder = -1;
        this.sortField = value.substring(1, value.length);
        this.sortKey = sortValue;
      } else {
        this.sortOrder = 1;
        this.sortField = value;
        this.sortKey = sortValue;
      }
    },
    fetchData() {
      this.projectService.getProjects().then((data) => (this.projects = data));
    },
  },
};
</script>

<style>
</style>