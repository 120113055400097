<template>
  <div>
    <ProjectSettingNav />
    <div>
      <Button class="p-mr-2 p-button-primary marginl" @click="templateModal=true">Ny template</Button>
    </div>
    <Dialog header="Ny template"
      modal
      :closable="true"
      :showHeader="true"
      :visible.sync="templateModal"
    >
      <NewTemplate @clicked="key++;templateModal=false" />
    </Dialog>
    <TemplateTable :key="key" />
  </div>
</template>

<script>
import ProjectSettingNav from "@/components/UI/ProjectSettingNav.vue";
import NewTemplate from "@/components/Dashboard/ProjectModule/NewTemplate.vue";
import TemplateTable from "@/components/Dashboard/ProjectModule/TemplateTable.vue";
export default {
  data() {
    return {
      key: 0,
      templateModal: false,
    };
  },
  components: {
    ProjectSettingNav,
    NewTemplate,
    TemplateTable,
  },
};
</script>
