<template>
  <section>
      <!-- {{object}}, {{project}}, {{telenorMateriel}} -->
    <DataTable
      rowHover
      @row-click="selectMaterial"
      :autoLayout="true"
      :filters="filters"
      removableSort
      :value="telenorMateriel"
      data-key="id"
    >
      <template #header>
        <div class="p-col-12">
          <div style="text-align: left">
            <h4>Tilgjengelig materiell</h4>
          </div>
        </div>
        <div class="p-d-flex p-flex-column p-flex-md-row">
          <div class="p-mb-1 p-mr-2">
            <div style="text-align: left">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global']" placeholder="Søk" />
              </span>
            </div>
          </div>
        </div>
      </template>
      <template #empty>Ingen treff</template>

      <Column
        sortable
        field="Telenor_Materiel.materiellnavn"
        header="Navn"
      ></Column>
      <Column
        sortable
        field="Telenor_Materiel.kategori"
        header="Kategori"
      ></Column>
      <Column sortable field="quantity" header="Tilgjengelig"></Column>
    </DataTable>

    <Dialog modal :closable="true" :showHeader="true" :visible.sync="showModal">
      <div v-if="selectedMaterial" style="padding: 20px">
        <h4 style="text-align: left" class="p-pb-2">
          {{ selectedMaterial.Telenor_Materiel.materiellnavn }}
        </h4>
        <h5 style="text-align: left" class="p-pb-2">
          Artikkelnummer: {{ selectedMaterial.Telenor_Materiel.materiellkode }}
        </h5>
        <h5 style="text-align: left" class="p-pb-2">
          Lager: Telenor/Prosjekt lager
        </h5>
        <hr />
        <h5 style="text-align: left" class="p-pb-2">
          Tilgjengelig: {{ selectedMaterial.quantity }}
        </h5>

        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-6 p-mt-2">
            <span class="p-float-label">
              <InputNumber v-model="number" mode="decimal" :min="0" />
              <label for="projectname">Antall</label>
            </span>
          </div>
        </div>
        <div class="p-fluid p-formgrid p-grid">
          <div class="p-field p-col-12 p-md-6 p-mt-2">
            <span class="p-float-label">
              <InputText v-model="selectedMaterial.comment" />
              <label>Kommentar</label>
            </span>
          </div>
        </div>

        <div>
          <Button
            style="float: left"
            label="Ta ut"
            icon="pi pi-times"
            class="p-button-danger"
            @click="transaction(selectedMaterial, number * -1)"
          />

          <Button
            @click="transaction(selectedMaterial, number)"
            label="Sett inn"
            icon="pi pi-check"
            class="p-button-primary marginl"
          />
        </div>
      </div>
    </Dialog>
  </section>
</template>



    <script>
import ProjectService from "@/services/Project";
import TemplateService from "@/services/Template";

export default {
  data() {
    return {
      number: null,
      showModal: false,
      selectedMaterial: null,
      projectService: null,
      templateService: null,
    //   object: {},
      filters: {},
      templateInfo: [],
      telenorMateriel: null,
    };
  },
props:['object', 'project'],
  created() {
    this.templateService = new TemplateService();
    this.projectService = new ProjectService();
  },

  methods: {
    transaction(selectedMaterial, number) {
      const object = {
        materialid: selectedMaterial.id,
        number: number,
        comment: selectedMaterial.comment,
        object: this.object,
        template: this.$route.params.template,
        project: this.project,
      };
      console.log(selectedMaterial);
      this.projectService
        .telenorMaterialTransaction(object)
        //   .then((data) => console.log(data[0]))
        //   .then(this.fetchInventories);
        // .then((data)=>console.log(data))
        .then((data) => (this.selectedMaterial.quantity = data[0].quantity));
      this.number = 0;
    this.fetchMaterials()
    this.showModal=false;
      // this.inventoryService
      //   .getInventory(selectedProduct.id)
      //   .then((data) => (console.log(data)))
      //   .then(this.fetchInventories);
    },
    selectMaterial(event) {
      console.log(event.data);
      this.selectedMaterial = event.data;
      this.showModal = true;
    },
    fetchMaterials() {
      this.projectService
        .fetchMaterials(this.project)
        // .then((data) => (console.log( data)));
        .then((data) => (this.telenorMateriel = data));
    console.log(this.telenorMateriel)
    },
  },
  mounted() {
      this.fetchMaterials()
  },
};
</script>

