<template>
  <section>
    <div
      @click="updateObject()"
      v-if="Object.keys(editobject).length != 0 && tab == 0"
      class="popup is-secondary bg-color"
      id="outer"
    >
      <!-- <p>Vil du lagre endringene?</p> -->
      <div class="inner">
        <!-- <b-button @click="updateObject()" style="border:0; color:white; background-color:#008f95" class=" lagreknapp">Lagre</b-button> -->
        <span>Lagre</span>
      </div>
      <div class="inner">
        <!-- <span @click="forkast()" class="liten">Forkast</span> -->
      </div>
    </div>

    <!-- {{object[0][templateInfo[0].datafield]}}<br><br>
      {{templateInfo[0]}} -->
    <div style="margin-top: 10px; margin-left: 15px">
      <h4>
        {{ templateInfo[0].Datafields[0].name }} :
        {{ object[0][templateInfo[0].datafield] }}
      </h4>
    </div>
    <b-tabs v-model="tab">
      <b-tab-item label="Objekt">
        <Button
          @click="$router.push('/project/' + object[0].project)"
          class="p-button-danger"
          label="Tilbake"
        />
        <Button
          style="float: right"
          v-tooltip="'Endre statiske'"
          v-if="editStatic"
          @click="editStatic = !editStatic"
          class="p-button-primary"
          icon="pi pi-pencil"
        />
        <Button
          @click="displayConfirmation = true"
          style="float: right; margin-right: 10px"
          class="p-button-danger"
          icon="pi pi-trash"
        />
        <div class="box" v-for="group in groups" :key="group">
          <!-- {{fieldsBox(group)}} -->
          <div v-if="group == 0">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-col-12 p-md-3">
                <h6 style="display: inline">Prioritert</h6>
                <span
                  class="logg"
                  v-if="templateInfo[templateInfo.length - 2].author"
                  >{{ templateInfo[templateInfo.length - 2].author + " - " }}
                  {{
                    templateInfo[templateInfo.length - 2].dato
                      | moment("DD. MMM YYYY, kk:mm")
                  }}</span
                >
                <br />
                <b-switch
                  style="padding-top: 5px"
                  :true-value="1"
                  :false-value="0"
                  v-model="object[0].priority"
                  v-on:input="editobject.priority = object[0].priority"
                ></b-switch>
              </div>

              <div class="p-col-12 p-md-3">
                <h6 style="display: inline">Ferdigstilt</h6>
                <span
                  class="logg"
                  v-if="templateInfo[templateInfo.length - 1].author"
                  >{{ templateInfo[templateInfo.length - 1].author + " - "
                  }}{{
                    templateInfo[templateInfo.length - 1].dato
                      | moment("DD. MMM YYYY, kk:mm")
                  }}</span
                >
                <br />
                <b-switch
                  style="padding-top: 5px"
                  :true-value="1"
                  :false-value="0"
                  v-model="object[0].completed"
                  v-on:input="editobject.completed = object[0].completed"
                ></b-switch>
              </div>
            </div>
          </div>

          <div v-for="items in fieldsBox(group)" :key="items.id">
            <div v-if="[1, 2, 5, 12].includes(items.Datafields[0].type)">
              <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12">
                  <h6 style="display: inline">
                    {{ items.Datafields[0].name }}
                  </h6>
                  <span class="logg" v-if="items.author"
                    >{{ items.author + " - " }}
                    {{ items.dato | moment("DD. MMM YYYY, kk:mm") }}</span
                  >
                  <br />

                  <InputText
                    v-if="[1, 5].includes(items.Datafields[0].type)"
                    :disabled="editStatic"
                    type="text"
                    v-on:input="
                      editobject[items.datafield] = object[0][items.datafield]
                    "
                    v-model="object[0][items.datafield]"
                  />

                  <InputText
                    v-if="items.Datafields[0].type == 2"
                    type="number"
                    :disabled="editStatic"
                    v-on:input="
                      editobject[items.datafield] = object[0][items.datafield]
                    "
                    v-model="object[0][items.datafield]"
                  />

                  <Textarea
                    autoResize
                    v-on:input="
                      editobject[items.datafield] = object[0][items.datafield]
                    "
                    v-model="object[0][items.datafield]"
                    v-if="items.Datafields[0].type == 12"
                  />
                </div>
              </div>
            </div>

            <div v-if="[3, 9].includes(items.Datafields[0].type)">
              <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12">
                  <h6 style="display: inline">
                    {{ items.Datafields[0].name }}
                  </h6>
                  <span class="logg" v-if="items.author"
                    >{{ items.author + " - "
                    }}{{ items.dato | moment("DD. MMM YYYY, kk:mm") }}</span
                  >
                </div>
                <div class="p-field">
                  <b-field v-if="items.Datafields[0].type == 3">
                    <b-radio-button
                      v-model="object[0][items.datafield]"
                      :native-value="0"
                      v-on:input="
                        editobject[items.datafield] = object[0][items.datafield]
                      "
                      type="is-danger"
                    >
                      <i class="pi pi-times" style="margin-right: 5px"></i>
                      <span>Nei</span>
                    </b-radio-button>

                    <b-radio-button
                      v-model="object[0][items.datafield]"
                      :native-value="1"
                      v-on:input="
                        editobject[items.datafield] = object[0][items.datafield]
                      "
                      type="is-success"
                    >
                      <i class="pi pi-check" style="margin-right: 5px"></i>
                      <span>Ja</span>
                    </b-radio-button>

                    <b-radio-button
                      v-model="object[0][items.datafield]"
                      :native-value="2"
                      v-on:input="
                        editobject[items.datafield] = object[0][items.datafield]
                      "
                      >Utgår</b-radio-button
                    >
                  </b-field>

                  <b-field v-if="items.Datafields[0].type == 9">
                    <b-radio-button
                      v-model="object[0][items.datafield]"
                      :native-value="0"
                      v-on:input="
                        editobject[items.datafield] = object[0][items.datafield]
                      "
                      type="is-danger"
                    >
                      <i class="pi pi-times" style="margin-right: 5px"></i>
                      <span>Nei</span>
                    </b-radio-button>

                    <b-radio-button
                      v-model="object[0][items.datafield]"
                      :native-value="1"
                      v-on:input="
                        editobject[items.datafield] = object[0][items.datafield]
                      "
                      type="is-success"
                    >
                      <i class="pi pi-check" style="margin-right: 5px"></i>
                      <span>Ja</span>
                    </b-radio-button>
                  </b-field>
                </div>
              </div>
            </div>

            <div v-if="[14].includes(items.Datafields[0].type)">
              <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12">
                  <h6 style="display: inline">
                    {{ items.Datafields[0].name }}
                  </h6>
                  <span class="logg" v-if="items.author"
                    >{{ items.author + " - "
                    }}{{ items.dato | moment("DD. MMM YYYY, kk:mm") }}</span
                  >
                <div v-if="!editStatic">

                  <InputText
                    :disabled="editStatic"
                    type="text"
                    v-on:input="
                      editobject[items.datafield] = object[0][items.datafield]
                    "
                    v-model="object[0][items.datafield]"
                  />
                </div>
                <div v-else>
                  <a target="_blank" :href="object[0][items.datafield]">
                  {{object[0][items.datafield]}}
                  </a>
                </div>
                </div>
              </div>
            </div>

            <div v-if="[4, 8].includes(items.Datafields[0].type)">
              <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12">
                  <h6 style="display: inline">
                    {{ items.Datafields[0].name }}
                  </h6>
                  <span class="logg" v-if="items.author"
                    >{{ items.author + " - "
                    }}{{ items.dato | moment("DD. MMM YYYY, kk:mm") }}</span
                  >
                  <InputText
                    :disabled="editStatic"
                    type="text"
                    v-on:input="
                      editobject[items.datafield] = object[0][items.datafield]
                    "
                    v-model="object[0][items.datafield]"
                  />
                </div>
              </div>
            </div>

            <div v-if="[6, 7].includes(items.Datafields[0].type)">
              <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12">
                  <h6 style="display: inline">
                    {{ items.Datafields[0].name }}
                  </h6>
                  <span class="logg" v-if="items.author"
                    >{{ items.author + " - "
                    }}{{ items.dato | moment("DD. MMM YYYY, kk:mm") }}</span
                  >
                  <InputText
                    v-if="items.Datafields[0].type == 6"
                    type="text"
                    v-on:input="
                      editobject[items.datafield] = object[0][items.datafield]
                    "
                    v-model="object[0][items.datafield]"
                  />
                  <InputText
                    v-if="items.Datafields[0].type == 7"
                    type="number"
                    v-on:input="
                      editobject[items.datafield] = object[0][items.datafield]
                    "
                    v-model="object[0][items.datafield]"
                  />
                </div>
              </div>
            </div>

            <div v-if="[10].includes(items.Datafields[0].type)">
              <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12">
                  <h6 style="display: inline">
                    {{ items.Datafields[0].name }}
                  </h6>
                  <span class="logg" v-if="items.author"
                    >{{ items.author + " - "
                    }}{{ items.dato | moment("DD. MMM YYYY, kk:mm") }}</span
                  >

                  <Dropdown
                    v-on:input="
                      editobject[items.datafield] = object[0][items.datafield]
                    "
                    v-model="object[0][items.datafield]"
                    :options="
                      ['-'].concat(items.Datafields[0].extra.split(','))
                    "
                    optionLabel="name"
                    :placeholder="items.Datafields[0].extra.split(',')[0]"
                  >
                    <template #value="slotProps">
                      <div v-if="slotProps.value">
                        <div
                          v-if="slotProps.value == ' '"
                          style="height: 1rem"
                        ></div>
                        <div
                          v-if="slotProps.value == ''"
                          style="height: 1rem"
                        ></div>
                        <div>{{ slotProps.value }}</div>
                      </div>
                      <span style="height: 1rem" v-else> -</span>
                    </template>

                    <template #option="slotProps">
                      <div
                        v-if="slotProps.option == ' '"
                        style="height: 1em"
                      ></div>
                      <div
                        v-if="slotProps.option == ''"
                        style="height: 1em"
                      ></div>
                      <div>{{ slotProps.option }}</div>
                    </template>
                  </Dropdown>
                </div>
              </div>
            </div>

            <div v-if="[11].includes(items.Datafields[0].type)">
              <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12">
                  <h6 style="display: inline">
                    {{ items.Datafields[0].name }} Fra
                  </h6>
                  <span class="logg" v-if="items.author"
                    >{{ items.author + " - "
                    }}{{ items.dato | moment("DD. MMM YYYY, kk:mm") }}</span
                  >
                  <InputText
                    type="number"
                    v-bind:value="lengthSplit(object[0][items.datafield], 0)"
                    v-on:input="
                      updateSplit([items.datafield], 0, $event);
                      editobject[items.datafield] = object[0][items.datafield];
                      pushType(items.Datafields[0]);
                    "
                  />
                  <h6 style="display: inline">
                    {{ items.Datafields[0].name }} Til
                  </h6>
                  <InputText
                    type="number"
                    v-bind:value="lengthSplit(object[0][items.datafield], 1)"
                    v-on:input="
                      updateSplit([items.datafield], 1, $event);
                      editobject[items.datafield] = object[0][items.datafield];
                      pushType(items.Datafields[0]);
                    "
                  />
                  <h6 style="display: inline">
                    {{ items.Datafields[0].name }} Sum
                  </h6>
                  <InputText
                    type="number"
                    disabled
                    v-bind:value="lengthSplit(object[0][items.datafield], 2)"
                  />
                </div>
              </div>
            </div>

            <div v-if="[13].includes(items.Datafields[0].type)">
              <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12">
                  <h6 style="display: inline">
                    {{ items.Datafields[0].name }}
                  </h6>
                  <span class="logg" v-if="items.author"
                    >{{ items.author + " - "
                    }}{{ items.dato | moment("DD. MMM YYYY, kk:mm") }}</span
                  >

                  <Dropdown
                    v-on:input="
                      editobject[items.datafield] = object[0][items.datafield];
                      pushType(items.Datafields[0]);
                    "
                    v-model="object[0][items.datafield]"
                    :options="
                      projectMaterialsCategory(items.Datafields[0].extra)
                    "
                    optionValue="id"
                    optionLabel="Project_Material.materialname"
                  >
                    <!-- <template #value="slotProps">
                      <div v-if="slotProps.value">
                        <div
                          v-if="slotProps.value == ' '"
                          style="height: 1rem"
                        ></div>
                        <div
                          v-if="slotProps.value == ''"
                          style="height: 1rem"
                        ></div>
                        <div>{{ slotProps.value }}</div>
                      </div>
                      <span style="height: 1rem" v-else> -</span>
                    </template>

                    <template #option="slotProps">
                      <div
                        v-if="slotProps.option == ' '"
                        style="height: 1em"
                      ></div>
                      <div
                        v-if="slotProps.option == ''"
                        style="height: 1em"
                      ></div>
                      <div>asd{{ slotProps.option }}</div>
                    </template> -->
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-tab-item>

      <b-tab-item label="Materiell">
        <Button
          @click="$router.push('/project/' + object[0].project)"
          class="p-button-danger"
          label="Tilbake"
        />
        <!-- {{$route.params}}
        {{object}} -->
        <!-- <br>
        <br>
        <br>
        {{selectedMaterial}} -->

        <!-- <div class="p-col-12">
          <div style="text-align:left;">
            <h4>Brukt materiell</h4>
          </div>
        </div>


                <div class="p-col-12">
          <div style="text-align:left;">
            <h4>Tilgjengelig materiell</h4>
          </div>
        </div> -->

        <ProjectMaterialsTable
          :key="key"
          type="transaction"
          emit="true"
          @emit="onEmit"
          :project="object[0].project"
          :object="$route.params.id"
        />
      </b-tab-item>

      <b-tab-item :label="`Filer (${files.length})`">
        <!-- {{files}} -->
        <div v-if="tab == 2">
          <FileUpload
            :multiple="false"
            name="demo[]"
            chooseLabel="Velg fil"
            uploadLabel="Last opp"
            cancelLabel="Avbryt"
            customUpload
            :fileLimit="1"
            @uploader="myUploader"
            :maxFileSize="15000000"
          >
            <template #empty>
              <p>Dra en fil hit.</p>
            </template>
          </FileUpload>
          <br />
            <!-- <Button style="margin-bottom:1em;" @click="downloadMultiple()">Last ned markerte</Button> -->
          <div class="p-grid">
            <div class="p-col-4" v-for="files in files" :key="files.id">
              <Panel>
                <template #header>
                  <span
                    ><b style="margin-right: 5px">{{
                      files.name || "Uten navn"
                    }}</b>
                    <Checkbox v-model="files.checked" :binary="true" />
                  </span>

                  <Button
                    class="p-button-danger p-button-sm"
                    @click="deleteDocument(files.id)"
                    v-if="$store.getters.user.userid == files.user"
                    >Slett dokument</Button
                  >
                </template>
                <!-- <img v-if="!files.thumbnail" :src="files.url" height="400"> -->
                <div v-if="fileType(files.name) == 'image'">
                  <div v-if="!files.s3_thumbnail">
                    <a target="new" :href="files.url">
                      <img style="height: 300px" :src="files.url" />
                    </a>
                  </div>

                  <div v-if="files.s3_thumbnail">
                    <a target="new" :href="files.url">
                      <img
                        style="width: 300px; height: 300px"
                        :src="files.urlthumbnail"
                      />
                    </a>
                  </div>
                </div>

                <div v-if="fileType(files.name) == 'pdf'">
                  <div v-if="!files.s3_thumbnail">
                    <a target="new" :href="files.url">
                      <!-- <img style="height: 300px" :src="files.url" /> -->
                      <i style="fontsize: 5rem" class="pi pi-file-pdf"></i>
                    </a>
                  </div>
                </div>
                <div v-if="fileType(files.name) == 'xlsx'">
                  <div v-if="!files.s3_thumbnail">
                    <a target="new" :href="files.url">
                      <!-- <img style="height: 300px" :src="files.url" /> -->
                      <i style="fontsize: 5rem" class="pi pi-file-excel"></i>
                    </a>
                  </div>
                </div>
              </Panel>
              <br />
              <br />
            </div>
          </div>
        </div>
      </b-tab-item>
      <b-tab-item :label="`Kommentarer (${comments.length})`">
        <div style="margin-bottom: 30px; margin-left: 10px" v-if="comments[0]">
          <div v-for="items in comments" :key="items.id">
            <!-- Hoved post -->
            <div class="media-content" v-if="!items.parent">
              <div class="content commentboks">
                <p>
                  <label style="font-size: 1rem; font-weight: 600">{{
                    items.author
                  }}</label>
                  <small class="has-text-secondary is-italic log">{{
                    items.createdAt
                  }}</small>
                  <!-- <small>  <span></span></small> -->
                  <span
                    class="remove"
                    style="font-size: 0.9rem; padding-left: 10px"
                    @click="
                      parent = items.id;
                      newComment = true;
                    "
                    >Svar</span
                  >
                  <br />
                  {{ items.content }}
                </p>
              </div>
              <!-- Replies under / LEVEL 1 -->
              <div v-for="itemslvl1 in items.under" :key="itemslvl1.id">
                <div
                  class="commentboks"
                  :style="`margin-left:${30}px;margin-bottom:15px;margin-top:-15px;`"
                >
                  <p>
                    <label style="font-size: 1rem; font-weight: 600">{{
                      itemslvl1.author
                    }}</label>
                    <small class="has-text-secondary is-italic log">{{
                      itemslvl1.createdAt
                    }}</small>
                    <br />
                    {{ itemslvl1.content }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Button
          label="Ny kommentar"
          class="p-button-primary marginl"
          @click="
            parent = '';
            newComment = true;
          "
        />
      </b-tab-item>
      <b-tab-item :label="`Logg (${log.length})`">
        <div v-for="log in log" :key="log.id">
          <div class="box">
            {{ returnFieldName(log.field) }}
            <div style="font-style: italic; display: inline">
              <span style="font-size: 0.9em">{{ log.author }} </span>
              <span style="font-size: 0.85em">{{
                log.createdAt | moment("DD. MMM YYYY, kk:mm")
              }}</span>
            </div>

            <!-- Implementer alle mulige forskjellige felter... >.>... Custom komponent -->

            <!-- Alle tekst / tall -->
            <div
              v-if="
                [1, 2, 4, 5, 6, 7, 8, 10, 12].includes(
                  returnFieldName(log.field, 'type')
                )
              "
            >
              {{ log.action }}
            </div>

            <!-- Prioritert?? -->

            <div v-if="log.field == 'priority' || log.field == 'completed'">
              <span v-if="log.action == 0">Nei</span>
              <span v-else>Ja</span>
            </div>

            <!-- Lengde type -->
            <div v-if="[11].includes(returnFieldName(log.field, 'type'))">
              Lengde fra : {{ log.action.split(",")[0] }} <br />
              Lengde til : {{ log.action.split(",")[1] }} <br />
              Lengde sum : {{ log.action.split(",")[2] }}<br />
            </div>

            <!-- Material type -->
            <div v-if="returnFieldName(log.field, 'type') == 13">
              {{ checkMaterialType(log.action) }}
            </div>

            <!-- Enkel sjekk tekst -->
            <div v-if="[9, 3].includes(returnFieldName(log.field, 'type'))">
              <span v-if="log.action == 2"> Utgår </span>
              <span v-if="log.action == 1"> Ja </span>
              <span v-if="log.action == 0"> Nei </span>
            </div>
          </div>
        </div>
      </b-tab-item>
    </b-tabs>

    <Dialog
      header="Advarsel!"
      :visible.sync="displayConfirmation"
      :style="{ width: '350px' }"
      :modal="true"
      :closable="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>Du har ulagrede endringer. Vil du fortsatt gå ut?</span>
      </div>
      <template #footer>
        <Button
          label="Nei"
          icon="pi pi-times"
          @click="displayConfirmation = false"
          class="p-button-text"
        />
        <Button
          label="Ja"
          icon="pi pi-check"
          @click="$router.push('/project/' + object[0].project)"
          class="p-button-text"
        />
      </template>
    </Dialog>
    <Dialog
      header="Ny kommentar"
      modal
      :closable="true"
      :showHeader="true"
      :visible.sync="newComment"
    >
      <div class="container">
        <form method="post" @submit.prevent="createComment(parent)">
          <div class="field">
            <div class="control">
              <Textarea
                v-model="comment"
                :autoResize="true"
                rows="5"
                cols="35"
                required
              />
            </div>
          </div>
          <div class="control">
            <button type="submit" class="button is-primary is-fullwidth">
              Send
            </button>
          </div>
        </form>
      </div>
    </Dialog>
    <Dialog
      header="Advarsel!"
      :visible.sync="displayConfirmation"
      :style="{ width: '350px' }"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>Er du sikker på at du vil slette objektet?</span>
      </div>
      <template #footer>
        <Button
          label="Nei"
          icon="pi pi-times"
          @click="displayConfirmation = false"
          class="p-button-text"
        />
        <Button
          label="Ja"
          icon="pi pi-check"
          @click="updateStatus(1)"
          class="p-button-text"
        />
      </template>
    </Dialog>
    <loading-overlay :active="isLoading" />
  </section>
</template>

<script>
import ProjectService from "@/services/Project";
import TemplateService from "@/services/Template";
import axios from "axios";
import TelenorMaterialTable from "@/components/Project/TelenorMaterialTable.vue";
import ProjectMaterialsTable from "@/components/Project/ProjectMaterialsTable.vue";
// import moment from '@moment'

export default {
  components: {
    TelenorMaterialTable,
    ProjectMaterialsTable,
  },
  data() {
    return {
      materialObject: {},
      isLoading: false,
      tab: 0,
      tmpLength: {},
      number: null,
      showModal: false,
      selectedMaterial: null,
      editObject: null,
      projectService: null,
      templateService: null,
      object: {},
      projectMaterials: null,
      datafields: [],
      comments: null,
      photos: null,
      filters: {},
      key: 0,
      templateInfo: [],
      groups: [],
      editobject: {},
      displayConfirmation: false,
      editStatic: true,
      log: [],
      copy: {},
      telenorMateriel: null,
      types: [],
      comment: "",
      comments: [],
      newComment: false,
      isOpen: false,
      files: [],
    };
  },
  watch: {
    tab: function (val) {
      if (val != 0) {
        // console.log(val)
        // console.log(this.editobject)
        if (Object.keys(this.editobject).length != 0) {
          // this.editobject={}
        }
      }
      if (val == 2) {
        this.fetchFiles();
      }
      if (val == 1) {
        this.fetchMaterials();
      }
    },
  },
  created() {
    this.templateService = new TemplateService();
    this.projectService = new ProjectService();
  },
  beforeRouteLeave(to, from, next) {
    if (!Object.keys(this.editobject).length == 0) {
      const answer = window.confirm(
        "Vil du virkelig forlate siden? Du har ulagrede endringer!"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },

  methods: {
    checkMaterialType(id) {
      let matIndex = this.projectMaterials.findIndex((x) => x.id == id);

      if (matIndex > -1) {
        //   // return 'Har mat! ' + matIndex
        return this.projectMaterials[matIndex].Project_Material.materialname;
      } else {
        return "?";
      }
      // .then((res) => console.log(res.data));
      // // .then((res)=>materialTypes.push(res.data))
      //     .then((res) => this.materialTypes.push(res.data))

      //   // this.templateService
      // }
    },
    projectMaterialsCategory(category) {
      console.log(category);
      let materials = this.projectMaterials.filter(function (e) {
        return e.Project_Material.category == category;
      });

      // materials=[
      //   {id:'83',materialname:'test'}
      // ]
      // return { "id": 83, "materialid": 575, "quantity": 2000, "project": 51, "company_owner": 1, "status": 0, "createdAt": "2021-02-14T18:45:42.000Z", "updatedAt": "2021-02-14T19:14:42.000Z", "Project_Material": { "category": "Fiber Kabel", "materialcode": "155003530", "materialname": "FO/A-2/SM/B1.3-MICRO-D" } }
      return materials;
      return this.projectMaterials;

      return [1, 2, 3];
    },
    downloadMultiple() {
      let files = this.files;
      for (let i = 0; i < files.length; i++) {
        if(files[i].checked==true){

          console.log(files[i])
        console.log(files[i].url);
        axios({
          url: files[i].url,
          method: "GET",
          responseType: "arraybuffer",
        }).then((response) => {
          // console.log(response)
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", "file.jpg");
          document.body.appendChild(fileLink);

          fileLink.click();
        });
          }
      }
    },
    onEmit(value) {
      console.log("Emitted!");
      this.projectMaterials = value;

      console.log(value[0].Project_Material.category);
    },
    lengthSplit(array, position) {
      if (array && array[position]) {
        let number = array.split(",")[position];
        if (!isNaN(number)) {
          return number;
        } else {
          return "";
        }
      }
    },
    updateSplit(array, position, event) {
      if (this.object[0][array] == null || this.object[0][array] == "") {
        //Datatype Lengde finnes egentlig ikke (Er NULL eller tom), så vi lager vår egen array, med fra, til og sum
        var arr = ["a", "b", "c"];
        arr[position] = event; //Oppdaterer feltet som er puttet i funksjonen
      } else {
        //Array finnes, så bare splitt opp, og
        var arr = this.object[0][array].split(",");
        arr[position] = event; //Oppdater feltet som er puttet i funksjonen
      }
      if (
        //Sjekker om begge posisjoner er heltall, for å summere.
        Number.isInteger(parseInt(arr[0])) &&
        Number.isInteger(parseInt(arr[1]))
      ) {
        let sum = arr[0] - arr[1];
        if (sum < 0) {
          sum = sum * -1;
        }
        arr[2] = sum;
      }
      this.object[0][array] = arr.toString(); //Konverter tilbake til string, for lagring i DB
    },

    deleteDocument(id) {
      if (confirm("Vil du slette denne filen?")) {
        this.projectService.deleteFile({ id: id }).then((response) => {
          this.fetchFiles();
        });
      }
    },

    // transaction(selectedMaterial, number) {
    //   const object = {
    //     materialid: selectedMaterial.id,
    //     number: number,
    //     comment: selectedMaterial.comment,
    //     object: this.object[0].id,
    //     template: this.$route.params.template,
    //     project: this.object[0].project,
    //   };
    //   console.log(selectedMaterial);
    //   this.projectService
    //     .telenorMaterialTransaction(object)
    //     //   .then((data) => console.log(data[0]))
    //     //   .then(this.fetchInventories);
    //     // .then((data)=>console.log(data))
    //     .then((data) => (this.selectedMaterial.quantity = data[0].quantity));
    //   this.number = 0;
    //   this.activeStep = 2;
    //   // this.inventoryService
    //   //   .getInventory(selectedProduct.id)
    //   //   .then((data) => (console.log(data)))
    //   //   .then(this.fetchInventories);
    // },
    // selectMaterial(event) {
    //   console.log(event.data);
    //   this.selectedMaterial = event.data;
    //   this.showModal = true;
    // },
    // fetchMaterials() {
    //   this.projectService
    //     .fetchMaterials(this.object[0].project)
    //     .then((data) => (this.telenorMateriel = data));
    // },
    fileType(filename) {
      if (filename.slice(filename.length - 4) == ".pdf") {
        return "pdf";
      }
      if (filename.slice(filename.length - 5) == ".xlsx") {
        return "xlsx";
      }
      return "image";
    },
    // type11(val,item, i){
    //   let org = this.object[item.datafield]
    //   console.log('Org: ' + org)
    //   org = org.split(',')
    //   org[i]=val
    //   org=org.toString()
    //   console.log('Oppdatert: ' + org)
    //   // this.object[item.datafield]='000'
    //   // this.editobject[item.datafield]=this.object[item.datafield]
    //   // console.log(item.datafield)
    //   // this.$set(this.editobject,item.datafield,'00')
    //   // console.log(this.editobject)
    //     let temp=[
    //       1,2,3
    //     ]
    //     let sum=-5
    //     if (sum>0){sum=sum*-1}

    //   temp = temp.toString()

    // },
    myUploader(event) {
      this.isLoading = true;
      console.log("Upload?");
      console.log(event);
      this.file = event.files[0];
      this.uploadExcel();
    },

    uploadExcel() {
      let data = {
        name: "",
        comment: "",
        project_id: this.object[0].project,
        table: `template_${this.$route.params.template}`,
        parent_id: this.object[0].id,
      };
      console.log("Upload");
      this.projectService.uploadFile(data, this.file).then((response) => {
        console.log(response);
        this.fetchFiles();
        this.fetchFiles();
        this.newObjectDialog = false;
        this.file = null;
        this.isLoading = false;
      });
    },
    pushType(type) {
      if (!this.types.includes(type)) {
        this.types.push(type);
      }
    },
    updateStatus(status) {
      let data = {
        id: this.object[0].id,
        project: this.object[0].project,
        template: this.$route.params.template,
        fields: {
          status: 1,
        },
      };
      this.projectService.updateObject(data).then((data) => console.log(data));
      this.$router.push("/project/" + this.object[0].project);
    },
    updateObject() {
      let data = {
        fields: this.editobject,
        id: this.object[0].id,
        project: this.object[0].project,
        template: this.$route.params.template,
      };
      for (let i = 0; i < Object.keys(this.editobject).length; i++) {
        //Looper igjennom alle KEYS i objekt
        // if (this.endretobject[Object.values(this.endreobject)[i]]==this.object[Object.keys(this.endretobject)[i]]))
        if (
          this.editobject[Object.keys(this.editobject)[i]] ==
          this.copy[Object.keys(this.editobject)[i]]
        ) {
          delete data.fields[Object.keys(this.editobject)[i]];
          //HVIS (VALUES til OBJEKT i endret == VALUES til SAMME OBJEKT ORIGINALT) så slett KEY fra
        }
      }
      data.types = this.types;
      this.projectService.updateObject(data).then((data) => this.fetchObject());
      this.editobject = {};
      this.editStatic = true;
      this.types = [];
      // .then((data)=> console.log(data))
    },
    logFunction() {
      let cols = this.templateInfo;
      cols.push({ name: "priority", datafield: "priority" });
      cols.push({ name: "completed", datafield: "completed" });
      let log = this.log;
      for (let i = 0; i < cols.length; i++) {
        for (let a = 0; a < log.length; a++) {
          if (log[a].field == cols[i].datafield) {
            this.$set(this.templateInfo[i], "dato", log[a].createdAt);
            this.$set(this.templateInfo[i], "author", log[a].author);
            if (
              this.templateInfo[i].Datafields &&
              [1, 2, 4, 5, 8].includes(this.templateInfo[i].Datafields[0].type)
            ) {
              this.$set(this.templateInfo[i], "edit", false);
            }
          }
        }
      }

      // this.cols=cols
      console.log(this.templateInfo);
    },
    fetchLog() {
      this.projectService
        .getLog(this.$route.params.template, this.$route.params.id)
        .then((data) => (this.log = data))
        .then((data) => this.logFunction());
    },
    fieldsBox(group) {
      return this.templateInfo.filter((element) => element.group == group);
    },
    groupBoxes() {
      for (let i = 0; i < this.templateInfo.length; i++) {
        if (!this.groups.includes(this.templateInfo[i].group)) {
          this.groups.push(this.templateInfo[i].group);
        }
      }
    },
    returnFieldName(datafield, type) {
      if (datafield == "priority") {
        return "Prioritert";
      }
      if (datafield == "completed") {
        return "Ferdigstilt";
      }
      let matIndex = this.templateInfo.findIndex(
        (x) => x.datafield == datafield
      );

      if (matIndex > -1) {
        if (type) {
          return this.templateInfo[matIndex].Datafields[0].type;
        } else {
          return this.templateInfo[matIndex].Datafields[0].name;
        }
        // templateInfo[0].Datafields[0].name
      } else {
        return "?";
      }
    },
    fetchTemplateInfo() {
      this.templateService
        .getTemplateInfo(this.$route.params.template)
        .then((data) => (this.templateInfo = data))
        .then((data) => this.groupBoxes())
        .then((data) => this.fetchLog());
    },
    fetchObject() {
      this.projectService
        .getObject(this.$route.params.template, this.$route.params.id)
        .then((data) => (this.object = data));
      this.copy = JSON.parse(JSON.stringify(this.object));
      this.key++;
    },
    fetchFiles() {
      let object = {
        table: "template_" + this.$route.params.template,
        parent_id: this.$route.params.id,
      };
      this.projectService.getFiles(object).then((data) => (this.files = data));
    },
    fetchComments() {
      this.projectService
        .getComments(this.$route.params.template, this.$route.params.id)
        .then((response) => {
          this.comments = response;
          let comments = this.comments;
          for (let i = 0; i < comments.length; i++) {
            this.comments[i].isOpen = false;
            if (comments[i].parent) {
              //Finn objekt med ID (comment[i].parent)
              let index = comments.map((e) => e.id).indexOf(comments[i].parent);
              console.log(index);

              //Push Comment[i] til comment[Med ID] (Index)
              if (!this.comments[index].under) {
                // this.comments[index].under=[]
                this.$set(this.comments[index], "under", []);
              }
              this.comments[index].under.push(comments[i]);
              // this.comments[index].under.push

              //Delete Comment[i] (Kanskje ikke nødvendig)
              // this.comments[i]
            }
          }
        })
        .catch((e) => {
          console.log("Feil med henting av kommentarer: " + e);
        });
    },

    createComment(parent) {
      let data = {
        project: this.object[0].project,
        template: this.$route.params.template,
        object: this.object[0].id,
        parent: this.parent,
        content: this.comment,
      };
      this.projectService
        .createComment(data)
        .then((response) => {
          this.fetchComments();
          this.comment = "";
          this.newComment = false;
        })
        .catch((e) => {
          console.log("Feil komentar! " + e);
        });
    },
  },
  mounted() {
    this.fetchObject();
    this.fetchTemplateInfo();
    this.fetchComments();
    this.fetchFiles();
    // this.fetchLog();
  },
};
</script>

<style>
.box {
  text-align: left;

  border-radius: 5px;
  padding: 30px;
  margin-top: 20px;
  background-color: whitesmoke;
}
.logg {
  display: inline;
  font-style: italic;
  margin-left: 10px;
}
.popup {
  /* background: greenyellow; */
  cursor: pointer;
  z-index: 90;
  position: fixed;
  left: 25%;
  width: 50%;
  height: 36px;
  top: 110px;
  border-radius: 15px 15px 15px 15px;
}
#outer {
  text-align: center;
}
.inner {
  position: relative;
  top: 6px;
  display: inline-block;
}
</style>