<template>
<div>


<!-- <Sidebar visible=true>
    Conent
</Sidebar> -->
<div style="float:right;">
<Button label="toggle" @click="open=!open" />
</div>



<br>
<h1>Splash page for dashboard</h1>

Darkmode
<Checkbox v-model="darkMode" :binary="true" />
</div>
</template>


<script>

export default {
    components:{

    },
    data() {
        return {
           dashboard:[
               {label:'Prosjekter', to:'/dashboard/prosjekter'}
           ],
           darkMode:false,
           open:true,
            active: [true, false, false]
        }
    },
    methods: {
        activate(index) {
            let activeArray = [...this.active];
            for (let i = 0 ; i < activeArray.length; i++) {
                activeArray[i] = (i === index);
            }
            this.active = activeArray;
        }
    },
    mounted(){
        
      let bodyElement = document.body;
    bodyElement.classList.add("app-background");
    let htmlElement = document.documentElement;
    let theme = localStorage.getItem("theme");

    if (theme === 'dark') {
        htmlElement.setAttribute('theme', 'dark')
        this.darkMode = true
    } else {
        htmlElement.setAttribute('theme', 'light');
        this.darkMode = false
    }
    },
      watch: {
    darkMode: function () {
      console.log('byttet til ' + this.darkMode)
        // add/remove class to/from html tag
        let htmlElement = document.documentElement;

        if (this.darkMode) {
            localStorage.setItem("theme", 'dark');
            htmlElement.setAttribute('theme', 'dark');
        } else {
            localStorage.setItem("theme", 'light');
            htmlElement.setAttribute('theme', 'light');
        }
    }
    }
}
</script>

