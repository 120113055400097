<template>
<div>
<Button  @click="inventoryModal=true"  class="p-mr-2 p-button-primary marginl" label="Ny lagerposisjon" />
    <Dialog header="Ny lagerposisjon"
    style="position:fixed;top:0;left:0;"
      modal
      :closable="true"
      :showHeader="true"
      :visible.sync="inventoryModal"
      :maximizable="true"
    >
  
  <div style="padding:20px;">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-6 p-mt-2">
        <span class="p-float-label">
          <Dropdown
            style="text-align:left;"
            v-model="inventory.product_id"
            :options="products"
            optionValue="id"
            optionLabel="name"
          />
          <label for="category">Produkt</label>
        </span>
      </div>

      <div class="p-field p-col-12 p-md-6 p-mt-2">
        <span class="p-float-label">
          <Dropdown
            style="text-align:left;"
            v-model="inventory.storage"
            :options="storages"
            optionValue="id"
            optionLabel="name"
          />
          <label for="storage">Lager?</label>
        </span>
      </div>

      <div class="p-field p-col-12 p-md-6 p-mt-2">
        <span class="p-float-label">
          <Dropdown
            :disabled="!inventory.storage"
            style="text-align:left;"
            v-model="inventory.location"
            :options="locationsf(inventory.storage)"
            optionValue="id"
            optionLabel="name"
          />
          <label for="supplier">Lokasjon</label>
        </span>
      </div>

      <!-- <div class="p-field p-col-12 p-md-6 p-mt-2">
        <span class="p-float-label">
          <Dropdown
            :disabled="!inventory.location"
            style="text-align:left;"
            v-model="inventory.sub_location"
            :options="sublocationsf(inventory.location)"
            optionValue="id"
            optionLabel="sub_location"
          />
          <label for="category">Sublokasjon</label>
        </span>
      </div> -->

      <div class="p-field p-col-12 p-md-6 p-mt-2">
        <span class="p-float-label">
          <InputText v-model="inventory.quantity" id="quantity" type="text" />
          <label for="quantity">Antall</label>
        </span>
      </div>

      <div class="p-field p-col-12 p-md-6 p-mt-2">
        <span class="p-float-label">
          <InputText v-model="inventory.comment" id="comment" type="text" />
          <label for="comment">Kommentar</label>
        </span>
      </div>
    </div>
  <Button
        @click="newInventory(inventory)"
        label="Lagre"
        icon="pi pi-check"
        class="p-button-primary"
      />
    </div>
  </Dialog>
  </div>
</template>

<script>
import CustomerService from "@/services/Customer";
import InventoryService from "@/services/Inventory/Inventory";
export default {
  name:"NewInventory",
  methods: {
    fetchProducts() {
      this.inventoryService
        .getProducts(0)
        .then((data) => (this.products = data));
    },
    fetchStorages() {
      this.inventoryService
        .getStorages(0)
        .then((data) => (this.storages = data));
    },
    fetchLocations() {
      this.inventoryService
        .getLocations(0)
        .then((data) => (this.locations = data));
    },
    fetchSublocations() {
      this.inventoryService
        .getSublocations(0)
        .then((data) => (this.sublocations = data));
    },
    newInventory(inventory) {
      this.inventoryService
        .createInventory(inventory)
        .then((data) => console.log(data));
        this.inventoryModal=false;
      this.$emit("clicked", "newinventory");
    },
    locationsf(id) {
      return this.locations.filter((element) => id == element.storage);
    },
    sublocationsf(id) {
      return this.sublocations.filter((element) => id == element.location);
    },
  },

props:['predefinedStorage', 'predefinedProduct'],

  data() {
    return {
      inventoryModal:false,
      inventory: {
        storage: parseInt(this.predefinedStorage)||'',
        product_id:parseInt(this.predefinedProduct)||'',
      },
      inventoryService: null,
      products: [],
      storages: [],
      locations: [],
      sublocations: [],
    };
  },
  created() {
    this.inventoryService = new InventoryService();
  },
  mounted() {
    this.fetchProducts(), this.fetchStorages(), this.fetchLocations();
    this.fetchSublocations();

  },
};
</script>

<style>
.p-dialog .p-dialog-content {
	overflow-y: visible !important;
}
.p-dialog-mask {
	position: absolute;
	pointer-events: auto; /* (optional, doesn't cover whole area when scroll presents) */
}
</style>