<template>
  <div style="height:100%;">
    <DataTable :autoLayout="true" :filters="filters" removableSort :value="products" data-key="id">
      <template #header>
        <div class="p-col-12">
          <div style="text-align:left;">
            <h4>Produkter</h4>
          </div>
        </div>
        <div class="p-d-flex p-flex-column p-flex-md-row">
          <div class="p-mb-1 p-mr-2">
            <div style="text-align:left;">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global']" placeholder="Søk" />
              </span>
            </div>
          </div>
          <div class="p-mb-1 p-mr-2">
            <b-field style="text-align: left; padding-right: 20px;">
              <b-radio-button v-model="status" :native-value="0" type="is-primary">
                <span>Aktive</span>
              </b-radio-button>

              <b-radio-button v-model="status" :native-value="1" type="is-primary">
                <span>Inaktive</span>
              </b-radio-button>
            </b-field>
          </div>
        </div>
      </template>
      <template #empty>Ingen treff</template>

      <Column sortable field="name" header="Navn"></Column>
      <Column sortable field="article_number" header="Artikkelnummer"></Column>
      <Column sortable field="elnumber" header="Elnummer"></Column>
      <Column sortable field="quantity_ip" header="Per pakke"></Column>
      <Column sortable field="description" header="Beskrivelse"></Column>
      <Column sortable field="price_per" header="Pris per"></Column>
      <Column sortable field="min_quantity" header="Minste beholdning"></Column>
      <Column sortable field="Inventory_Manufacturer.name" header="Produsent?">
        <template #body="slotProps">
            <span v-if="slotProps.data.Inventory_Manufacturer">{{slotProps.data.Inventory_Manufacturer.name}}</span>
          </template>
      </Column>
      <Column sortable field="Inventory_Supplier.name" header="Leverandør">
              <template #body="slotProps">
            <span v-if="slotProps.data.Inventory_Supplier">{{slotProps.data.Inventory_Supplier.name}}</span>
          </template>
      </Column>
      <Column sortable field="Inventory_Product_Category.name" header="Kategori">
              <template #body="slotProps">
            <span v-if="slotProps.data.Inventory_Product_Category">{{slotProps.data.Inventory_Product_Category.name}}</span>
          </template>
      </Column>
      <Column bodyStyle="text-align:right;" header>
        <template #body="slotProps">
          <Button
            label="Endre"
            @click="editProduct(slotProps.data)"
            class="p-mr-2 p-button-danger p-button-rounded"
          />
        </template>
      </Column>
    </DataTable>

    <Dialog
      :visible.sync="productDialog"
      :style="{width: '450px'}"
      header="Endre produkt"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field p-col-12 p-md-12 p-mt-2">
        <span class="p-float-label">
          <InputText v-model="product.name" id="name" type="text" />
          <label for="name">Navn</label>
        </span>
      </div>
      <div class="p-field p-col-12 p-md-12 p-mt-2">
        <span class="p-float-label">
          <InputText v-model="product.article_number" id="article_number" type="text" />
          <label for="article_number">Artikkel nummer</label>
        </span>
      </div>
      <div class="p-field p-col-12 p-md-12 p-mt-2">
        <span class="p-float-label">
          <InputText v-model="product.elnumber" id="elnumber" type="text" />
          <label for="elnumber">Elnummer</label>
        </span>
      </div>
      <div class="p-field p-col-12 p-md-12 p-mt-2">
        <span class="p-float-label">
          <InputText v-model="product.quantity_ip" id="quantity_ip" type="text" />
          <label for="quantity_ip">Per pk.</label>
        </span>
      </div>
      <div class="p-field p-col-12 p-md-12 p-mt-2">
        <span class="p-float-label">
          <InputText v-model="product.description" id="description" type="text" />
          <label for="description">Beskrivelse</label>
        </span>
      </div>
      <div class="p-field p-col-12 p-md-12 p-mt-2">
        <span class="p-float-label">
          <InputText v-model="product.price_per" id="price_per" type="text" />
          <label for="price_per">Pris per</label>
        </span>
      </div>
      <div class="p-field p-col-12 p-md-12 p-mt-2">
        <span class="p-float-label">
          <InputText v-model="product.min_quantity" id="min_quantity" type="text" />
          <label for="min_quantity">Minste lagerbeholdning</label>
        </span>
      </div>

      <div class="p-field p-col-12 p-md-6 p-mt-2">
        <span class="p-float-label">
          <Dropdown
            style="text-align:left;"
            v-model="product.category"
            :options="productCategories"
            optionValue="id"
            optionLabel="name"
          />
          <label for="category">Kategori</label>
        </span>
      </div>
      <div class="p-field p-col-12 p-md-6 p-mt-2">
        <span class="p-float-label">
          <Dropdown
            style="text-align:left;"
            v-model="product.manufacturer"
            :options="manufacturers"
            optionValue="id"
            optionLabel="name"
          />
          <label for="manufacturers">Produsent?</label>
        </span>
      </div>

      <div class="p-field p-col-12 p-md-6 p-mt-2">
        <span class="p-float-label">
          <Dropdown
            style="text-align:left;"
            v-model="product.supplier"
            :options="suppliers"
            optionValue="id"
            optionLabel="name"
          />
          <label for="supplier">Leverandør</label>
        </span>
      </div>

      <template #footer>
        <div v-if="product.status==0">
          <Button
            style="float:left;"
            label="Deaktiver"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="updateStatus(1)"
          />
          <Button
            @click="updateProduct(product)"
            label="Lagre"
            icon="pi pi-check"
            class="p-button-primary"
          />
        </div>
        <div v-if="product.status==1">
          <Button
            style="float:left;"
            label="Slett"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="displayConfirmation=true"
          />
          <Button
            @click="updateStatus(0)"
            label="Aktiver"
            icon="pi pi-check"
            class="p-button-danger"
          />
        </div>
      </template>
    </Dialog>
    <Dialog
      header="Advarsel!"
      :visible.sync="displayConfirmation"
      :style="{width: '350px'}"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>Er du sikker på at du vil slette produktkategorien?</span>
      </div>
      <template #footer>
        <Button
          label="Nei"
          icon="pi pi-times"
          @click="displayConfirmation=false"
          class="p-button-text"
        />
        <Button
          label="Ja"
          icon="pi pi-check"
          @click="updateStatus(2)"
          class="p-button-text"
          
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import InventoryService from "@/services/Inventory/Inventory";
export default {
  data() {
    return {
      displayConfirmation: false,
      productDialog: false,
      deleteproductDialog: false,
      product: {},
      submitted: false,
      productService: null,
      filters: {},
      status: 0,
      products: [],
      products: [],
      locations: [],
      suppliers: [],
      manufacturers: [],
      productCategories: [],
    };
  },

  watch: {
    status: function () {
      this.fetchData();
    },
  },

  methods: {
    updateStatus(status) {
      const product = { id: this.product.id, status: status };
      this.inventoryService
        .updateProduct(product)
        .then((data) => this.fetchData());
      this.productDialog = false;
      this.displayConfirmation = false;
    },
    updateProduct(product) {
      this.inventoryService
        .updateProduct(product)
        .then((data) => this.fetchData());
      this.productDialog = false;
      this.displayConfirmation = false;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    fetchSuppliers() {
      this.inventoryService
        .getSuppliers(0)
        .then((data) => (this.suppliers = data));
    },
    fetchManufacturers() {
      this.inventoryService
        .getManufacturers(0)
        .then((data) => (this.manufacturers = data));
    },
    fetchProductCategories() {
      this.inventoryService
        .getProductCategories(0)
        .then((data) => (this.productCategories = data));
    },
    fetchData() {
      this.inventoryService
        .getProducts(this.status)
        .then((data) => (this.products = data));
    },
    editProduct(product) {
      this.product = { ...product };
      this.productDialog = true;
    },
  },

  created() {
    this.inventoryService = new InventoryService();
  },

  mounted() {
    this.fetchSuppliers(),
      this.fetchManufacturers(),
      this.fetchProductCategories(),
      this.fetchData();
  },
};
</script>