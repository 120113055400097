import axios from 'axios'

export default class TemplateService {
	getProjectCategories(status) {
		return axios.get('api/getprojectcategories/' + status).then(res => res.data.data);
	}
	getAllTemplates(status) {
		return axios.get('api/getalltemplates/' + status).then(res => res.data.data);
	}
	getTemplate(id) {
		return axios.get('api/gettemplate/' + id).then(res => res.data.data)
	}
	getTemplateInfo(id) {
		return axios.get('api/gettemplateinfo/' + id).then(res => res.data.data)
	}
	getTemplateName(templates){
		let  data={template:templates};
		return axios.post('api/gettemplates', data).then(res=>res.data.data)
	}
	updateTemplate(template){
		return axios.post('api/updatetemplate', template).then(res=>res.data.data)
	}
	updateDatafield(datafield){
		return axios.post('api/updatedatafield',datafield).then(res=>res.data.data)
	}
	createDatafieldCategory(datafieldCategory){
		return axios.post('api/createdatafieldcategory',datafieldCategory).then(res=>res.data.data)
	}
	updateDatafieldCategory(datafieldCategory){
		return axios.post('api/updatedatafieldcategory',datafieldCategory).then(res=>res.data.data)
	}
	addTemplateField(datafield){
		return axios.post('api/adddatafield',datafield).then(res=>res.data.data)
	}
	updateTemplateFields(datafields){
		return axios.post('api/updatetemplatefield',datafields).then(res=>res.data.data)
	}
	createTemplate(template){
		return axios.post('api/createtemplate',template).then(res=>res.data.data)
	}
	createDatafield(datafield){
		return axios.post('api/createdatafield', datafield).then(res=>res.data.data)
	}
	getDatafieldCategories(status) {
		return axios.get('api/getdatafieldcategories/'+status).then(res => res.data.data);
	}
	getDatafields(status) {
		return axios.get('api/getdatafields/'+status).then(res => res.data.data);
	}
	getDatafieldTypes(){
		return axios.get('api/getdatafieldtypes/').then(res=>res.data.data)
	}

}

