<template>
<div>
  <Button class="p-mr-2 p-button-primary marginl"  label="Ny lokasjon" @click="locationModal=true"/>
  <Dialog header="Ny lokasjon (Hylle/Pall/Rom)"
  style="position:fixed;top:0;left:0;"
        modal
      :closable="true"
      :showHeader="true"
      :visible.sync="locationModal"

    >
  <div style="padding:20px;">

    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-6 p-mt-2">
        <span class="p-float-label">
          <InputText v-model="location.name" id="name" type="text" />
          <label for="name">Lokasjonsnavn</label>
        </span>
      </div>

      <div class="p-field p-col-12 p-md-6 p-mt-2">
        <span class="p-float-label">
          <Dropdown
                :editable="true"
            style="text-align:left;"
            v-model="location.storage"
            :options="storages"
            optionValue="id"
            optionLabel="name"
          />
          <label for="datafieldcategory">Lager</label>
        </span>
      </div>
    </div>
    <div>
      <Button
        @click="newLocation(location)"
        label="Lagre"
        icon="pi pi-check"
        class="p-button-primary"
      />
    </div>
  </div>
     </Dialog>
  </div>
</template>

<script>
import CustomerService from "@/services/Customer";
import InventoryService from "@/services/Inventory/Inventory";
export default {
  watch:{
    locationModal: function(){
      if(this.locationModal==false){
        this.location.storage=this.predefinedStorage||''
        this.location.name=''
      }
    }
  },
  props:['predefinedStorage'],
  methods: {
    fetchStorages() {
      this.inventoryService
        .getStorages()
        .then((data) => (this.storages = data));
    },
    newLocation(location) {
      this.inventoryService
        .createLocation(location)
        .then((data) => console.log(data));
      this.$emit("clicked", "newlocation");
    },
  },

  data() {
    return {
      locationModal:false,
      location: {storage:this.predefinedStorage||''},
      storages: [],
      customerService: null,
    };
  },
  created() {
    this.inventoryService = new InventoryService();
  },
  mounted() {
    this.fetchStorages();
  },
};
</script>

<style>
</style>