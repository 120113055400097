<template>
  <div style="padding:20px;">
    <!-- <h4 style="text-align:left;" class="p-pb-2">Nytt datafelt</h4> -->
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-6 p-mt-2">
        <h6>Datafeltnavn</h6>
          <InputText v-model="datafield.name" id="name" type="text" />
      </div>
      <div class="p-field p-col-12 p-md-6 p-mt-2">
        <h6>Beskrivelse</h6>
          <InputText v-model="datafield.description" id="description" type="text" />
      </div>

      <div class="p-field p-col-12 p-md-6 p-mt-2">
        <h6>Datatype</h6>
          <Dropdown
            style="text-align:left;"
            v-model="datafield.type"
            :options="dataTypes"
            optionValue="id"
            optionLabel="display"
          />
      </div>

      <div class="p-field p-col-12 p-md-6 p-mt-2">
        <h6>Kategori</h6>
          <Dropdown
            style="text-align:left;"
            v-model="datafield.datafield_category"
            :options="datacategory"
            optionValue="id"
            optionLabel="name"
          />
      </div>

            <div class="p-field p-col-12 p-md-6 p-mt-2">
        <h6>Ekstra</h6>
          <InputText v-model="datafield.extra" id="extra" type="text" />
      </div>

    </div>
    <div>
      <!-- <Button
        style="float:left;"
        label="Avbryt"
        icon="pi pi-times"
        class="p-button-danger"
        @click="visible=false"
      /> -->

      <Button
        @click="createDatafield()"
        label="Lagre"
        icon="pi pi-check"
        class="p-button-primary"
      />
    </div>
  </div>
</template>

<script>
// import Users from "@/services/Users";
import TemplateService from "@/services/Template";
export default {
  methods: {
    fetchDatafieldTypes() {
      this.templateService
        .getDatafieldTypes()
        .then((data) => (this.dataTypes = data));
    },
    fetchDatafieldCategories() {
      this.templateService
        .getDatafieldCategories(0)
        .then((data) => (this.datacategory = data));
    },
    createDatafield() {
            if(!this.datafield.name || !this.datafield.type || !this.datafield.datafield_category){
        this.error='Fyll ut felter'
        return 
      }
      this.templateService
        .createDatafield(this.datafield)
        .then((data) => console.log(data));
      this.$emit("clicked", "someval");
    },
    // async registrer() {
    //   try {
    //     const response = await Users.opprettbruker(this.project);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
  },

  data() {
    return {
      templateService: null,
      dataTypes: [],
      datacategory: [],
      datafield: {},
    };
  },
  created() {
    this.templateService = new TemplateService();
  },
  mounted() {
    this.fetchDatafieldTypes(), this.fetchDatafieldCategories();
  },
};
</script>

<style>
</style>