<template>
  <div style="height:100%;">
    <h2>Uttak av varer</h2>
    <Button
      @click="$router.push('/checkout')"
      :label="`Handlekurv (${checkout.length})`"
      class="p-button-primary"
    />
    <Button @click="emptyCheckout()" class="marginl p-button-primary" label="Tøm kurv" />
    <b-tabs :animated="false">
      <b-tab-item v-for="storage in storages" :key="storage.id" :label="storage.name">
        <NewInventoryE @clicked="fetchInventories()" name="e" :predefinedStorage="storage.id" />
        <NewLocation @clicked="fetchInventories()" name="e" :predefinedStorage="storage.id" />
        <!-- {{inventories[0]}}
        <br>
        <br>
        <br>{{products[0]}}-->
        <DataTable
          class="p-datatable-sm"
          :autoLayout="true"
          :filters="filters"
          removableSort
          :value="storageByStorages(storage.id)"
          data-key="id"
        >
          <template #header>
            <div class="p-col-12">
              <div style="text-align:left;">
                <h4>Lager</h4>
              </div>
            </div>
            <div class="p-d-flex p-flex-column p-flex-md-row">
              <div class="p-mb-1 p-mr-2">
                <div style="text-align:left;">
                  <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global']" placeholder="Søk" />
                  </span>
                </div>
              </div>

              <div class="p-mb-1 p-mr-2">
                <MultiSelect
                  v-model="filters['Inventory_Product_Category.id']"
                  :options="productCategories" 
                  optionLabel="name"
                  optionValue="id"
                  placeholder="All"
                  class="p-column-filter"
                >
                  <template #option="slotProps">
                    <div class="p-multiselect-representative-option">
                      <span class="image-text">{{slotProps.option.name}}</span>
                    </div>
                  </template>
                </MultiSelect>
              </div>
            </div>
          </template>
          <template #empty>Ingen treff</template>

          <Column sortable field="Inventory_Product.name" header="Navn"></Column>
          <Column sortable field="Inventory_Location.name" header="Lokasjon">
            <template #body="slotProps">
              <span
                v-if="slotProps.data.Inventory_Location"
              >{{slotProps.data.Inventory_Location.name}}</span>
            </template>
          </Column>
          <Column field="quantity" header="Antall"></Column>
          <Column
            filterField="Inventory_Product_Category.id"
            filterMatchMode="in"
            sortable
            field="Inventory_Product_Category.name"
            header="Kategori"
          >
            <template #body="slotProps">
              <span
                v-if="slotProps.data.Inventory_Product_Category"
              >{{slotProps.data.Inventory_Product_Category.name}}</span>
            </template>
            <template #filter></template>
          </Column>

          <Column sortable field="Inventory_Product.article_number" header="Artikkelnummer"></Column>
          <Column sortable field="Inventory_Product.elnumber" header="Elnummer"></Column>
          <Column header>
            <template #body="slotProps">
              <div>
                <Button
                  v-if="!checkout.includes(slotProps.data.id)"
                  class="p-button-primary marginl"
                  @click="checkout.push(slotProps.data.id);"
                  icon="pi pi-shopping-cart"
                />
                <Button
                  v-if="checkout.includes(slotProps.data.id)"
                  class="p-button-danger marginl"
                  @click="checkout.splice(checkout.indexOf(slotProps.data.id),1);"
                  icon="pi pi-shopping-cart"
                />

                <Button
                  class="p-button-primary marginl"
                  @click="editObject=slotProps.data;showModal=true"
                  label="Endre"
                />
              </div>
            </template>
          </Column>
        </DataTable>
      </b-tab-item>

      <b-tab-item label="Alle lagre">
        <DataTable
          class="p-datatable-sm"
          :expandedRows.sync="expandedRows"
          :autoLayout="true"
          :filters="filters"
          removableSort
          :value="products"
          data-key="id"
        >
          <template #header>
            <div class="p-col-12">
              <div style="text-align:left;">
                <h4>Lager</h4>
              </div>
            </div>
            <div class="p-d-flex p-flex-column p-flex-md-row">
              <div class="p-mb-1 p-mr-2">
                <div style="text-align:left;">
                  <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <InputText v-model="filters['global']" placeholder="Søk" />
                  </span>
                </div>
              </div>

              <div class="p-mb-1 p-mr-2">
                <MultiSelect
                  v-model="filters['Inventory_Product_Category.id']"
                  :options="productCategories"
                  optionLabel="name"
                  optionValue="id"
                  placeholder="All"
                  class="p-column-filter"
                >
                  <template #option="slotProps">
                    <div class="p-multiselect-representative-option">
                      <span class="image-text">{{slotProps.option.name}}</span>
                    </div>
                  </template>
                </MultiSelect>
              </div>
            </div>
          </template>
          <template #empty>Ingen treff</template>
          <Column :expander="true" headerStyle="width: 3rem" />
          <Column sortable field="name" header="Navn"></Column>
          <Column header="Antall">
            <template #body="slotProps">
              <span>{{sumQuantity(slotProps.data.id)}}</span>
            </template>
          </Column>
          <Column
            filterField="Inventory_Product_Category.id"
            filterMatchMode="in"
            sortable
            field="Inventory_Product_Category.name"
            header="Kategori"
          >
            <template #body="slotProps">
              <span
                v-if="slotProps.data.Inventory_Product_Category"
              >{{slotProps.data.Inventory_Product_Category.name}}</span>
            </template>
            <template #filter></template>
          </Column>

          <Column sortable field="article_number" header="Artikkelnummer"></Column>
          <Column sortable field="elnumber" header="Elnummer"></Column>

          <template #expansion="slotProps">
            <div class="orders-subtable">
              <DataTable class="p-datatable-sm" :value="inventoriesFiltered(slotProps.data.id)">
                <!-- <DataTable class="p-datatable-sm" :value="inventories"> -->
                <Column field="Inventory_Storage.name" header="Lager">
                  <template #body="slotProps">
                    <span
                      v-if="slotProps.data.Inventory_Storage"
                    >{{slotProps.data.Inventory_Storage.name}}</span>
                  </template>
                </Column>
                <Column field="Inventory_Location.name" header="Lokasjon">
                  <template #body="slotProps">
                    <span
                      v-if="slotProps.data.Inventory_Location"
                    >{{slotProps.data.Inventory_Location.name}}</span>
                  </template>
                </Column>
                <Column field="quantity" header="Antall"></Column>
                <Column header>
                  <template #body="slotProps">
                    <div>
                      <!-- <InputNumber v-model="number" mode="decimal" :min="0" /> -->
                      <Button
                        v-if="!checkout.includes(slotProps.data.id)"
                        class="p-button-primary marginl"
                        @click="checkout.push(slotProps.data.id);"
                        icon="pi pi-shopping-cart"
                      />
                      <Button
                        v-if="checkout.includes(slotProps.data.id)"
                        class="p-button-danger marginl"
                        @click="checkout.splice(checkout.indexOf(slotProps.data.id),1);"
                        icon="pi pi-shopping-cart"
                      />

                      <Button
                        class="p-button-primary marginl"
                        @click="editObject=slotProps.data;showModal=true"
                        label="Endre"
                      />
                    </div>
                  </template>
                </Column>
              </DataTable>
            </div>
          </template>
        </DataTable>
      </b-tab-item>
    </b-tabs>

    <Dialog modal :closable="true" :showHeader="true" :visible.sync="showModal">
      <div v-if="editObject" style="padding:20px;">
        <h4 style="text-align:left;" class="p-pb-2">{{editObject.Inventory_Product.name}}</h4>
        <h5
          style="text-align:left;"
          class="p-pb-2"
        >Artikkelnummer: {{editObject.Inventory_Product.article_number}}</h5>
        <h5
          style="text-align:left;"
          class="p-pb-2"
        >Lager: {{editObject.Inventory_Storage.name}} / {{editObject.Inventory_Location.name}}</h5>
        <hr />
        <h5 style="text-align:left;" class="p-pb-2">Antall: {{editObject.quantity}}</h5>
        <form @submit="createProject()">
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-6 p-mt-2">
              <span class="p-float-label">
                <InputNumber v-model="number" mode="decimal" :min="0" />
                <label for="projectname">Antall</label>
              </span>
            </div>

            <div class="p-field p-col-12 p-md-12">
              <!-- <Button @click="registrer()" class="p-button-secondary" label="Legg til" /> -->
            </div>
          </div>

          <div>
            <Button
              style="float:left;"
              label="Ta ut"
              icon="pi pi-times"
              class="p-button-danger"
              @click="transaction(editObject, number*-1)"
            />

            <Button
              @click="transaction(editObject, number)"
              label="Sett inn"
              icon="pi pi-check"
              class="p-button-primary marginl"
            />
                        <Button
              @click="qrcode(editObject, number)"
              label="QR Code"
              
              class="p-button-primary marginl"
            />
          </div>
        </form>
        <QrCode :product="editObject"/>
      </div>
    </Dialog>

    <Dialog modal :closable="true" :showHeader="true" :visible.sync="checkoutModal"></Dialog>

  </div>
</template>

<script>
import NewInventory from "@/components/Dashboard/InventoryModule/NewInventory.vue";
import NewLocation from "@/components/Dashboard/InventoryModule/NewLocation.vue";
import InventoryService from "@/services/Inventory/Inventory";
import QrCode from "@/components/InventoryModule/QRCode"
export default {
  data() {
    return {
      checkout: [],
      checkoutModal: false,
      productCategory: null,
      number: null,
      editObject: null,
      showModal: false,
      rowDeposit: [],
      rowWithdraw: [],
      editingRows: [],
      displayConfirmation: false,
      productDialog: false,
      deleteproductDialog: false,
      product: {},
      submitted: false,
      productService: null,
      filters: {},
      status: 0,
      expandedRows: [],
      products: [],
      products: [],
      pIn: false,
      pOut: false,
      locations: [],
      suppliers: [],
      manufacturers: [],
      productCategories: [],
      inventories: [],
      storages: [],
    };
  },

  watch: {
    status: function () {
      this.fetchData();
    },
    checkout: function () {
      localStorage.setItem("checkout", JSON.stringify(this.checkout));
    },
  },

  methods: {
    qrcode(){
      this.showQR=true
    },
    emptyCheckout() {
      localStorage.removeItem("checkout");
      this.checkout = [];
    },
    transaction(editObject, number) {
      const object = {
        id: editObject.id,
        number: number,
      };
      (this.editObject = null),
        (this.number = null),
        (this.showModal = false),
        this.inventoryService
          .updateInventoryQuantity(object)
          .then((data) => console.log(data))
          .then(this.fetchInventories);
    },
    inventoriesFiltered(id) {
      return this.inventories.filter((element) => element.product_id == id);
    },
    storageByStorages(id) {
      return this.inventories.filter((element) => element.storage == id);
    },
    locationsByStorage(id) {
      return this.locations.filter((element) => element.storage == id);
    },
    dataFiltered(productCategory) {
      // return this.products.filter((element)=>element.category==productCategory.id)
    },
    sumQuantity(id) {
      let arr = this.inventories.filter((element) => element.product_id == id);
      return arr.reduce(function (acc, val) {
        return acc + val.quantity;
      }, 0);
    },
    updateStatus(status) {
      const product = { id: this.product.id, status: status };
      this.inventoryService
        .updateProduct(product)
        .then((data) => this.fetchData());
      this.productDialog = false;
      this.displayConfirmation = false;
    },
    updateProduct(product) {
      this.inventoryService
        .updateProduct(product)
        .then((data) => this.fetchData());
      this.productDialog = false;
      this.displayConfirmation = false;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    fetchLocations() {
      this.inventoryService
        .getLocations(0)
        .then((data) => (this.locations = data));
    },
    fetchSuppliers() {
      this.inventoryService
        .getSuppliers(0)
        .then((data) => (this.suppliers = data));
    },
    fetchManufacturers() {
      this.inventoryService
        .getManufacturers(0)
        .then((data) => (this.manufacturers = data));
    },
    fetchProductCategories() {
      this.inventoryService
        .getProductCategories(0)
        .then((data) => (this.productCategories = data));
    },
    fetchData() {
      this.inventoryService
        .getProducts(0)
        .then((data) => (this.products = data));
    },
    fetchInventories() {
      this.inventoryService
        .getInventories(0)
        .then((data) => (this.inventories = data));
    },
    fetchStorages() {
      this.inventoryService
        .getStorages(0)
        .then((data) => (this.storages = data));
    },
    editProduct(product) {
      this.product = { ...product };
      this.productDialog = true;
    },
    newInventory(inventory) {
      this.inventoryService
        .createInventory(inventory)
        .then((data) => console.log(data));
      this.$emit("clicked", "newinventory");
    },
  },

  created() {
    this.inventoryService = new InventoryService();
  },
  components: {
    NewInventoryE: NewInventory,
    NewLocation,
    QrCode
  },

  mounted() {
    if (localStorage.getItem("checkout")) {
      this.checkout = JSON.parse(localStorage.getItem("checkout"));
    }
    this.fetchSuppliers(),
      this.fetchManufacturers(),
      this.fetchProductCategories(),
      this.fetchData(),
      this.fetchInventories();
    this.fetchLocations();
    this.fetchStorages();
  },
};
</script>

<style>
.orders-subtable {
  /* padding-left:80px;
padding-right:30px; */
  /* padding-bottom:15px; */
  /* padding-top:0px; */
  margin-top: 0;
}
</style>