<template>
  <div>
    <CommonSettingNav />
        <div>
          <Button 
          class="p-mr-2 p-button-primary marginl"
          @click="companyModal=true">Ny bedrift</Button>
        </div>
    <Dialog header="Ny bedrift"
      modal
      :closable="true"
      :showHeader="true"
      :visible.sync="companyModal"
    >
      <NewCompany @clicked="companyModal=false;key++" />
    </Dialog>

    <CompanyTable :key="key" />
  </div>
</template>

<script>
import CommonSettingNav from "@/components/UI/CommonSettingNav.vue";
import CompanyTable from "@/components/Dashboard/Common/CompanyTable.vue";
import NewCompany from "@/components/Dashboard/Common/NewCompany.vue";
export default {
  data(){
    return{
      key:0,
      companyModal:false,
    }
  },
  components: {
    CommonSettingNav,
    CompanyTable,
    NewCompany,
  },
};
</script>
