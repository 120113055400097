<template>
  <section>
    <!-- {{object}}, {{project}} -->
    <!-- {{projectMaterials}} -->
    <!-- {{categories}} -->
    <!-- {{filters}} -->
    <!-- {{projectMaterials[0]}} -->
    <div>

    <DataTable
    style="min-height:500px"
      rowHover
      :filters="filters"
      @row-click="selectMaterial"
      :autoLayout="true"
      :value="projectMaterials"
      data-key="id"
      :contentStyle="{height: '300px'}"
    >
      <template #header>
        <div class="p-col-12">
          <div style="text-align: left">
            <h4>Prosjekt materiell</h4>
          </div>
          
        </div>
        <div class="p-d-flex p-flex-column p-flex-md-row">
          
          <div class="p-mb-1 p-mr-2">
            <div style="text-align: left">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global']" placeholder="Søk" />
              </span>
            </div>
          </div>
          <!-- <div v-for="category in categories" :key="category.index"> 
        <Button
          style="margin-right:5px;"
          @click="filterButton(category)"
          class="p-button-primary-outline active"
          :label="category"
        />

</div> -->
        <!-- <SelectButton v-if="type==='transaction'" multiple v-model="filters.Project_Material.category" :options="categories"/> -->
        <!-- <SelectButton v-if="type!='transaction'" multiple v-model="filters.category" :options="categories"/> -->
        <SelectButton multiple v-model="filters[category()]" :options="categories"/>
        </div>
      </template>
      <template #empty>Ingen treff</template>

      <Column sortable :field="materialname()" header="Navn"></Column>
      <Column sortable :field="materialcode()" header="Materiellkode"></Column>
      <Column
        filterMatchMode="in"
        :filterField="category()"
        sortable
        :field="category()"
        header="Kategori"
      >
        <!-- <template #filter>
          <MultiSelect
            v-model="filters[category()]"
            :options="categories"
            placeholder="Alle"
            class="p-column-filter"
          >
            <template #option="slotProps">
              <div class="p-multiselect-representative-option">
                <span class="image-text">{{ slotProps.option }}</span>
              </div>
            </template>
          </MultiSelect>
        </template> -->
      </Column>
      <Column
        v-if="type == 'transaction'"
        sortable
        field="quantity"
        header="Tilgjengelige"
      ></Column>
    </DataTable>
    </div>
<div>  

    <Dialog
      modal
      :closable="true"
      showHeader
      :header="dialogHeader()"
      :visible.sync="showModal"
    >
      <div v-if="selectedMaterial" style="padding: 20px">
        <div style="padding: 20px">
          <!-- <h4 style="text-align:left;" class="p-pb-2">Nytt prosjekt</h4> -->

          <div v-if="type == 'edit'" class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-12 p-mt-12">
              <h6>Materialnavn</h6>
              <InputText
                required="true"
                v-model="selectedMaterial.materialname"
                id="materialname"
                type="text"
              />
            </div>

            <div class="p-field p-col-12 p-md-12 p-mt-12">
              <h6>Materialkode</h6>
              <InputText
                required="true"
                v-model="selectedMaterial.materialcode"
                id="materialcode"
                type="text"
              />
            </div>

            <div class="p-field p-col-12 p-md-12 p-mt-12">
              <h6>Kategori</h6>
              <InputText
                required="true"
                v-model="selectedMaterial.category"
                id="category"
                type="text"
              />
            </div>
            <div>
              <Button
                @click="updateMaterial(selectedMaterial)"
                type="submit"
                label="Lagre"
                icon="pi pi-check"
                class="p-button-primary"
              />
              <h5>{{ error }}</h5>
            </div>
          </div>

          <div v-if="type == 'transaction'">
            {{ quantity }}
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12 p-md-12 p-mt-12">
                <h6>Materialnavn</h6>
                {{ selectedMaterial.Project_Material.materialname }}
              </div>

              <div class="p-field p-col-12 p-md-12 p-mt-12">
                <h6>Materialkode</h6>
                {{ selectedMaterial.Project_Material.materialcode }}
              </div>

              <div class="p-field p-col-12 p-md-12 p-mt-12">
                <h6>Kategori</h6>
                {{ selectedMaterial.Project_Material.category }}
              </div>
              <div class="p-field p-col-12 p-md-12 p-mt-12">
                <h6>Kommentar</h6>
                <InputText v-model="selectedMaterial.comment" type="text" />
              </div>
              <div class="p-field p-col-12 p-md-12 p-mt-12">
                <h6>Antall</h6>
                <InputNumber v-model="quantity" mode="decimal" />
              </div>
            </div>
            <Button
              style="float: left"
              label="Ta ut"
              icon="pi pi-times"
              class="p-button-danger"
              @click="transaction(selectedMaterial, quantity * -1)"
            />

            <Button
              @click="transaction(selectedMaterial, quantity)"
              label="Sett inn"
              icon="pi pi-check"
              class="p-button-primary marginl"
            />

            <h5>{{ error }}</h5>
          </div>

          <div v-if="type == 'new'">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12 p-md-12 p-mt-12">
                <h6>Materialnavn</h6>
                {{ selectedMaterial.materialname }}
              </div>

              <div class="p-field p-col-12 p-md-12 p-mt-12">
                <h6>Materialkode</h6>
                {{ selectedMaterial.materialcode }}
              </div>

              <div class="p-field p-col-12 p-md-12 p-mt-12">
                <h6>Kategori</h6>
                {{ selectedMaterial.category }}
              </div>
              <div class="p-field p-col-12 p-md-12 p-mt-12">
                <h6>Antall</h6>

                <InputNumber v-model="quantity" mode="decimal" />
              </div>
              <div class="p-field p-col-12 p-md-12 p-mt-12">
                <h6>Kommentar</h6>
                <InputText v-model="comment" type="text" />
              </div>
            </div>
            <Button
              style="float: left"
              label="Legg til"
              icon="pi pi-check"
              class="p-button-primary"
              @click="addMaterial(selectedMaterial)"
            />

            <h5>{{ error }}</h5>
          </div>
        </div>
      </div>
    </Dialog>
  </div>
  </section>
</template>



    <script>
import ProjectService from "@/services/Project";
import TemplateService from "@/services/Template";

export default {
  data() {
    return {
      contentStyle: {
        overflow: "scroll",
        "max-height": "100%",
      },
      categories: [],
      error: null,
      quantity: null,
      showModal: false,
      selectedMaterial: null,
      projectMaterials: null,
      projectService: null,
      comment: null,
      uploadModal: false,
      templateService: null,
      //   object: {},
      filters: {

        // "category":['Rør']
      },
      templateInfo: [],
      telenorMateriel: null,
    };
  },
  props: ["object", "project", "type","emit"],
  created() {
    this.templateService = new TemplateService();
    this.projectService = new ProjectService();
  },
  watch:{
    projectMaterials: function(val){
      this.$emit('emit',val)
      console.log(val[0])
      for (let i=0;i<val.length;i++){
        // console.log(val[i]['Project_Material']['category'])
        console.log(val[i][this.categoryArr()])
        if(this.type=='transaction'){
          if(!this.categories.includes(val[i]['Project_Material']['category']) && (val[i]['Project_Material']['category']!='')){
            this.categories.push(val[i]['Project_Material']['category'])
          }
        } else {
          if(!this.categories.includes(val[i]['category']) && (val[i]['category']!='') ){
            this.categories.push(val[i]['category'])
          }

        }
        // if(!this.categories.includes(val[i][this.category()])){
        // this.categories.push(val[i][this.category()])
        // this.categories.push(val[i]['Project_Material.category'])
        // console.log('Unik!')
        // this.categories.push('Rør')
        // console.log(this.val[i][this.category()])
        // console.log(this.category())
        // }
      }

        //     this.categories = this.projectMaterials
        // .map((item) => item.category)
        // .filter((value, index, self) => self.indexOf(value) === index);
    }
  },
  methods: {

    filterButton(category){
      console.log(category)
      if(this.type=='transaction'){
        var index = this.filters.Project_Material.category.indexOf(category)
        if(index!==-1){
          this.filters.Project_Material.splice(index,1)
        } else {
          this.filters.Project_Material.category.push(category)
        }
      } else {
                var index = this.filters.category.indexOf(category)
        if(index!==-1){
          this.filters.category.splice(index,1)
        } else {
          this.filters.category.push(category)
        }
      
      }
    },
    transactionLabel() {
      if (this.quantity > 0) {
        return "Ta ut";
      }
      if (this.quantity < 0) {
        return "Legg inn";
      }
    },
    dialogHeader() {
      if (this.type == "transaction") return "Ta ut / Legg til";
      if (this.type == "new") return "Legg til nytt materiell";
      if (this.type == "edit") return "Endre materiell";
    },
    materialname() {
      if (this.type == "transaction") return "Project_Material.materialname";
      return "materialname";
    },
    category() {
      if (this.type == "transaction") return "Project_Material.category";
      return "category";
    },
    categoryArr() {
      if (this.type == "transaction") return "filters.Project_Material.category";
      return "filters.category";
    },
    materialcode() {
      if (this.type == "transaction") return "Project_Material.materialcode";
      return "materialcode";
    },
    transaction(selectedMaterial, quantity) {
      const object = {
        materialid: selectedMaterial.id,
        quantity: quantity,
        comment: selectedMaterial.comment,
        object: this.object,
        template: this.$route.params.template,
        project: this.project,
      };
      console.log(selectedMaterial);
      this.projectService
        .projectMaterialTransaction(object)
        //   .then((data) => console.log(data[0]))
        //   .then(this.fetchInventories);
        // .then((data)=>console.log(data))
        .then((data) => (this.selectedMaterial.quantity = data[0].quantity));
      this.quantity = 0;
      this.fetchMaterials();
      this.showModal = false;
      // this.inventoryService
      //   .getInventory(selectedProduct.id)
      //   .then((data) => (console.log(data)))
      //   .then(this.fetchInventories);
    },
    updateMaterial(data) {
      this.projectService
        .updateProjectMaterial(data)
        .then(
          (data) => (
            console.log(data.status),
            console.log(data),
            (this.showModal = false)
          )
        );
    },
    selectMaterial(event) {
      if (this.type == "edit") {
        console.log(event.data);
        this.selectedMaterial = event.data;
        this.showModal = true;
      }
      if (this.type == "new") {
        this.selectedMaterial = event.data;
        this.showModal = true;
      }
      if (this.type == "transaction") {
        this.selectedMaterial = event.data;
        this.showModal = true;
      }
    },
    addMaterial(material) {
      let data = {
        material: material.id,
        quantity: this.quantity,
        comment: this.comment,
        object: this.object,
        project: this.project,
      };
      this.projectService
        .addNewMaterialToProject(data)
        .then((data) => console.log(data));
    },
    fetchAllMaterials() {
      this.projectService
        .getAllMaterials()
        .then((data) => (this.projectMaterials = data))
        .then(this.createCategories());
    },
    fetchMaterials() {
      this.projectService
        .fetchMaterialsProject(this.project)
        .then((data) => (this.projectMaterials = data));
        

      // .then((data) => (console.log( data)));
      // .then(this.createCategories())
    },
    createCategories() {
      console.log(this.projectMaterials);
      this.categories = this.projectMaterials
        .map((item) => item.category())
        .filter((value, index, self) => self.indexOf(value) === index);
    },
  },
  mounted() {
    if (this.type == "edit" || this.type == "new") {
      this.fetchAllMaterials();
    }

    if (this.type == "transaction") {
      this.fetchMaterials();
    }
  },
};
</script>

<style scoped>
  .p-dialog-mask{
    position:fixed !important;
  }
</style>