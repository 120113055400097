<template>
  <div style="padding:20px;">
    <h4 style="text-align:left;" class="p-pb-2">Ny produktkategori</h4>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-12 p-mt-2">
        <span class="p-float-label">
          <InputText v-model="productCategory.name" id="name" type="text" />
          <label for="name">Kategorinavn</label>
        </span>
      </div>
    </div>
    <div>
      <Button
        @click="newProductCategory(productCategory)"
        label="Lagre"
        icon="pi pi-check"
        class="p-button-primary"
      />
    </div>
  </div>
</template>

<script>
import CustomerService from "@/services/Customer";
import InventoryService from "@/services/Inventory/Inventory";
export default {
  methods: {
    newProductCategory(productCategory) {
      this.inventoryService
        .createProductCategory(productCategory)
        .then((data) => console.log(data));
      this.$emit("clicked", "newproductCategory");
    },
  },

  data() {
    return {
      productCategory: {},
      inventoryService: null,
    };
  },
  created() {
    this.inventoryService = new InventoryService();
  },
};
</script>

<style>
</style>