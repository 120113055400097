<template>
  <div>
    <CommonSettingNav />
    <div>
      <Button class="p-mr-2 p-button-primary marginl" @click="userModal=true">Ny bruker</Button>
    </div>
    <Dialog header="Ny bruker"
      modal
      :closable="true"
      :showHeader="true"
      :visible.sync="userModal"
    >
      <NewUser @clicked="userModal=false;key++" />
    </Dialog>

    <UserTable :key="key" />
  </div>
</template>

<script>
import CommonSettingNav from "@/components/UI/CommonSettingNav.vue";
import NewUser from "@/components/Dashboard/Common/NewUser.vue";
import UserTable from "@/components/Dashboard/Common/UserTable.vue";
export default {
  data() {
    return {
      userModal: false,
      key: 0,
    };
  },
  components: {
    CommonSettingNav,
    NewUser,
    UserTable,
  },
};
</script>