import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios';
import Vuex from 'vuex'
import store from './store/store'

import PrimeVue from 'primevue/config'
// const app = createApp(app)
// app.use(PrimeVue)

//==========================//
//      Axios               //
//==========================//
// axios.defaults.baseURL = 'https://192.168.1.15:3000'
// axios.defaults.baseURL = 'https://192.168.1.103:3000'
// axios.defaults.baseURL = 'https://ntxps.herokuapp.com'
// console.log('Ok?')
// console.log(process.env.VUE_APP_BASEURL)
// console.log(process.env.VUE_APP_TITLE)
axios.defaults.baseURL = process.env.VUE_APP_BASEURL
if(localStorage.getItem('user-token')){
  axios.defaults.headers={'Authorization':`Bearer ${$store.getters.token}`}
  // axios.defaults.headers={'Authorization':`Bearer ${localStorage.getItem('user-token')}`}
}
if(store.getters.user){
  axios.defaults.headers={'Authorization':`Bearer ${store.getters.token}`}
}
Vue.prototype.$https = axios;

//==========================//
//      PrimeVue Imports    //
//==========================//
import Dialog from 'primevue/dialog';
import TabMenu from 'primevue/tabmenu';
import Message from 'primevue/message';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Button from 'primevue/button';
import Panel from 'primevue/panel';
import Card from 'primevue/card';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import InputSwitch from 'primevue/inputswitch';
import SelectButton from 'primevue/selectbutton';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
// import Fieldset from 'primevue/fieldset';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions'
import Checkbox from 'primevue/checkbox';
import Sidebar from 'primevue/sidebar';
import MultiSelect from 'primevue/multiselect'
import Textarea from 'primevue/textarea';
import Tooltip from 'primevue/tooltip';
import FileUpload from 'primevue/fileupload';
import Listbox from 'primevue/listbox';
import ProgressSpinner from 'primevue/progressspinner';

//Loading overlay
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.component('loading-overlay', Loading)

//==========================//
//      Buefy Imports    //
//==========================//
// import Sidebar from 'buefy'
import Switch from 'buefy'
import Tabs from 'buefy'
import 'buefy/dist/buefy.css'
import Modal from 'buefy'

//==========================//
//      Bootstrap Imports   //
//==========================//
import { NavbarPlugin } from 'bootstrap-vue'

//==========================//
//      Style               //
//==========================//

// import 'primevue/resources/themes/bootstrap4-dark-purple/theme.css';
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'primeflex/primeflex.css';
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'                 //core css
import 'primeicons/primeicons.css'
import './registerServiceWorker' //PWA
import './assets/css/styles.scss'

import VueQuagga from "vue-quaggajs";


Vue.use(PrimeVue)
Vue.use(VueQuagga)
Vue.use(Vuex)
Vue.use(Switch)
Vue.use(Tabs)
Vue.use(Modal)

const moment = require ('moment')
require('moment/locale/nb')
Vue.use(require('vue-moment'), {
  moment
})

// Vue.use(BootstrapVue)
// Vue.use(IconsPlugin)
Vue.use(NavbarPlugin)

// Vue.use(Listbox)


Vue.component('Listbox',Listbox)
Vue.component('FileUpload',FileUpload)
Vue.component('Textarea',Textarea)
Vue.component('MultiSelect',MultiSelect)
Vue.component('InputNumber',InputNumber)
Vue.component('Sidebar',Sidebar)
Vue.component('Checkbox', Checkbox)
Vue.component('DataViewLayoutOptions',DataViewLayoutOptions)
Vue.component('DataView', DataView)
// Vue.component('Fieldset', Fieldset)
Vue.component('TabView', TabView)
Vue.component('TabPanel', TabPanel)
Vue.component('SelectButton',SelectButton)
Vue.component('InputSwitch',InputSwitch)
Vue.component('Column',Column)
Vue.component('DataTable',DataTable)
Vue.component('ColumnGroup',ColumnGroup)
Vue.component('Calendar',Calendar)
Vue.component('Dropdown',Dropdown)
Vue.component('Panel', Panel)
Vue.component('Card', Card)
Vue.component('Menubar', Menubar)
Vue.component('Button', Button)
Vue.component('InputText', InputText)
Vue.component('Menu', Menu);
Vue.component('Dialog', Dialog);
Vue.component('Message', Message);
Vue.component('TabMenu', TabMenu);
Vue.component('ProgressSpinner',ProgressSpinner)
Vue.directive('tooltip', Tooltip);



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
