<template>
  <div>
    <!-- {{ materialTypes }} -->
    <!-- {{datafields}} -->
    <Button
      class="p-button-danger"
      @click="$router.push('/projects')"
      label="Tilbake"
    />
    <Button
      class="p-button-primary"
      @click="newObjectDialog = true"
      label="Ny"
      style="float: right"
    />
    <Button
      class="p-button-primary"
      @click="importerConnectorDialog = true"
      label="Importer Connector "
      style="float: right; margin-right: 10px"
    />
    <DataTable
      rowHover
      @row-click="test"
      :autoLayout="true"
      :filters="filters"
      removableSort
      :value="objects"
      data-key="id"
      class="p-datatable-sm"
    >
      <template #header>
        <div class="p-col-12"></div>
        <div class="p-d-flex p-flex-column p-flex-md-row">
          <div class="p-mb-1 p-mr-2">
            <div style="text-align: left">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global']" placeholder="Søk" />
              </span>
            </div>
          </div>
        </div>
      </template>
      <template #empty>Ingen treff</template>
      <Column bodyStyle="padding-left:5px; padding-right:0px;">
        <template  #body="slotProps">
          <i
            style="color: red; margin-right: 10px"
            v-if="slotProps.data.priority == 1"
            class="pi pi-circle-on"
          ></i>
          <i
            style="color: green; margin-right: 0px"
            v-if="slotProps.data.completed == 1"
            class="pi pi-circle-on"
          ></i>
        </template>
      </Column>
      <Column
        body-style="padding:0;"
        class="test"
        sortable
        :field="column.datafield"
        v-for="column in datafieldsComputed"
        :key="column.id"
      >
        <template  class="" #header>{{
          column.Datafields[0].name
        }}</template>

        <template   #body="slotProps">

          <!-- Type: 1 = Statisk Tekst & Adresse & Telefonnr -->
          <div   v-if="[1, 2, 4, 5, 6, 7, 8,10].includes(column.Datafields[0].type)">
            <!-- <i
              style="color:red; margin-right:10px;"
              v-if="(slotProps.data.priority==1 && column.id==datafields[0].id)"
              class="pi pi-circle-on"
            ></i>
            <i
              style="color:green; margin-right:10px;"
              v-if="(slotProps.data.completed==1 && column.id==datafields[0].id)"
              class="pi pi-circle-on"
            ></i> -->
            {{ slotProps.data[column.datafield] }}
          </div>

          <div   v-if="[14].includes(column.Datafields[0].type)">
          <a target="_blank" :href="slotProps.data[column.datafield]">

          Link          </a>
          </div>

          <!-- Type: 3 = Avansert sjekkboks, Ja, Nei, Utgår -->
          <div  :class="statusClass(slotProps.data[column.datafield])" v-if="[3].includes(column.Datafields[0].type)">
            <span
              v-if="
                slotProps.data[column.datafield] == '0' ||
                slotProps.data[column.datafield] == ''
              "
              >Nei</span
            >
            <span v-if="slotProps.data[column.datafield] == '1'">Ja</span>
            <span v-if="slotProps.data[column.datafield] == '2'">Utgår</span>
          </div>

          <!-- Type: 9 = Enkel sjekkboks, Check -->
          <div v-if="[9].includes(column.Datafields[0].type)">
            <span
              v-if="
                slotProps.data[column.datafield] == '0' ||
                slotProps.data[column.datafield] == ''
              "
            >
              <i class="pi pi-minus"></i>
            </span>
            <span v-if="slotProps.data[column.datafield] == '1'">
              <i class="pi pi-check"></i>
            </span>
          </div>

          <!-- Type : 13 = Materiell dropboks, litt vrient.. -->
          <div v-if="[13].includes(column.Datafields[0].type)">
            <span v-if="slotProps.data[column.datafield]">
              {{ checkMaterialType(slotProps.data[column.datafield]) }}
            </span>

            <span v-if="!slotProps.data[column.datafield]">
              <!-- <i class="pi pi-minus"></i> -->
              -
            </span>
          </div>
        </template>
      </Column>
    </DataTable>
    <Dialog
      style="position: fixed; top: 0; left: 0"
      :visible.sync="newObjectDialog"
      header="Ny oppføring"
      :modal="true"
      :contentStyle="{ overflow: 'auto' }"
      class="p-fluid"
    >
      <div class="container">
        <!-- {{newObject}}
        {{project.id}}
        {{template}}-->
        <div class="">
          <Button
            class="p-button-primary"
            @click="downloadExcel()"
            label="Last ned Excel Template"
          />
        </div>
        <div class="">
          <FileUpload
            name="demo[]"
            chooseLabel="Velg fil"
            uploadLabel="Last opp"
            cancelLabel="Avbryt"
            customUpload
            @uploader="myUploader"
            :maxFileSize="1000000"
          >
            <template #empty>
              <p>Dra en fil hit.</p>
            </template>
          </FileUpload>
        </div>

        <div class="">
          <hr />
          <form
            method="post"
            @submit.prevent="postNewObject()"
            style="padding: 20px"
          >
            <div
              class="field"
              v-if="
                datafields.length > 0 &&
                datafields[0].hasOwnProperty('Datafields')
              "
            >
              <div v-for="items in datafields" :key="items.id">
                <div
                  v-if="
                    items.Datafields[0].type == 1 ||
                    items.Datafields[0].type == 4 ||
                    items.Datafields[0].type == 5 ||
                    items.Datafields[0].type == 8 ||
                    items.Datafields[0].type == 6
                  "
                >
                  <h6>{{ items.Datafields[0].name }}</h6>
                  <div class="control">
                    <InputText
                      type="text"
                      name="project_categoryny"
                      v-model="newObject[items.datafield]"
                    />
                  </div>
                </div>

                <div
                  v-if="
                    items.Datafields[0].type == 2 ||
                    items.Datafields[0].type == 7
                  "
                >
                  <h6>{{ items.Datafields[0].name }}</h6>
                  <div class="control">
                    <InputText
                      type="number"
                      name="project_categoryny"
                      v-model="newObject[items.datafield]"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="control">
              <Button label="Legg til" class="p-button-primary" type="submit" />
            </div>
          </form>
        </div>
      </div>
    </Dialog>
    <Dialog
      style="position: fixed; top: 0; left: 0"
      :visible.sync="importerConnectorDialog"
      header="Importer Connector"
      :modal="true"
      :contentStyle="{ overflow: 'auto' }"
      class="p-fluid"
    >
      <div class="container">
        <!-- {{newObject}}
        {{project.id}}
        {{template}}-->

        <div class="">
          <FileUpload
            name="demo[]"
            chooseLabel="Velg fil"
            uploadLabel="Last opp"
            cancelLabel="Avbryt"
            customUpload
            @uploader="uploadConnector"
            :maxFileSize="1000000"
          >
            <template #empty>
              <p>Dra en fil hit.</p>
            </template>
          </FileUpload>
        </div>
      </div>
    </Dialog>
    <loading-overlay :active="isLoading" />
  </div>
</template>
<script>
import ProjectService from "@/services/Project";
import TemplateService from "@/services/Template";

export default {
  computed: {
    datafieldsComputed: function () {
      return this.datafields.filter((datafields) => datafields.tableview == 1);
    },
  },
  props: ["template", "project"],
  data() {
    return {
      isLoading: false,
      loader: "bars",
      progress: 0,
      file: null,
      importerConnectorDialog: false,
      dev: null,
      newObjectDialog: false,
      newObject: {},
      templateService: null,
      projectService: null,
      datafields: [],
      objects: [],
      filters: {},
      materialTypes: [],
    };
  },
  created() {
    this.projectService = new ProjectService();
    this.templateService = new TemplateService();
  },
  mounted() {
    this.fetchObjects();
    this.fetchDatafields();
    this.fetchMaterials();
  },
  methods: {
    testC(i){
      console.log('Test aprent div')
      console.log(this.$refs)
    },
    statusClass(data){
      console.log(data)
      
      switch (data) {
        case 'Ja':
          return 'ok'
        case 1:
          return 'ok'
        case 2:
          return 'ok'
        case 0:
          return 'yellow'
        
        default:
          break;
      }
      // return[
      //   {
      //     'ok':data=='Ja',
      //     'ok':data=='Utgår',
      //     'ok':data==1,
      //     'ok':data==2,
      //     'yellow':data=='Nei',
      //     'yellow':data==0
      //   }
      // ]
    },
    fetchMaterials(){
              this.projectService
          .findMaterialName({ id: this.project.id })
          .then((res)=>this.materialTypes=res.data)
    },
    checkMaterialType(id) {

      let matIndex = this.materialTypes.findIndex((x) => x.id == id);

      if (matIndex > -1) {
      //   // return 'Har mat! ' + matIndex
      return this.materialTypes[matIndex].Project_Material.materialname;
      } else {
      return '?'
      }
          // .then((res) => console.log(res.data));
          // // .then((res)=>materialTypes.push(res.data))
      //     .then((res) => this.materialTypes.push(res.data))

      //   // this.templateService
      // }
    },

    test(event) {
      // this.dev=event.data.id
      this.$router.push(`/object/${this.template}/${event.data.id}`);
    },
    fetchDatafields() {
      this.templateService
        .getTemplateInfo(this.template)
        .then((data) => (this.datafields = data));
    },
    fetchObjects() {
      this.projectService
        .getObjects(this.project.id, this.template)
        .then((data) => (this.objects = data));
    },
    postNewObject() {
      let data = {
        fields: this.newObject,
        projectid: this.project.id,
        template: this.template,
      };
      this.projectService
        .createObject(data)
        .then((data) => this.fetchObjects());
      this.newObjectDialog = false;
    },
    downloadExcel() {
      this.projectService.downloadExcel(this.template).then((response) => {
        console.log(response);
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "file.xlsx");
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    myUploader(event) {
      console.log("Upload?");
      console.log(event);
      this.file = event.files[0];
      this.uploadExcel();
    },
    uploadConnector(event) {
      console.log("Connector excel");
      this.file = event.files[0];
      this.connectorExcel();
    },
    connectorExcel() {
      console.log("Upload connector excel");
      this.isLoading = true;
      this.projectService
        .uploadExcelConnector(this.template, this.project.id, this.file)
        .then((response) => {
          console.log(response);
          this.fetchObjects();
          this.newObjectDialog = false;
          this.importerConnectorDialog = false;
          this.isLoading = false;
        });
    },

    uploadExcel() {
      console.log("Upload");
      this.projectService
        .uploadExcel(this.template, this.project.id, this.file)
        .then((response) => {
          console.log(response);
          this.fetchObjects();
          this.newObjectDialog = false;
        });
    },
  },
};

//Hent templateinfo
//Hent objekter
</script>


<style >
.p-datatable .p-datatable-tbody > tr > td{
  /* background:red !important; */
  /* background-color:red !important; */
}
.ok{
  /* background-color:hsl(0, 40%, 60%) !important; */
  background-color:hsla(100,80%,50%,30%);
  /* background:#87f542 !important; */
  padding:1em;  
}
.yellow{
  padding:1em;
  background-color:hsla(60, 100%, 50%,30%) !important;
  /* background:#ffffa1 !important; */
}
.p-datatable .p-datatable-tbody > tr {
  cursor: pointer;
}
.p-datatable .p-datatable-thead > tr > th {
  cursor: pointer;
  vertical-align: bottom;
  white-space: nowrap;
}
</style>