<template>
  <div style="padding:20px;">
    <h4 style="text-align:left;" class="p-pb-2">Ny sublokasjon</h4>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-6 p-mt-2">
        <span class="p-float-label">
          <InputText v-model="sublocation.name" id="name" type="text" />
          <label for="name">Navn</label>
        </span>
      </div>

      <div class="p-field p-col-12 p-md-6 p-mt-2">
        <span class="p-float-label">
          <Dropdown
            style="text-align:left;"
            v-model="sublocation.location"
            :options="locations"
            optionValue="id"
            optionLabel="name"
          />
          <label for="datafieldcategory">Lokasjon</label>
        </span>
      </div>
    </div>
    <div>
      <Button
        @click="newSublocation(sublocation)"
        label="Lagre"
        icon="pi pi-check"
        class="p-button-primary"
      />
    </div>
  </div>
</template>

<script>
import CustomerService from "@/services/Customer";
import InventoryService from "@/services/Inventory/Inventory";
export default {
  methods: {
    fetchLocations() {
      this.inventoryService
        .getLocations(0)
        .then((data) => (this.locations = data));
    },

    newSublocation(sublocation) {
      this.inventoryService
        .createSublocation(sublocation)
        .then((data) => console.log(data));
      this.$emit("clicked", "newsublocation");
    },
  },

  data() {
    return {
      sublocation: {},
      inventoryService: null,
      locations: [],
    };
  },
  created() {
    this.inventoryService = new InventoryService();
  },
  mounted() {
    this.fetchLocations();
  },
};
</script>

<style>
</style>