<template>
  <div style="padding:20px;">
    
    <!-- <h4 style="text-align:left;" class="p-pb-2">Nytt prosjekt</h4> -->
    
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6 p-mt-2">
          <h6>Prosjektnavn</h6>
            <InputText required="true" v-model="project.projectname" id="projectname" type="text" />
                 
        </div>
        <div class="p-field p-col-12 p-md-6 p-mt-2">
        <h6>Deadline</h6>
          <Calendar autocomplete=off dateFormat="d.m.yy" v-model="project.deadline" id="deadline" />

        </div>

        <div class="p-field p-col-12 p-md-6 p-mt-2">
          <h6>Prosjektkategori</h6>
            <Dropdown
              style="text-align:left;"
              v-model="project.projectcategory"
              :options="projectcategories"
              optionValue="id"
              optionLabel="project_category"
              required="true"
            />
        </div>

        <div class="p-field p-col-12 p-md-6 p-mt-2">
          <h6>Kunde</h6>
            <Dropdown
              style="text-align:left;"
              v-model="project.employer"
              :options="customers"
              optionValue="id"
              optionLabel="customername"
              required="true"
            />
        </div>
                <div class="p-field p-col-12 p-md-6 p-mt-2">
          <h6>Prosjektleder</h6>
            <InputText required="true" v-model="project.project_leader" id="projectleader" type="text" />
                 
        </div>

        <div class="p-field p-col-12 p-md-12">
        </div>
      </div>
      <div>
        <Button @click="createProject()" type="submit" label="Lagre" icon="pi pi-check" class="p-button-primary" />
<h5>{{error}}</h5>
        
      </div>
  
  </div>
</template>

<script>
import ProjectService from "@/services/Project";
import CustomerService from "@/services/Customer";
export default {
  methods: {
    createProject() {
      if(!this.project.projectname || !this.project.projectcategory || !this.project.employer || !this.project.deadline){
        this.error='Fyll ut felter'
        return 
      }
      this.projectService
        .createProject(this.project)
        .then((response) => (this.response = response));
      this.project = {};
      this.$emit("clicked", "someval");
    },
    fetchCustomers() {
      this.customerService
        .getCustomers(0)
        .then((data) => (this.customers = data));
    },
    fetchProjectCategories() {
      this.projectService
        .getProjectCategories(0)
        .then((data) => (this.projectcategories = data));
    },
  },
  mounted() {
    this.fetchCustomers();
    this.fetchProjectCategories();
  },
  created() {
    this.projectService = new ProjectService();
    this.customerService = new CustomerService();
  },
  data() {
    return {
      projectcategories: [],
      customers: [],
      project: {},
      response: {},
      error:null
    };
  },
};
</script>

<style>
</style>