<template>
  <div>
    <InventorySettingNav />
    <div>
      <Button class="p-mr-2 p-button-primary marginl" @click="productModal=true">Nytt produkt</Button>
    </div>
    <Dialog header="Nytt produkt"
      modal
      :closable="true"
      :showHeader="true"
      :visible.sync="productModal"
    >
      <NewProduct @clicked="productModal=false;key++" />
    </Dialog>

    <TableProduct :key="key" />
  </div>
</template>

<script>
import InventorySettingNav from "@/components/UI/InventorySettingNav.vue";
import TableProduct from "@/components/Dashboard/InventoryModule/TableProduct.vue";
import NewProduct from "@/components/Dashboard/InventoryModule/NewProduct.vue";
export default {
  data() {
    return {
      key: 0,
      productModal: false,
    };
  },
  components: {
    InventorySettingNav,
    TableProduct,
    NewProduct,
  },
};
</script>
