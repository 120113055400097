<template>
  <div style="height:100%;">
    <DataTable
      :autoLayout="true"
      :filters="filters"
      removableSort
      :value="inventories"
      data-key="id"
    >
      <template #header>
        <div class="p-col-12">
          <div style="text-align:left;">
            <h4>Lagerposisjon</h4>
          </div>
        </div>
        <div class="p-d-flex p-flex-column p-flex-md-row">
          <div class="p-mb-1 p-mr-2">
            <div style="text-align:left;">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global']" placeholder="Søk" />
              </span>
            </div>
          </div>
          <div class="p-mb-1 p-mr-2">
            <b-field style="text-align: left; padding-right: 20px;">
              <b-radio-button v-model="status" :native-value="0" type="is-primary">
                <span>Aktive</span>
              </b-radio-button>

              <b-radio-button v-model="status" :native-value="1" type="is-primary">
                <span>Inaktive</span>
              </b-radio-button>
            </b-field>
          </div>
        </div>
      </template>
      <template #empty>Ingen treff</template>

      <Column sortable field="Inventory_Product.name" header="Produkt"></Column>
      <Column sortable field="Inventory_Storage.name" header="Lager">
        <template #body="slotProps">
          <span v-if="slotProps.data.Inventory_Storage">{{slotProps.data.Inventory_Storage.name}}</span>
        </template>
      </Column>
      <Column sortable field="Inventory_Location.name" header="Lokasjon">
        <template #body="slotProps">
          <span v-if="slotProps.data.Inventory_Location">{{slotProps.data.Inventory_Location.name}}</span>
        </template>
      </Column>
      <!-- <Column sortable field="Inventory_Sublocation.name" header="Sublokasjon"></Column> -->
      <Column sortable field="quantity" header="Antall"></Column>
      <Column sortable field="comment" header="Kommentar"></Column>

      <Column bodyStyle="text-align:right;" header>
        <template #body="slotProps">
          <Button
            label="Endre"
            @click="editProduct(slotProps.data)"
            class="p-mr-2 p-button-danger p-button-rounded"
          />
        </template>
      </Column>
    </DataTable>

    <Dialog
      :visible.sync="inventoryDialog"
      :style="{width: '450px'}"
      header="Endre lagerposisjon"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-grid">
        <div class="p-field p-col-12 p-md-6 p-mt-2">
          <span class="p-float-label">
            <Dropdown
              style="text-align:left;"
              v-model="inventory.product_id"
              :options="products"
              optionValue="id"
              optionLabel="name"
            />
            <label for="category">Produkt</label>
          </span>
        </div>

        <div class="p-field p-col-12 p-md-6 p-mt-2">
          <span class="p-float-label">
            <Dropdown
              style="text-align:left;"
              v-model="inventory.storage"
              :options="storages"
              optionValue="id"
              optionLabel="name"
            />
            <label for="storage">Lager?</label>
          </span>
        </div>

        <div class="p-field p-col-12 p-md-6 p-mt-2">
          <span class="p-float-label">
            <Dropdown
              :disabled="!inventory.storage"
              style="text-align:left;"
              v-model="inventory.location"
              :options="locationsf(inventory.storage)"
              optionValue="id"
              optionLabel="name"
            />
            <label for="supplier">Lokasjon</label>
          </span>
        </div>

        <div class="p-field p-col-12 p-md-6 p-mt-2">
          <span class="p-float-label">
            <InputText v-model="inventory.quantity" id="quantity" type="text" />
            <label for="quantity">Antall</label>
          </span>
        </div>

        <div class="p-field p-col-12 p-md-6 p-mt-2">
          <span class="p-float-label">
            <InputText v-model="inventory.comment" id="comment" type="text" />
            <label for="comment">Kommentar</label>
          </span>
        </div>
      </div>

      <template #footer>
        <div v-if="inventory.status==0">
          <Button
            style="float:left;"
            label="Deaktiver"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="updateStatus(1)"
          />
          <Button
            @click="updateInventory(inventory)"
            label="Lagre"
            icon="pi pi-check"
            class="p-button-primary"
          />
        </div>
        <div v-if="inventory.status==1">
          <Button
            style="float:left;"
            label="Slett"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="displayConfirmation=true"
          />
          <Button
            @click="updateStatus(0)"
            label="Aktiver"
            icon="pi pi-check"
            class="p-button-danger"
          />
        </div>
      </template>
    </Dialog>
    <Dialog
      header="Advarsel!"
      :visible.sync="displayConfirmation"
      :style="{width: '350px'}"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>Er du sikker på at du vil slette produktkategorien?</span>
      </div>
      <template #footer>
        <Button
          label="Nei"
          icon="pi pi-times"
          @click="displayConfirmation=false"
          class="p-button-text"
        />
        <Button
          label="Ja"
          icon="pi pi-check"
          @click="updateStatus(2)"
          class="p-button-text"
          
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import InventoryService from "@/services/Inventory/Inventory";
export default {
  data() {
    return {
      displayConfirmation: false,
      inventoryDialog: false,
      deleteproductDialog: false,
      product: {},
      submitted: false,
      productService: null,
      filters: {},
      status: 0,
      products: [],
      products: [],
      locations: [],
      storages: [],
      suppliers: [],
      manufacturers: [],
      productCategories: [],
      inventories: [],
      inventory: {},
    };
  },

  watch: {
    status: function (status) {
      console.log(status);
      this.fetchInventories(status);
    },
  },

  methods: {
    locationsf(id) {
      return this.locations.filter((element) => id == element.storage);
    },
    sublocationsf(id) {
      return this.sublocations.filter((element) => id == element.location);
    },
    fetchStorages() {
      this.inventoryService
        .getStorages(0)
        .then((data) => (this.storages = data));
    },
    updateStatus(status) {
      const inventory = { id: this.inventory.id, status: status };
      this.inventoryService
        .updateInventory(inventory)
        .then((data) => this.fetchInventories());
      this.inventoryDialog = false;
      this.displayConfirmation = false;
    },
    updateInventory(inventory) {
      this.inventoryService
        .updateInventory(inventory)
        .then((data) => this.fetchInventories());
      this.inventoryDialog = false;
      this.displayConfirmation = false;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    fetchSuppliers() {
      this.inventoryService
        .getSuppliers(0)
        .then((data) => (this.suppliers = data));
    },
    fetchManufacturers() {
      this.inventoryService
        .getManufacturers(0)
        .then((data) => (this.manufacturers = data));
    },
    fetchProductCategories() {
      this.inventoryService
        .getProductCategories(0)
        .then((data) => (this.productCategories = data));
    },
    fetchLocations() {
      this.inventoryService
        .getLocations(0)
        .then((data) => (this.locations = data));
    },
    fetchData() {
      this.inventoryService
        .getProducts(0)
        .then((data) => (this.products = data));
    },
    fetchInventories() {
      this.inventoryService
        .getInventories(this.status)
        .then((data) => (this.inventories = data));
    },
    editProduct(inventory) {
      this.inventory = { ...inventory };
      this.inventoryDialog = true;
    },
  },

  created() {
    this.inventoryService = new InventoryService();
  },

  mounted() {
    this.fetchStorages(),
      this.fetchSuppliers(),
      this.fetchManufacturers(),
      this.fetchProductCategories(),
      this.fetchInventories(0),
      this.fetchData(),
      this.fetchLocations();
  },
};
</script>