<template>
  <div>
    <InventorySettingNav />
    <div>
      <Button class="p-mr-2 p-button-primary marginl" @click="productCategoryModal=true">Ny produktkategori</Button>
    </div>
    <Dialog header="Ny produktkategori"
      modal
      :closable="true"
      :showHeader="true"
      :visible.sync="productCategoryModal"
    >
      <NewProductCategory @clicked="productCategoryModal=false;key++" />
    </Dialog>

    <TableProductCategory :key="key" />
  </div>
</template>

<script>
import InventorySettingNav from "@/components/UI/InventorySettingNav.vue";
import TableProductCategory from "@/components/Dashboard/InventoryModule/TableProductCategory.vue";
import NewProductCategory from "@/components/Dashboard/InventoryModule/NewProductCategory.vue";
export default {
  data() {
    return {
      key: 0,
      productCategoryModal: false,
    };
  },
  components: {
    InventorySettingNav,
    TableProductCategory,
    NewProductCategory,
  },
};
</script>
