import axios from 'axios'

export default class ProjectService {    
	getProjectCategories(status) {
		return axios.get('api/getprojectcategories/'+status).then(res => res.data.data);
	}
	getProjects(status) {
		return axios.get('api/getprojects/'+status).then(res => res.data.data);
	}
	getProject(id) {
		return axios.get('api/getproject/'+id).then(res=>res.data.data);
	}
	getObjects(project,template){
		// let data ={project:28, template:39}
		let data={project:project, template:template}
		return axios.post('api/getobjects/', data).then(res=>res.data.data)
	}
	getObject(template,id){
		return axios.get(`api/getobject/${template}/${id}`).then(res=>res.data.data)
	}
	getLog(template,id){
		return axios.get(`api/objectlog/${template}/${id}`).then(res=>res.data.data)
	}
	createObject(data){
		return axios.post('api/createobject',data).then(res=>res.data.data)
	}

	createProject(project){
		project.template=''
		return axios.post('api/createproject',project).then(res=>res.data.data)
	}
	createProjectCategory(projectCategory){
		return axios.post('api/createprojectcategory',projectCategory).then(res=>res.data.data)
	}

	updateProject(project){
		return axios.post('api/updateproject',project).then(res=>res.data.data)
	}
	updateProjectCategory(projectCategory){
		return axios.post('api/updateprojectcategory',projectCategory).then(res=>res.data.data)
	}	
	updateObject(object){
		
		return axios.post('api/updateobject',object).then(res=>res.data.data)
	}
	getComments(template, id){
		return axios.get(`api/getcomments/${template}/${id}`).then(res=>res.data.data)
	}
	createComment(data){
		console.log(data)
		return axios.post('api/createcomment',data).then(res=>res.data.data)
	}

	downloadExcel(template){
		// return axios.get(`api/downloadexcelfield/${template}`)
		return axios({
			url: `api/downloadexcelfield/${template}`,
			method: "GET",
			responseType:"blob"
		})
	}
	addNewMaterialToProject(data){
		return axios.post(`api/addmaterialtoproject`, data).then(res=>res.data.data)
	}
	fetchMaterialsProject(project){
		return axios.get(`api/getmaterialproject/${project}`).then(res=>res.data.data)
	}
	uploadExcel(template, project, file){
		const formData = new FormData()
		formData.append("excel", file)
		return axios.post(`api/uploadexcelfield/${template}/${project}`, formData)
	}
	uploadExcelConnector(template, project, file){
		const formData = new FormData()
		formData.append("excel", file)
		return axios.post(`api/uploadexcelconnector/${template}/${project}`, formData)
	}
	uploadMaterial(project, file){
		const formData = new FormData()
		formData.append("excel", file)
		return axios.post(`api/uploadexcelmaterial/${project}`, formData)
	}
	uploadProjectMaterialExcel(file,project){
		let opt=''
		if(project){
			opt=project.project
		}
		const formData = new FormData()
		formData.append("excel", file)
		return axios.post(`api/uploadprojectexcelmaterial/${opt}`, formData, project).then(res=>res.data)
	}
	uploadFile(data, file){
		const formData = new FormData()
		formData.append("file", file)
		formData.append("info",JSON.stringify(data))
		return axios.post(`api/uploadfile/`, formData)
	}
	getFiles(data){
		return axios.post(`api/getfiles/`, data).then(res=>res.data.data)
	}
	deleteFile(data){
		return axios.post(`api/deletefile`, data).then(res=>res.data.data)
	}
	fetchMaterials(project){
		return axios.get(`api/getmaterialtelenor/${project}`).then(res=>res.data.data)
	}
	getAllMaterials(){
		return axios.get(`api/getprojectmaterials/`).then(res=>res.data.data)
	}
	createSingleMaterial(data){
		return axios.post(`api/createprojectmaterial`,data).then(res=>res.data.data)
	}
	updateProjectMaterial(data){
		return axios.post(`api/updateprojectmaterial`,data).then(res=>res.data)
	}
	updateTableOrder(data){
		return axios.post(`api/updatetableorder`,data).then(res=>res.data.data)
	}
	telenorMaterialTransaction(data){
		return axios.post('api/telenormaterial/transaction', data).then(res=>res.data.data)
	}
	projectMaterialTransaction(data){
		return axios.post('api/projectmaterial/transaction', data).then(res=>res.data.data)
	}
	findMaterialName(data){
		return axios.post(`api/getmaterialname`,data).then(res=>res.data)
	}
}

