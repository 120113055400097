<template>
  <div>
    <InventorySettingNav />
    <div>
      <!-- <Button class="p-mr-2 p-button-primary marginl" @click="inventoryModal=true">Ny lagerposisjon</Button> -->
    <NewInventory @clicked="key++"/>
    </div>


    <TableInventory :key="key" />
  </div>
</template>

<script>
import InventorySettingNav from "@/components/UI/InventorySettingNav.vue";
import TableInventory from "@/components/Dashboard/InventoryModule/TableInventory.vue";
import NewInventory from "@/components/Dashboard/InventoryModule/NewInventory.vue";
export default {
  data() {
    return {
      key: 0,
      inventoryModal: false,
    };
  },
  components: {
    InventorySettingNav,
    TableInventory,
    NewInventory,
  },
};
</script>
