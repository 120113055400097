<template>
  <div style="height:100%;">
    <DataTable :autoLayout="true" :filters="filters" removableSort :value="locations" data-key="id">
      <template #header>
        <div class="p-col-12">
          <div style="text-align:left;">
            <h4 >Lokasjoner (Hylle/Pall/Rom)</h4>
          </div>
        </div>
        <div class="p-d-flex p-flex-column p-flex-md-row">
          <div class="p-mb-1 p-mr-2">
            <div style="text-align:left;">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global']" placeholder="Søk" />
              </span>
            </div>
          </div>
          <div class="p-mb-1 p-mr-2">
                      <b-field style="text-align: left; padding-right: 20px;">
              <b-radio-button v-model="status" :native-value="0" type="is-primary">
                <span>Aktive</span>
              </b-radio-button>

              <b-radio-button v-model="status" :native-value="1" type="is-primary">
                <span>Inaktive</span>
              </b-radio-button>
            </b-field>
          </div>
        </div>
      </template>
      <template #empty>Ingen treff</template>

      <Column sortable field="Inventory_Storage.name" header="Lager"></Column>
      <Column sortable field="name" header="Lokasjon"></Column>

      <Column bodyStyle="text-align:right;" header>
        <template #body="slotProps">
          <Button
            label="Endre"
            @click="editLocation(slotProps.data)"
            class="p-mr-2 p-button-danger p-button-rounded"
          />
        </template>
      </Column>
    </DataTable>

    <Dialog
      :visible.sync="locationDialog"
      :style="{width: '450px'}"
      header="Endre lokasjon"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field p-col-12 p-md-12 p-mt-2">
        <span class="p-float-label">
          <InputText v-model="location.name" id="name" type="text" />
          <label for="name">Navn</label>
        </span>
      </div>

      <div class="p-field p-col-12 p-md-6 p-mt-2">
        <span class="p-float-label">
          <Dropdown
            style="text-align:left;"
            v-model="location.storage"
            :options="storages"
            optionValue="id"
            optionLabel="name"
          />
          <label for="datafieldcategory">Lager</label>
        </span>
      </div>

      <template #footer>
        <div v-if="location.status==0">
          <Button
            style="float:left;"
            label="Deaktiver"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="updateStatus(1)"
          />
          <Button @click="updateLocation(location)" label="Lagre" icon="pi pi-check" class="p-button-primary" />
        </div>
        <div v-if="location.status==1">
          <Button
            style="float:left;"
            label="Slett"
            icon="pi pi-trash"
            class="p-button-danger"
            @click="displayConfirmation=true"
          />
          <Button @click="updateStatus(0)" label="Aktiver" icon="pi pi-check" class="p-button-danger" />
        </div>
      </template>
    </Dialog>
            <Dialog
      header="Advarsel!"
      :visible.sync="displayConfirmation"
      :style="{width: '350px'}"
      :modal="true"
    >
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>Er du sikker på at du vil slette lokasjonen?</span>
      </div>
      <template #footer>
        <Button
          label="Nei"
          icon="pi pi-times"
          @click="displayConfirmation=false"
          class="p-button-text"
        />
        <Button
          label="Ja"
          icon="pi pi-check"
          @click="updateStatus(2)"
          class="p-button-text"
          
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import InventoryService from "@/services/Inventory/Inventory";
export default {
  data() {
    return {
      displayConfirmation:false,
      locationDialog: false,
      deletelocationDialog: false,
      location: {},
      submitted: false,
      productService: null,
      filters: {},
      status: 0,
      locations: [],
      storages:[],
    };
  },

  watch: {
    status: function () {
      this.fetchData();
    },
  },

  methods: {
    updateStatus(status){
      const location = {id:this.location.id, status:status}
      this.inventoryService
       .updateLocation(location)
       .then((data)=>this.fetchData())
       this.locationDialog=false
       this.displayConfirmation = false
    },
    updateLocation(location){
      this.inventoryService
      .updateLocation(location)
      .then((data)=>(this.fetchData()))
       this.locationDialog=false
       this.displayConfirmation = false
      
    },    
    hideDialog() {
      this.locationDialog = false;
      this.submitted = false;
    },
    fetchData() {
      this.inventoryService
        .getLocations(this.status)
        .then((data) => (this.locations = data));
    this.inventoryService
    .getStorages(0)
    .then((data)=>this.storages=data)
    },

    editLocation(location) {
      this.location = { ...location };
      this.locationDialog = true;
    },
  },

  created() {
    this.inventoryService = new InventoryService();
  },

  mounted() {
    this.fetchData();
  },
};
</script>