<template>
  <div>
    <ProjectSettingNav />
    <b-tabs :animated="false">
      <b-tab-item label="Prosjekter">
        <div>
          <Button
            class="p-mr-2 p-button-primary marginl"
            @click="projectModal = true"
            >Nytt prosjekt</Button
          >
        </div>

        <ProjectTable :key="projectkey" />
      </b-tab-item>
      <b-tab-item label="Kategorier">
        <div>
          <Button
            class="p-mr-2 p-button-primary marginl"
            @click="projectcategoryModal = true"
            >Ny kategori</Button
          >
        </div>

        <ProjectCategoryTable :key="categorykey" />
      </b-tab-item>
    </b-tabs>
    <Dialog
      header="Nytt prosjekt"
      :contentStyle="{ overflow: 'visible' }"
      modal
      :closable="true"
      :dismissableMask="false"
      :showHeader="true"
      :visible.sync="projectModal"
    >
      <NewProject
        @clicked="
          projectModal = false;
          projectkey++;
        "
      />
    </Dialog>
    <Dialog
      header="Ny prosjektkategori"
      modal
      :closable="true"
      :showHeader="true"
      :visible.sync="projectcategoryModal"
    >
      <NewProjectCategory
        @clicked="
          projectcategoryModal = false;
          categorykey++;
        "
      />
    </Dialog>
  </div>
</template>

<script>
import ProjectSettingNav from "@/components/UI/ProjectSettingNav.vue";
import NewProject from "@/components/Dashboard/ProjectModule/NewProject.vue";
import ProjectCategoryTable from "@/components/Dashboard/ProjectModule/ProjectCategoryTable.vue";
import NewProjectCategory from "@/components/Dashboard/ProjectModule/NewProjectCategory.vue";
import ProjectTable from "@/components/Dashboard/ProjectModule/ProjectTable.vue";
export default {
  data() {
    return {
      projectkey: 0,
      categorykey: 0,
      projectModal: false,
      projectcategoryModal: false,
    };
  },
  components: {
    ProjectTable,
    ProjectCategoryTable,
    ProjectSettingNav,
    NewProject,
    NewProjectCategory,
  },
};
</script>


