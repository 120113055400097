<template>
  <div style="padding:20px;">
    <h4 style="text-align:left;" class="p-pb-2">Ny produsent</h4>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-12 p-mt-2">
        <span class="p-float-label">
          <InputText v-model="manufacturer.name" id="name" type="text" />
          <label for="name">Produsentnavn</label>
        </span>
      </div>
    </div>
    <div>
      <Button
        @click="newManufacturer(manufacturer)"
        label="Lagre"
        icon="pi pi-check"
        class="p-button-primary"
      />
    </div>
  </div>
</template>

<script>
import CustomerService from "@/services/Customer";
import InventoryService from "@/services/Inventory/Inventory";
export default {
  methods: {
    fetchStorages() {
      this.inventoryService
        .getStorages()
        .then((data) => (this.storages = data));
    },
    newManufacturer(manufacturer) {
      this.inventoryService
        .createManufacturer(manufacturer)
        .then((data) => console.log(data));
      this.$emit("clicked", "newmanufacturer");
    },
  },

  data() {
    return {
      manufacturer: {},
      storages: [],
      customerService: null,
    };
  },
  created() {
    this.inventoryService = new InventoryService();
  },
  mounted() {
    this.fetchStorages();
  },
};
</script>

<style>
</style>