<template>
  <div style="height: 100%">
    <DataTable
      :autoLayout="true"
      :filters="filters"
      removableSort
      @row-reorder="onRowReorder"
      :value="templates"
      data-key="id"
    >
      <template #header>
        <div class="p-col-12">
          <div style="text-align: left">
            <h4>Templates</h4>
          </div>
        </div>
        <div class="p-d-flex p-flex-column p-flex-md-row">
          <div class="p-mb-1 p-mr-2">
            <div style="text-align: left">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global']" placeholder="Søk" />
              </span>
            </div>
          </div>
          <div class="p-mb-1 p-mr-2">
            <b-field style="text-align: left; padding-right: 20px">
              <b-radio-button
                v-model="status"
                :native-value="0"
                type="is-primary"
              >
                <span>Aktive</span>
              </b-radio-button>

              <b-radio-button
                v-model="status"
                :native-value="1"
                type="is-primary"
              >
                <span>Inaktive</span>
              </b-radio-button>
            </b-field>
          </div>
        </div>
      </template>
      <template #empty>Ingen treff</template>
      <Column :rowReorder="true" headerStyle="width: 3rem" />
      <Column sortable field="name" header="Templatenavn"></Column>
      <Column sortable field="version" header="Template versjon/ID"></Column>

      <Column bodyStyle="text-align:right;">
        <template #body="slotProps">
          <Button
            label="Endre"
            @click="$router.push('/edittemplate/' + slotProps.data.id)"
            class="p-mr-2 p-button-danger p-button-rounded"
          />
        </template>
      </Column>
    </DataTable>
    <Button @click="saveOrder()" style="margin:10px;" v-if="editedOrder" label="Lagre rekkefølge" class="p-button-primary button-sm"/>

    <Dialog
      :visible.sync="dataDialog"
      :style="{ width: '450px' }"
      header="Prosjektkategori"
      :modal="true"
      class="p-fluid"
    >
      <div class="p-field">
        <label style="float: left" for="name">Name</label>
        <InputText
          id="name"
          v-model.trim="project.project_category"
          required="true"
          :class="{ 'p-invalid': submitted && !project.name }"
        />
        <small class="p-invalid" v-if="submitted && !project.name"
          >Name is required.</small
        >
      </div>

      <template #footer>
        <div v-if="project.status == 0">
          <Button
            style="float: left"
            label="Arkiver"
            icon="pi pi-trash"
            class="p-button-danger p-button-text"
            @click="2 + 2"
          />
          <Button label="Lagre" icon="pi pi-check" class="p-button-text" />
        </div>
        <div v-if="project.status == 1">
          <Button
            style="float: left"
            label="Slett"
            icon="pi pi-trash"
            class="p-button-danger p-button-text"
            @click="2 + 2"
          />
          <Button label="Aktiver" icon="pi pi-check" class="p-button-text" />
        </div>
      </template>
    </Dialog>
  </div>
</template>



<script>
import TemplateService from "@/services/Template";
import ProjectService from "@/services/Project"
export default {
  data() {
    return {
      submitted: false,
      dataDialog: false,
      deleteCustomerDialog: false,
      templateService: null,
      filters: {},
      editedOrder:false,
      status: 0,
      templates: [],
      project: {},
    };
  },

  watch: {
    status: function () {
      this.fetchData();
    },
  },

  methods: {
    saveOrder() {
      let templates = this.templates;
      let order = [];
      for (let i = 0; i < templates.length; i++) {
        order.push({
          id: templates[i].id,
          order: i,
        });
      }
      console.log(order);
      let data = { order: order, table: "Templates" };
      this.projectService
        .updateTableOrder(data)
        .then((data) => this.fetchData());
    },
    onRowReorder(event) {
      console.log(event.value); 
      this.templates = event.value;
      this.editedOrder = true;
      // this.$toast.add({severity:'success', summary: 'Rows Reordered', life: 3000});
    },
    hideDialog() {
      this.dataDialog = false;
      this.submitted = false;
    },
    fetchData() {
      this.templateService
        .getAllTemplates(this.status)
        .then((data) => (this.templates = data));
    },

    editData(project) {
      this.project = { ...project };
      this.dataDialog = true;
    },
  },

  created() {
    this.templateService = new TemplateService();
    this.projectService = new ProjectService();
  },

  mounted() {
    this.fetchData();
  },
};
</script>