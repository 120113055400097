<template>
  <div id="app">
    <Navbar v-if="auth" />
    <router-view id="body" />
  </div>
</template>

<script>
import Navbar from "@/components/UI/Navbar.vue";
import Sidebar from "@/components/UI/Sidebar.vue";
export default {
  components: {
    Navbar,
    Sidebar,
  },
  computed: {
    auth() {
      return this.$store.getters.isAuthenticated;
    },
  },
  data() {
    return {
      sidebarActive: false,
      visibleLeft: true,
    };
  },
  created() {
    this.$store.dispatch("tryAutoLogin");
  },
  mounted() {},
};
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>

