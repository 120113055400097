<template>
<div> 

<!-- <TabMenu :model="dashboard"/> -->
    <div style=" z-index:18;  width:100%;" class="tabs ">
      <ul>
        <router-link active-class="is-active" tag="li" :to="'/dashboard/projects'"><a>Prosjekter</a></router-link>
        <router-link active-class="is-active" tag="li" :to="'/dashboard/templates'"><a>Templateoversikt</a></router-link>
        <router-link active-class="is-active" tag="li" :to="'/dashboard/datafields'"><a>Datafelter</a></router-link>
        <router-link active-class="is-active" tag="li" :to="'/dashboard/customers'"><a>Kunder</a></router-link>
        <router-link active-class="is-active" tag="li" :to="'/dashboard/projectmaterials'"><a>Prosjektmateriell</a></router-link>
        </ul>
    </div>


</div>
</template>


<script>

export default {
    components:{

    },
    data() {
        return {
           dashboard:[
               {label:'Prosjekter', to:'/dashboard/projects'},
               {label:'Templateoversikt', to:'/dashboard/templates'},
               {label:'Datafelter', to:'/dashboard/datafields'},
           ],
            active: [true, false, false]
        }
    },
    methods: {
        activate(index) {
            let activeArray = [...this.active];
            for (let i = 0 ; i < activeArray.length; i++) {
                activeArray[i] = (i === index);
            }
            this.active = activeArray;
        }
    }
}
</script>

<style lang="scss" scoped>

a:hover{
    text-decoration:none;
}

.tabs{
    padding-bottom:0px;
    margin:0;
}

</style>