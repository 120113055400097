<template>
  <div>
    <ProjectSettingNav />
    <b-tabs :animated="false">
      <b-tab-item  label="Datafelter">
        <div style="padding-bottom: 20px;">
          <Button class="p-mr-2 p-button-primary marginl" @click="datafeltModal=true">Nytt datafelt</Button>
        </div>

        <DatafieldTable :key="key" />
      </b-tab-item>

      <b-tab-item label="Kategorier">
        <div class="ny">
          <Button class="p-mr-2 p-button-primary marginl" @click="categoryModal=true">Ny kategori</Button>
        </div>

        <DatafieldCategoryTable :key="key" />
      </b-tab-item>
    </b-tabs>
            <Dialog header="Nytt datafelt"
          modal
          :closable="true"
          :dismissableMask="false"
          :showHeader="true"
          :visible.sync="datafeltModal"
        >
          <NewDatafield @clicked="datafeltModal=false;key++" />
        </Dialog>
        <Dialog header="Ny datafeltkategori"
          modal
          :closable="true"
          :showHeader="true"
          :visible.sync="categoryModal"
        >
          <NewDatafieldCategory @clicked="categoryModal=false;key++" />
        </Dialog>
  </div>
</template>

<script>
import ProjectSettingNav from "@/components/UI/ProjectSettingNav.vue";
import NewDatafield from "@/components/Dashboard/ProjectModule/NewDatafield.vue";
import NewDatafieldCategory from "@/components/Dashboard/ProjectModule/NewDatafieldCategory.vue";
import DatafieldCategoryTable from "@/components/Dashboard/ProjectModule/DatafieldCategoryTable.vue";
import DatafieldTable from "@/components/Dashboard/ProjectModule/DatafieldTable.vue";

export default {
  data() {
    return {
      key: 0,
      datafeltModal: false,
      categoryModal: false,
    };
  },
  components: {
    NewDatafieldCategory,
    NewDatafield,
    DatafieldCategoryTable,
    DatafieldTable,
    ProjectSettingNav,
  },
};
</script>

<style
</style>