<template>
  <Sidebar
    class="p-sidebar-sm"
    :class="{mobilewidth:!visibleLeft}"
    :showCloseIcon="false"
    :modal="true"
    :visible="visibleLeft||override"
  >
    <div>
        <Button @click="$emit('clicked','close')" label="Lukk"/>


      <ul>
        <router-link active-class="is-active" tag="li" :to="'/'">
          <a>Prosjekter</a>
        </router-link>
      <hr />
                <router-link active-class="is-active" tag="li" :to="'/inventory/dash'">
          <a>Inventory Dash</a>
        </router-link>
                <router-link active-class="is-active" tag="li" :to="'/inventory'">
          <a>Inventory </a>
        </router-link>
   
      <hr />
        <router-link active-class="is-active" tag="li" :to="'/dashboard/projects'">
          <a>Prosjekter</a>
        </router-link>

        <router-link active-class="is-active" tag="li" :to="'/dashboard/templates'">
          <a>Templateoversikt</a>
        </router-link>
        <router-link active-class="is-active" tag="li" :to="'/dashboard/datafields'">
          <a>Datafelter</a>
        </router-link>
        <!-- <router-link active-class="is-active" tag="li" :to="'/projectcategoryoverview'"><a>Prosjektkategorier</a></router-link> -->
        <router-link active-class="is-active" tag="li" :to="'/dashboard/users'">
          <a>Brukere</a>
        </router-link>
        <router-link active-class="is-active" tag="li" :to="'/dashboard/customers'">
          <a>Kunder</a>
        </router-link>
        <router-link active-class="is-active" tag="li" :to="'/dashboard/companies'">
          <a>Bedrifter</a>
        </router-link>
      </ul>
    </div>
  </Sidebar>
</template>

<script>
export default {
  watch: {
    windowWidth: function (val) {
      this.check();
    },
  },
  props: ["override"],
  data() {
    return {
      windowWidth: window.innerWidth,
      visibleLeft: null,
      items: [
        { label: "Prosjekter", url: "/dashboard/projects" },
        { label: "Prosjekter", url: "/dashboard/projects" },
        { label: "Prosjekter", url: "/dashboard/projects" },
      ],
    };
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    check() {
      if (window.innerWidth < 800) {
        this.visibleLeft = false;
      } else {
        this.visibleLeft = true;
      }
    },
  },
  mounted() {
    this.check();
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
    beforeRouteLeave(to, from, next) {
    if (window.innerWidth<800) {
        this.$emit('clicked','close')
 
    } 
      next();
    
  },
};
</script>

<style>
.p-sidebar {
  /* padding-top: 156x !important; */
  box-shadow: none !important;
}
.menu-category {
  font-weight: 600;
}
.p-sidebar-sm {
width: auto !important;
z-index:100;
}
.mobilewidth{
    width: 100% !important;
}


</style>