<template>
  <div style="padding:20px;">
    <h4 style="text-align:left;" class="p-pb-2">Ny leverandør</h4>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-6 p-mt-2">
        <span class="p-float-label">
          <InputText v-model="supplier.name" id="name" type="text" />
          <label for="name">Leverandør</label>
        </span>
      </div>

      <div class="p-field p-col-12 p-md-6 p-mt-2">
        <span class="p-float-label">
          <InputText v-model="supplier.address" id="address" type="text" />
          <label for="name">Adresse</label>
        </span>
      </div>

      <div class="p-field p-col-12 p-md-6 p-mt-2">
        <span class="p-float-label">
          <InputText v-model="supplier.email" id="email" type="text" />
          <label for="name">Email</label>
        </span>
      </div>

      <div class="p-field p-col-12 p-md-6 p-mt-2">
        <span class="p-float-label">
          <InputText v-model="supplier.phone" id="phone" type="text" />
          <label for="name">Telefon</label>
        </span>
      </div>

      <div class="p-field p-col-12 p-md-6 p-mt-2">
        <span class="p-float-label">
          <InputText v-model="supplier.contact" id="contact" type="text" />
          <label for="name">Kontaktperson</label>
        </span>
      </div>
    </div>
    <div>
       <Button
        @click="newSupplier(supplier)"
        label="Lagre"
        icon="pi pi-check"
        class="p-button-primary"
      />
    </div>
  </div>
</template>

<script>
import CustomerService from "@/services/Customer";
import InventoryService from "@/services/Inventory/Inventory";
export default {
  methods: {
    newSupplier(supplier) {
      this.inventoryService
        .createSupplier(supplier)
        .then((data) => console.log(data));
      this.$emit("clicked", "newsupplier");
    },
  },

  data() {
    return {
      supplier: {},
      inventoryService: null,
    };
  },
  created() {
    this.inventoryService = new InventoryService();
  },
};
</script>

<style>
</style>