import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/store'


//==========================//
//      Common              //
//==========================//
import Login from '../views/Login.vue'
import NotFound from '@/components/UI/NotFound'
import Dashboard from '../views/Dashboard.vue'
import Customers from '../views/Dashboard/Common/Customers.vue'
import Companies from '../views/Dashboard/Common/Companies.vue'
import Users from '../views/Dashboard/Common/Users.vue'
import Home from '../views/Home.vue'
import UserProfile from '../views/Dashboard/Common/UserProfile.vue'

//==========================//
//      User Pr             //
//==========================//
import NewUser from '../views/NewUser.vue'

//==========================//
//      Project             //
//==========================//
import Projects from '../views/Dashboard/ProjectModule/Projects.vue'
import Templates from '../views/Dashboard/ProjectModule/Templates.vue'
import ObjectTable from '../views/Project/ObjectTable.vue'
import Object from '../views/Project/Object.vue'
import EditProject from '../views/Dashboard/ProjectModule/EditProject.vue'
import EditTemplate from '../views/Dashboard/ProjectModule/EditTemplate.vue'
import ProjectOverview from '../views/Projects.vue'
import Datafields from '../views/Dashboard/ProjectModule/Datafields.vue'
import ProjectMaterial from '../views/Dashboard/ProjectModule/ProjectMaterials.vue'

//==========================//
//      Inventory           //
//==========================//
import InventoryOverview from '../views/Inventory.vue'
import InventoryDash from '../views/Dashboard/InventoryModule/Dashboard.vue'
import Products from '../views/Dashboard/InventoryModule/Products.vue'
import Storages from '../views/Dashboard/InventoryModule/Storages.vue'
import ProductCategories from '../views/Dashboard/InventoryModule/ProductCategories.vue'
import Locations from '../views/Dashboard/InventoryModule/Locations.vue'
import Inventories from '../views/Dashboard/InventoryModule/Inventories.vue'
import Suppliers from '../views/Dashboard/InventoryModule/Suppliers.vue'
import Manufacturers from '../views/Dashboard/InventoryModule/Manufacturers.vue'
import Checkout from '../views/Inventory/Checkout.vue'
import Restock from '../views/Inventory/Restock.vue'
import SearchProduct from '../components/InventoryModule/SearchProduct.vue'


//==========================//
//      StaticProjects      //
//==========================//
import StaticProjects from '../views/Dashboard/StaticProjectModule/Projects.vue'

Vue.use(VueRouter)
const routes = [
  {
    path:'/staticprojects/',
    component:StaticProjects,
    name:'Statiske prosjekter'
  },
  {
    path:'/dashboard/projectmaterials',
    component:ProjectMaterial,
    name:'Prosjektmateriell'
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/newuser',
    name:'NewUser',
    component: NewUser
  },
  {
    path: '/inventory/searchproduct',
    name: 'Enkeltproduct',
    component: SearchProduct,
  },
  {
    path: '/restock',
    name: 'Påfyll',
    component: Restock,
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/dashboard/templates',
    name: 'Templates',
    component: Templates
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/dashboard/projects',
    name: 'Prosjekter',
    component: Projects
  },
  {
    path: '/dashboard/products',
    name: 'Products',
    component: Products
  },
  {
    path: '/dashboard/storages',
    name: 'Storages',
    component: Storages
  }, 
  {
    path: '/dashboard/productcategories',
    name: 'ProductCategories',
    component: ProductCategories
  }, 
  {
    path: '/dashboard/locations',
    name: 'Locations',
    component: Locations
  }, 
  {
    path: '/dashboard/inventories',
    name: 'Inventories',
    component: Inventories
  }, 
  {
    path: '/dashboard/suppliers',
    name: 'Suppliers',
    component: Suppliers
  }, 
  {
    path: '/dashboard/manufacturers',
    name: 'Manufacturers',
    component: Manufacturers
  }, 
  {
    path: '/dashboard/users',
    name: 'Brukere',
    component: Users
  },
  {
    path: '/dashboard/companies',
    name: 'Bedrifter',
    component: Companies
  },
  {
    path: '/dashboard/customers',
    name: 'Kunder',
    component: Customers
  },
  {
    path: '/dashboard/datafields',
    name: 'Datafelter',
    component: Datafields
  },
  {
    path: '/editproject/:id',
    name: 'Endre prosjekt',
    component: EditProject
  },
  {
    path: '/edittemplate/:id',
    name: 'Endre template',
    component: EditTemplate
  },
  {
    path: '/project/:id',
    name: 'Objekter',
    component: ObjectTable
  },
  {
    path: '/object/:template/:id',
    name: 'Objekt',
    component: Object
  },
  {
    path: '/inventory/dash',
    name: 'Lager Dashboard',
    component: InventoryDash
  },
  {
    path: '/inventory',
    name: 'Lager',
    component: InventoryOverview
  },
  {
    path: '/projects',
    name: 'Prosjektoversikt',
    component: ProjectOverview
  },
{
  path: '/userprofile',
  name: 'Profil',
  component: UserProfile
},

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '*',
    component: NotFound
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
// router.beforeEach(to, from, next){
//    if(store.state.idToken){
//         next()
//       } else {
//         next('/login')
//       }
// }

router.beforeEach((to, from, next) => {
  // if(to.name=='NewUser')next('/newuser')
  if(to.name=='Login' && store.state.idToken) next('/')
  if(to.name=='Login') next()
  if(to.name=='NewUser')next()
   if(store.state.idToken){
        next()
      } else {
        next('/login')
      }
  // else next()
})


export default router
