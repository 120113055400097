// import Api from '@/services/Api'
import axios from 'axios'

export default class UserService{
    opprettbruker (bruker){
        return Api().post('api/register', bruker)
    }
    opprettfirma (firma){
        return Api().post('opprettfirma', firma)
    }
    login (bruker){
        return Api().post('login', bruker)
    }
	getUsers(status){
		return axios.get('api/getusers/'+status).then(res=>res.data.data)
	}
    updateUser(user){
        return axios.post('api/updateuser',user).then(res=>res.data)
    }
	getUsers(status){
		return axios.get('api/getusers/'+status).then(res=>res.data.data)
	}
    
}
