<template>
<div id="app">  

 <b-tabs  v-model="activeTab">
            <b-tab-item label="Produkter">
              <NewProduct/>
              <TableProduct/>              
            </b-tab-item>
            <b-tab-item label="Lager">
              <NewStorage/>
              <TableStorage/>
            </b-tab-item>
            <!-- <b-tab-item label="Sublocation">
              <NewSublocation/>
              <TableSublocation/>
            </b-tab-item> -->
            <b-tab-item label="Produktkategori">
              <NewProductCategory/>
              <TableProductCategory/>
            </b-tab-item>
            <b-tab-item label="Lokasjon">
              <NewLocation/>
              <TableLocation/>
            </b-tab-item>
            <b-tab-item label="Lagerposisjon">
              <NewInventory/>
              <TableInventory/>
            </b-tab-item>
            <b-tab-item label="Leverandør">
              <NewSupplier/>
              <TableSupplier/>                
            </b-tab-item>
            <b-tab-item label="Produsent">
              <NewManufacturer/>
              <TableManufacturer/>                
            </b-tab-item>


        </b-tabs>
</div>
</template>

<script>
import ProjectService from "@/services/Project";
import Projects from '@/components/Overview/Projects'

import NewInventory from '@/components/Dashboard/InventoryModule/NewInventory'
import TableInventory from '@/components/Dashboard/InventoryModule/TableInventory'

import NewLocation from '@/components/Dashboard/InventoryModule/NewLocation'
import TableLocation from '@/components/Dashboard/InventoryModule/TableLocation'

import NewManufacturer from '@/components/Dashboard/InventoryModule/NewManufacturer'
import TableManufacturer from '@/components/Dashboard/InventoryModule/TableManufacturer'

import NewSupplier from '@/components/Dashboard/InventoryModule/NewSupplier'
import TableSupplier from '@/components/Dashboard/InventoryModule/TableSupplier'

import NewProductCategory from '@/components/Dashboard/InventoryModule/NewProductCategory'
import TableProductCategory from '@/components/Dashboard/InventoryModule/TableProductCategory'

import NewSublocation from '@/components/Dashboard/InventoryModule/NewSublocation'
import TableSublocation from '@/components/Dashboard/InventoryModule/TableSublocation'

import NewStorage from '@/components/Dashboard/InventoryModule/NewStorage'
import TableStorage from '@/components/Dashboard/InventoryModule/TableStorage'

import NewProduct from '@/components/Dashboard/InventoryModule/NewProduct'
import TableProduct from '@/components/Dashboard/InventoryModule/TableProduct'


export default {
    components:{
        Projects,
        NewInventory,
        TableInventory,
        NewLocation,
        TableLocation,
        NewManufacturer,
        TableManufacturer,
        NewSupplier,
        TableSupplier,
        NewProductCategory,
        TableProductCategory,
        NewSublocation,
        TableSublocation,
        NewStorage,
        TableStorage,
        NewProduct,
        TableProduct,
    },
  data() {
    return {
      projects: [],
    activeTab:0,
    };
  },

  created() {
  },



  methods: {
  
 onDecode (decodedString) {


 }

  },
};
</script>

<style>
</style>