<template>
  <div style="padding:20px;">
    
    <!-- <h4 style="text-align:left;" class="p-pb-2">Nytt prosjekt</h4> -->
    
      <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-12 p-mt-12">
          <h6>Materialnavn</h6>
            <InputText required="true" v-model="material.materialname" id="materialname" type="text" />
        </div>

        <div class="p-field p-col-12 p-md-12 p-mt-12">
          <h6>Materialkode</h6>
            <InputText required="true" v-model="material.materialcode" id="materialcode" type="text" />
        </div>
 
    
        <div class="p-field p-col-12 p-md-12 p-mt-12">
          <h6>Kategori</h6>
            <InputText required="true" v-model="material.category" id="category" type="text" />
        </div>
      

        
      </div>
      <div>
        <Button @click="createProject()" type="submit" label="Lagre" icon="pi pi-check" class="p-button-primary" />
<h5>{{error}}</h5>
        
      </div>
  
  </div>
</template>

<script>
import ProjectService from "@/services/Project";
import CustomerService from "@/services/Customer";
export default {
  methods: {
    createProject() {
      if(!this.material.materialname || !this.material.materialcode || !this.material.category){
        this.error='Fyll ut felter'
        return 
      }
      this.projectService
        .createSingleMaterial(this.material)
        .then((response) => (this.response = response));
      this.material = {};
      this.$emit("clicked", "someval");
    },

  },
  mounted() {
    // this.fetchCustomers();
    // this.fetchProjectCategories();
  },
  created() {
    this.projectService = new ProjectService();
    this.customerService = new CustomerService();
  },
  data() {
    return {
      projectcategories: [],
      customers: [],
      material: {},
      response: {},
      error:null
    };
  },
};
</script>

<style>
</style>