import axios from 'axios'

export default class InventoryService {    
    createSupplier(supplier){
        return axios.post('/api/inventory/createsupplier',supplier).then(res=>res.data.data);
    }
    updateSupplier(supplier){
        return axios.post('/api/inventory/updatesupplier',supplier).then(res=>res.data.data);
    }
    getSuppliers(status){
        return axios.get('/api/inventory/getsuppliers/'+status).then(res=>res.data.data)
    }
    getSupplier(id){
        return axios.get('/api/inventory/getsupplier/'+id).then(res=>res.data.data)
    }
    
    createSublocation(sublocation){
        return axios.post('/api/inventory/createsublocation',sublocation).then(res=>res.data.data);
    }
    updateSublocation(sublocation){
        return axios.post('/api/inventory/updatesublocation',sublocation).then(res=>res.data.data);
    }
    getSublocations(status){
        return axios.get('/api/inventory/getsublocations/'+status).then(res=>res.data.data)
    }
    getSublocation(id){
        return axios.get('/api/inventory/getsublocation/'+id).then(res=>res.data.data)
    }

    createStorage(storage){
        return axios.post('/api/inventory/createstorage',storage).then(res=>res.data.data);
    }
    updateStorage(storage){
        return axios.post('/api/inventory/updatestorage',storage).then(res=>res.data.data);
    }
    getStorages(status){
        return axios.get('/api/inventory/getstorages/'+status).then(res=>res.data.data)
    }
    getStorage(id){
        return axios.get('/api/inventory/getstorage/'+id).then(res=>res.data.data)
    }

    createProductCategory(productCategory){
        return axios.post('/api/inventory/createproductcategory',productCategory).then(res=>res.data.data);
    }
    updateProductCategory(productCategory){
        return axios.post('/api/inventory/updateproductcategory',productCategory).then(res=>res.data.data);
    }
    getProductCategories(status){
        return axios.get('/api/inventory/getproductcategories/'+status).then(res=>res.data.data)
    }
    getProductCategory(id){
        return axios.get('/api/inventory/getproductcategory/'+id).then(res=>res.data.data)
    }

    createProduct(product){
        return axios.post('/api/inventory/createproduct',product).then(res=>res.data.data);
    }
    updateProduct(product){
        return axios.post('/api/inventory/updateproduct',product).then(res=>res.data.data);
    }
    getProducts(status){
        return axios.get('/api/inventory/getproducts/'+status).then(res=>res.data.data)
    }
    getProduct(id){
        return axios.get('/api/inventory/getproduct/'+id).then(res=>res.data.data)
    }

    createManufacturer(manufacturer){
        return axios.post('/api/inventory/createManufacturer',manufacturer).then(res=>res.data.data);
    }
    updateManufacturer(manufacturer){
        return axios.post('/api/inventory/updateManufacturer',manufacturer).then(res=>res.data.data);
    }
    getManufacturers(status){
        return axios.get('/api/inventory/getManufacturers/'+status).then(res=>res.data.data)
    }
    getManufacturer(id){
        return axios.get('/api/inventory/getManufacturer/'+id).then(res=>res.data.data)
    }    

    createLocation(location){
        return axios.post('/api/inventory/createLocation',location).then(res=>res.data.data);
    }
    updateLocation(location){
        return axios.post('/api/inventory/updateLocation',location).then(res=>res.data.data);
    }
    getLocations(status){
        return axios.get('/api/inventory/getLocations/'+status).then(res=>res.data.data)
    }
    getLocation(id){
        return axios.get('/api/inventory/getLocation/'+id).then(res=>res.data.data)
    }     

    createInventory(inventory){
        return axios.post('/api/inventory/createInventory',inventory).then(res=>res.data.data);
    }
    updateInventory(inventory){
        return axios.post('/api/inventory/updateInventory',inventory).then(res=>res.data.data);
    }
    getInventories(status){
        return axios.get('/api/inventory/getInventories/'+status).then(res=>res.data.data)
    }
    getInventory(id){
        return axios.get('/api/inventory/getInventory/'+id).then(res=>res.data.data)
    }         
    updateInventoryQuantity(inventory){
        return axios.post('/api/inventory/updateInventoryQuantity',inventory).then(res=>res.data.data)
    }

    getCart(cart){
        console.log(cart)
        return axios.post('/api/inventory/getcart/', cart).then(res=>res.data.data)
    }
    
    searchProducts(search){
        console.log('eh' + search)
        return axios.post('/api/inventory/searchproducts/',search).then(res=>res.data.data)
    }

    getInventoriesByProduct(id){
        let product = {id:id}
        return axios.post('/api/inventory/findinventoriesbyid/',product).then(res=>res.data.data)
    }

    getInventoryLog(product_id, from_inventory_id){
        let data = {product_id:product_id, from_inventory_id:from_inventory_id}
        return axios.post('/api/inventory/getinventorylog/',data).then(res=>res.data.data)
    }

}

