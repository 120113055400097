<template>
  <div style="padding:20px;">
    <!-- <h4 style="text-align:left;" class="p-pb-2">Ny prosjektkategori</h4> -->

    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-12 p-md-12 p-mt-2">
        <span class="p-float-label" style="width: 20rem;">
          <InputText v-model="projectcategory.project_category" id="name" type="text" />
          <label for="name">Kategorinavn</label>
        </span>
      </div>
    </div>
    <div>
      <!-- <Button
        style="float:left;"
        label="Avbryt"
        icon="pi pi-times"
        class="p-button-danger"
        @click="$emit('clicked','someval')"
      />-->

      <Button
        @click="createProjectCategory()"
        label="Lagre"
        icon="pi pi-check"
        class="p-button-primary"
      />
    </div>
  </div>
</template>

<script>
// import Users from "@/services/Users";
import ProjectService from "@/services/Project";
export default {
  created() {
    this.projectService = new ProjectService();
  },
  methods: {
    createProjectCategory() {
      this.projectService
        .createProjectCategory(this.projectcategory)
        .then((data) => console.log(data));
      this.$emit("clicked", "someval");
    },
  },

  data() {
    return {
      projectcategory: {},
    };
  },
};
</script>

<style>
</style>